import React from 'react';
import { RcFile } from 'antd/lib/upload';
import styles from './ButtonUpload.styl';
import UploadedFileCard from './UploadedFileCard';

interface UploadedFileListProps {
    file?: RcFile;
    onDeleteFile: () => void;
    containerStyle?: React.CSSProperties;
}

const UploadedFileList: React.FunctionComponent<UploadedFileListProps> = (props) => {
    const { file, onDeleteFile, containerStyle } = props;

    return file ? (
        <div style={containerStyle} className={styles.file_listing_container}>
            <UploadedFileCard file={file} onDelete={onDeleteFile} />
        </div>
    ) : null;
};

export default UploadedFileList;
