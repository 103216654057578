import React, { useCallback, useEffect, useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import { Form, message } from 'antd';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { useFormCheckErrors } from 'tds-common-fe';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './ButtonUpload.styl';
import CustomCurveUploadModal from '../../shared/CustomCurveUploadModal';
import { UploadCustomCurveFormat } from '../../../api/utilsService';
import { isNameASCIIPrintable, stringEnumToArray } from '../../../utils/generalUtils';
import UploadedFileList from './UploadedFileList';
import CustomCurveInputs from './CustomCurveInputs';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import {
    createCsvFromString,
    createYamlFromString,
    fileToText,
    getBlobFromBase64Image,
    getFileExtension,
} from '../../../utils/fileUtils';
import { CustomCurveFileType, SmoothenedCurvedInfo } from '../../../types/measurement';
import { clearCreatedMeasurementAction, createMeasurementAction } from '../../../actions/measurement';
import { checkMeasurementNameExists, createMeasurement, smoothenDGSCurve } from '../../../api/measurementService';
import Guidelines from './Guidelines/Guidelines';
import { useDataViewContext } from '../DataViewProvider';
import PreviewModal from './Preview/PreviewModal';
import UploadConfirmation from './Preview/UploadConfirmation';
import {
    CIRCULAR_REQUIRED_FIELDS,
    CustomCurveFormFieldNames,
    ReadingsType,
    RECTANGULAR_REQUIRED_FIELDS,
    TransducerType,
} from '../../../types/customCurve';
import { getCreateMeasurementProbeInfo } from './utils/curveInputUtils';
import analytics from '../../../analytics/firebaseAnalytics';
import { UploadCustomCurveAction } from '../../../analytics/analyticsConstants';
import UploadButton from '../../shared/Buttons/UploadButton';
import { uploadZipFile } from '../../../api/uploadFileService';

const ButtonUpload: React.FunctionComponent = () => {
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [csvFile, setCsvFile] = useState<RcFile | undefined>();
    const { product = '' } = useDataViewContext();

    const [form] = Form.useForm<CustomCurveFormFieldNames>();
    const [transducerType, setTransducerType] = useState(TransducerType.CIRCULAR_CRYSTAL);
    const requiredFields =
        transducerType === TransducerType.CIRCULAR_CRYSTAL ? CIRCULAR_REQUIRED_FIELDS : RECTANGULAR_REQUIRED_FIELDS;
    const { checkErrors } = useFormCheckErrors(form, requiredFields);
    const formatMessage = useFormatMessage();
    const [smoothenedCurveInfo, setSmoothenedCurveInfo] = useState<SmoothenedCurvedInfo | undefined>();

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isFormIncomplete, setIsFormIncomplete] = useState(true);
    const [showGuidelines, setShowGuidelines] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showUploadConfirmation, setShowUploadConfirmation] = useState(false);

    const dispatch = useDispatch();

    const handlePreview = useCallback(async () => {
        analytics.logUploadCustomCurve(UploadCustomCurveAction.uploadPreviewCurve, product);
        if (!smoothenedCurveInfo) {
            setButtonLoading(true);
            const csvFileContent = await fileToText(csvFile as File);
            const probeInfo = getCreateMeasurementProbeInfo(requiredFields, form, true);
            const smoothenedCurve = await smoothenDGSCurve({
                ...probeInfo,
                product,
                csv: csvFileContent as string,
                label: csvFile?.name.split(`.${getFileExtension(csvFile?.name)}`)[0] ?? '',
                onErrorCallback: () => setButtonLoading(false),
            });
            setButtonLoading(false);
            setSmoothenedCurveInfo(smoothenedCurve.data);
        }
        setIsUploadModalVisible(false);
        setShowPreview(true);
    }, [product, smoothenedCurveInfo, csvFile, requiredFields, form]);

    const resetFields = useCallback(() => {
        form.resetFields();
        setCsvFile(undefined);
        setTransducerType(TransducerType.CIRCULAR_CRYSTAL);
        setShowUploadConfirmation(false);
        setSmoothenedCurveInfo(undefined);
    }, [form]);

    const handleUpload = useCallback(async () => {
        analytics.logUploadCustomCurve(UploadCustomCurveAction.confirmUpload, product);
        if (!(csvFile && product && smoothenedCurveInfo)) return;
        const uploadCreationID = uuid();
        const measurementName = csvFile.name.split(`.${getFileExtension(csvFile.name)}`)[0];
        dispatch(createMeasurementAction({ name: measurementName, id: uploadCreationID, product }));

        const probeInfo = getCreateMeasurementProbeInfo(requiredFields, form);
        const createMeasurementParams = {
            content: {},
            name: measurementName,
            probeInfo,
            type: CustomCurveFileType.dgscc,
            productModel: product.toLowerCase(),
            productFamily: product.toLowerCase(),
            settings: [{ content: {} }],
        };
        const smoothenedCSVFile = createCsvFromString(smoothenedCurveInfo.csv, csvFile.name);
        const yamlFile = createYamlFromString(smoothenedCurveInfo.yaml, `${measurementName}.yaml`);

        Promise.all([
            getBlobFromBase64Image(smoothenedCurveInfo.colorPlotImg),
            getBlobFromBase64Image(smoothenedCurveInfo.bwPlotImg),
        ])
            .then(([colorBlob, bwPlot]) => {
                const colorPlotFile = new File([colorBlob], 'colorPlotImg.png', { type: 'image/png' });
                const bwPlotFile = new File([bwPlot], 'bwPlotImg.png', { type: 'image/png' });
                return Promise.all([
                    uploadZipFile({ product, file: csvFile, zipName: measurementName }),
                    uploadZipFile({ product, file: smoothenedCSVFile, zipName: measurementName }),
                    uploadZipFile({ product, file: colorPlotFile, zipName: measurementName }),
                    uploadZipFile({ product, file: bwPlotFile, zipName: measurementName }),
                    uploadZipFile({ product, file: yamlFile, zipName: measurementName }),
                ]);
            })
            .then(([originalCsv, smoothenedCsv, coloredImg, bwImage, yamlFile]) => {
                return createMeasurement({
                    ...createMeasurementParams,
                    readings: [
                        {
                            type: ReadingsType.CSVOriginal,
                            content: {},
                            attachment: { type: 'rawData', fID: originalCsv.fileID },
                        },
                        {
                            type: ReadingsType.CSVSmoothened,
                            content: {},
                            attachment: { type: 'rawData', fID: smoothenedCsv.fileID },
                        },
                        {
                            type: ReadingsType.PhotoColor,
                            content: {},
                            attachment: { type: 'photo', fID: coloredImg.fileID },
                        },
                        {
                            type: ReadingsType.PhotoBW,
                            content: {},
                            attachment: { type: 'photo', fID: bwImage.fileID },
                        },
                        {
                            type: ReadingsType.YAML,
                            content: {},
                            attachment: { type: 'rawData', fID: yamlFile.fileID },
                        },
                    ],
                });
            })
            .then(() => message.success(formatMessage({ id: 'DataView.Upload.Success' })))
            .finally(() => {
                dispatch(clearCreatedMeasurementAction(uploadCreationID, product));
            });
        resetFields();
    }, [csvFile, dispatch, form, formatMessage, product, requiredFields, resetFields, smoothenedCurveInfo]);

    useEffect(() => {
        setButtonDisabled(!csvFile || isFormIncomplete);
    }, [csvFile, isFormIncomplete]);

    return (
        <>
            <UploadButton
                onClick={() => {
                    setIsUploadModalVisible(true);
                    analytics.logUploadCustomCurve(UploadCustomCurveAction.upload, product);
                }}
            />
            <CustomCurveUploadModal
                visible={isUploadModalVisible}
                onClose={() => {
                    setIsUploadModalVisible(false);
                    setCsvFile(undefined);
                    setIsFormIncomplete(true);
                    form.resetFields();
                    setTransducerType(TransducerType.CIRCULAR_CRYSTAL);
                    analytics.logUploadCustomCurve(UploadCustomCurveAction.uploadClose, product);
                }}
                supportedFormats={stringEnumToArray(UploadCustomCurveFormat)}
                onDrop={(file, fileExt) => {
                    setSmoothenedCurveInfo(undefined);
                    if (fileExt === UploadCustomCurveFormat.CSV) {
                        const fileName = file.name.split(`.${getFileExtension(file.name)}`)[0];
                        if (isNameASCIIPrintable(fileName)) {
                            checkMeasurementNameExists({
                                name: fileName,
                                product,
                                type: CustomCurveFileType.dgscc,
                            }).then((res) => {
                                if (res.response.data.exists) {
                                    message.error(
                                        formatMessage(
                                            { id: 'DataView.Upload.Error.NameExists' },
                                            { fileName: <b>{fileName}</b> }
                                        ),
                                        10
                                    );
                                } else {
                                    setCsvFile(file);
                                }
                            });
                        } else {
                            message.error(
                                formatMessage(
                                    { id: 'DataView.Upload.Error.RenameFile' },
                                    { fileName: <b>{fileName}</b> }
                                ),
                                10
                            );
                        }
                    }
                }}
                handlePreview={handlePreview}
                buttonDisabled={buttonDisabled}
                buttonLoading={buttonLoading}
            >
                <div className={styles.center}>
                    <p className={styles.guideline_text}>
                        <FormattedMessage
                            id="DataView.Upload.Guidelines.Instructions"
                            values={{
                                viewOurGuidelines: (
                                    <AnalyticsButton
                                        type="link"
                                        className={styles.view_guidelines_button}
                                        onClick={() => {
                                            setShowGuidelines(true);
                                            analytics.logUploadCustomCurve(
                                                UploadCustomCurveAction.uploadViewGuidelines,
                                                product
                                            );
                                        }}
                                    >
                                        <FormattedMessage id="DataView.Upload.Guidelines.Instructions.ViewOurGuidelines" />
                                    </AnalyticsButton>
                                ),
                            }}
                        />
                    </p>
                </div>
                <Form
                    form={form}
                    onFieldsChange={(changedFields) => {
                        setIsFormIncomplete(checkErrors());
                        setSmoothenedCurveInfo(undefined);
                        changedFields.forEach((field) => {
                            const fieldName = (field.name as string[])[0] as CustomCurveFormFieldNames;
                            // reset effective diameter, width and length to default when transducer type changes
                            if (fieldName === CustomCurveFormFieldNames.transducerType) {
                                form.resetFields([
                                    CustomCurveFormFieldNames.effectiveDiameter,
                                    CustomCurveFormFieldNames.effectiveWidth,
                                    CustomCurveFormFieldNames.effectiveLength,
                                ]);
                            }
                        });
                    }}
                    requiredMark={false}
                    colon={false}
                    layout="vertical"
                    initialValues={{
                        [CustomCurveFormFieldNames.transducerType]: TransducerType.CIRCULAR_CRYSTAL,
                        [CustomCurveFormFieldNames.deltaVk]: 0,
                        [CustomCurveFormFieldNames.effectiveDiameter]: 10,
                        [CustomCurveFormFieldNames.effectiveWidth]: 10,
                        [CustomCurveFormFieldNames.effectiveLength]: 10,
                    }}
                >
                    <UploadedFileList
                        file={csvFile}
                        onDeleteFile={() => {
                            setCsvFile(undefined);
                            analytics.logUploadCustomCurve(UploadCustomCurveAction.uploadDeleteFile, product);
                        }}
                    />
                    <CustomCurveInputs transducerType={transducerType} setTransducerType={setTransducerType} />
                </Form>
            </CustomCurveUploadModal>
            <Guidelines
                visible={showGuidelines}
                onClose={() => {
                    setShowGuidelines(false);
                    analytics.logUploadCustomCurve(UploadCustomCurveAction.uploadViewGuidelinesBack, product);
                }}
            />
            <PreviewModal
                form={form}
                smoothenedCurveInfo={smoothenedCurveInfo!}
                visible={showPreview && !!smoothenedCurveInfo}
                product={product}
                onClose={() => {
                    setIsUploadModalVisible(true);
                    setShowPreview(false);
                    analytics.logUploadCustomCurve(UploadCustomCurveAction.previewBack, product);
                }}
                onUpload={() => {
                    setShowPreview(false);
                    setShowUploadConfirmation(true);
                    analytics.logUploadCustomCurve(UploadCustomCurveAction.previewUpload, product);
                }}
                requiredFields={requiredFields}
            />
            <UploadConfirmation
                visible={showUploadConfirmation}
                onBack={() => {
                    setShowUploadConfirmation(false);
                    setShowPreview(true);
                    analytics.logUploadCustomCurve(UploadCustomCurveAction.confirmUploadBack, product);
                }}
                onUpload={handleUpload}
            />
        </>
    );
};

export default ButtonUpload;
