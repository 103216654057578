import { MeasurementObjectWithInfo } from '../GPR/Objects';
import { MeasurementFullData } from '../../../../../types/measurement';
import { MarkersColumnRaw } from '../shared/Markers';
import { renderComment } from '../GPR/getMarkersData';
import getSequenceNumber from './getSequenceNumberUtil';
import { ScanType } from '../../../../../types/proceq';
import { CORROSION_SCAN_TYPES } from '../../../../../types/profometer';
import { getGridFromNumber } from '../../utils/getGridFromNumber';

// corrosion saves markers in cells instead of the 'objects' attribute
export const getCorrosionMarkersTableData = (data: MeasurementFullData) => {
    const markers = data.measurement.content?.cellContents?.reduce((accumulatedValue: any, currentValue: any) => {
        const cellMarkers = currentValue.markers;
        if (cellMarkers) {
            return [
                ...accumulatedValue,
                ...cellMarkers.map((marker: any) => ({
                    id: marker.id,
                    content: { ...marker },
                    mID: data.measurement.id,
                    position: currentValue.position,
                    cellIndex: `${getGridFromNumber(currentValue.position?.y ?? 0)}${
                        currentValue.position?.x !== undefined ? currentValue.position.x + 1 : 0
                    }`,
                    markerNumber: `${marker.type === 'Text' ? 'M' : 'V'}${marker.number}`,
                })),
            ].sort((a, b) =>
                a.position.y === b.position.y ? a.position.x - b.position.x : a.position.y - b.position.y
            );
        }
        return accumulatedValue;
    }, []);
    const columnsRaw: MarkersColumnRaw[] = [
        {
            title: 'App.HTML.Profometer.Markers.CellIndex',
            dataIndex: ['cellIndex'],
        },
        {
            title: 'Marker Number',
            dataIndex: ['markerNumber'],
        },
        {
            title: 'Proceq.LogbookSettingsComment',
            dataIndex: ['content', 'content'],
            render: (text: string, record: MeasurementObjectWithInfo) => {
                return renderComment(text, record, data);
            },
        },
    ];
    return { markers, columnsRaw };
};

export const getMarkersTableData = (data: MeasurementFullData, scanType: ScanType) => {
    if (CORROSION_SCAN_TYPES.has(scanType)) {
        return getCorrosionMarkersTableData(data);
    }

    const areaScanParameters = data.settings[0].content.areaScanStatus?.areaScanParameters;
    const startDirection = areaScanParameters?.startDirection;
    const markers: MeasurementObjectWithInfo[] = data.objects
        .filter((object) => object.type === 'marker')
        .map((object) => {
            const reading = data.readings.find((r) => r.id === object.rID);
            const sequenceNo = getSequenceNumber(
                scanType,
                startDirection,
                reading?.content.scanPosition,
                areaScanParameters
            );
            return {
                ...object,
                sequenceNo,
            };
        })
        .sort((a, b) => {
            if (a.sequenceNo === b.sequenceNo) {
                // this is actually the last to be sorted, applies only when type is sorted
                if (a.content.type === b.content.type) {
                    return a.content.number - b.content.number;
                }
                // then sort by tag type
                return a.content.type === 'Text' ? -1 : 1;
            }
            return a.sequenceNo - b.sequenceNo;
        }) as MeasurementObjectWithInfo[];
    const columnsRaw: MarkersColumnRaw[] = [
        {
            title: 'Line',
            dataIndex: ['sequenceNo'],
        },
        {
            title: 'Marker Number',
            render: (text: string, record: MeasurementObjectWithInfo) =>
                `${record.content.type === 'Text' ? 'M' : 'V'}${record.content.number}`,
        },
        {
            title: 'Proceq.LogbookSettingsDistance',
            unitId: 'Profometer.Markers.Distance',
            dataIndex: ['content', 'distanceMeter'],
        },
        {
            title: 'Proceq.LogbookSettingsComment',
            dataIndex: ['content', 'content'],
            render: (text: string, record: MeasurementObjectWithInfo) => {
                return renderComment(text, record, data);
            },
        },
    ];
    return { markers, columnsRaw };
};
