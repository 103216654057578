export type UUID = string;
export type Fingerprint = string;

// productFamily for measurements
export enum ProductCode {
    GPR = 'GPR',
    GPR_SOIL = 'GPR_SOIL',
    PUNDIT = 'PUNDIT',
    EQUOTIP = 'EQUOTIP',
    SCHMIDT = 'SCHMIDT',
    FDL = 'FDL',
    GLM = 'GLM',
    PIT_IE = 'PIT_IE',
    GPR_INSIGHTS = 'GPR_INSIGHTS',
    PROFOMETER = 'PROFOMETER',
    GPR_MOUNTED = 'GPR_MOUNTED',
}

export const RecentMeasurementProducts = [
    ProductCode.GPR,
    ProductCode.GPR_SOIL,
    ProductCode.PUNDIT,
    ProductCode.EQUOTIP,
    ProductCode.SCHMIDT,
    ProductCode.FDL,
    ProductCode.GLM,
    ProductCode.PIT_IE,
    ProductCode.PROFOMETER,
    ProductCode.GPR_MOUNTED,
];

export enum ProductModel {
    GPR = 'GPR',
    GPR_PALM = 'GPR_PALM',
    GPR_GP8100 = 'GPR_GP8100',
    GS9000 = 'GS9000',
    GPR_SOIL = 'GPR_SOIL',
    PUNDIT = 'PUNDIT',
    PUNDIT_S = 'PUNDIT_S',
    PI8000 = 'PI8000',
    FDL = 'FDL',
    UCI = 'UCI',
    LEEBD = 'LEEBD',
    SSL = 'SSL',
    SCHMIDT = 'SCHMIDT',
    GLM = 'GLM',
    PUNDIT_PD8050 = 'PUNDIT_PD8050',
    PM8000 = 'PM8000',
    PM8500 = 'PM8500',
    RS8000 = 'RS8000',
    GM8000 = 'GM8000',
}

export const gprSoilProduct = 'Proceq MA8000';

export const supportedProductSet: Set<ProductCode> = new Set([
    ProductCode.GPR,
    ProductCode.GPR_SOIL,
    ProductCode.PUNDIT,
    ProductCode.EQUOTIP,
    ProductCode.SCHMIDT,
    ProductCode.FDL,
    ProductCode.GLM,
    ProductCode.PIT_IE,
    ProductCode.PROFOMETER,
    ProductCode.GPR_MOUNTED,
]);

// Measurements which product models in the same family will be fetched together
export const productModelToFamilyMap: { [key in ProductModel]: ProductCode } = {
    /**
     * WiFi Products
     */
    // GPR
    GPR: ProductCode.GPR,
    GPR_PALM: ProductCode.GPR,
    GPR_GP8100: ProductCode.GPR,
    // GPR Soil
    GPR_SOIL: ProductCode.GPR_SOIL,
    GS9000: ProductCode.GPR_SOIL,
    // GPR Mounted
    GM8000: ProductCode.GPR_MOUNTED,
    // Pundit
    PUNDIT: ProductCode.PUNDIT,
    PUNDIT_S: ProductCode.PUNDIT,
    PUNDIT_PD8050: ProductCode.PUNDIT,
    // Pundit Impact
    PI8000: ProductCode.PIT_IE,
    // Flaw Detector
    FDL: ProductCode.FDL,
    /**
     * Bluetooth Products
     */
    // Equotip
    UCI: ProductCode.EQUOTIP,
    LEEBD: ProductCode.EQUOTIP,
    // Schmidt
    SSL: ProductCode.SCHMIDT,
    SCHMIDT: ProductCode.SCHMIDT,
    // Glossmeter
    GLM: ProductCode.GLM,
    PM8000: ProductCode.PROFOMETER,
    PM8500: ProductCode.PROFOMETER,
    RS8000: ProductCode.SCHMIDT,
};

export const wifiProductFamilySet = new Set<ProductCode>([
    ProductCode.GPR,
    ProductCode.GPR_SOIL,
    ProductCode.PUNDIT,
    ProductCode.FDL,
    ProductCode.GPR_MOUNTED,
]);
export const bluetoothProductFamilySet = new Set<ProductCode>([
    ProductCode.EQUOTIP,
    ProductCode.SCHMIDT,
    ProductCode.GLM,
    ProductCode.PIT_IE,
    ProductCode.PROFOMETER,
]);

export const productsWithVerificationData = new Set<ProductCode>([ProductCode.EQUOTIP, ProductCode.SCHMIDT]);

export const productsWithSpatialData = new Set<ProductCode>([ProductCode.GPR_SOIL, ProductCode.GPR_MOUNTED]);

export const productsWithLocalizedExports = new Set<ProductCode>([
    ProductCode.EQUOTIP,
    ProductCode.SCHMIDT,
    ProductCode.GLM,
]);

export const iphoneSupportedProductFamilySet = new Set<ProductCode>([
    ProductCode.EQUOTIP,
    ProductCode.GLM,
    ProductCode.SCHMIDT,
]);

export type ProductFamily = 'GPR' | 'EQUOTIP' | 'INSPECT' | 'SOFTWARE'; // this ProductFamily is for contacts not for measurements
export type UnixMicroseconds = number;
export type DateTimeString = string; // YYYY-MM-DD HH:ii:ss

export type Region = 'SG' | 'EU' | 'US' | 'JP' | 'CN';

export enum UserRole {
    Super = 'super',
    Admin = 'admin',
    PO = 'po',
    BUAdmin = 'bu_admin',
    BU = 'bu',
    Dev = 'dev',
    User = 'user',
    Analyst = 'analyst',
}

export type ShortLangKey = 'en' | 'es' | 'de' | 'fr' | 'it' | 'ko' | 'pt' | 'ru' | 'tr' | 'jp' | 'cn';
export type LangKey = 'en' | 'es' | 'de' | 'fr' | 'it' | 'ko' | 'pt' | 'ru' | 'tr' | 'ja' | 'zh';

export type BluetoothSerialNumber = string; // BT99-999-9999

export enum ScanType {
    LineScan = 'Line Scan',
    AreaScan = 'Area Scan',
    StripeScan = 'Stripe Scan',
    Indication = 'Indication',
    AScan = 'AScan',
    FreePath = 'Free Path',
    GridScan = 'Grid Scan',
    ImpactEchoSpot = 'ImpactEchoSpot',
    ImpactEchoGrid = 'ImpactEchoGrid',
    SpotScan = 'Spot Scan',
    BScan = 'B-scan',
    Conventional = 'Conventional', // shown as ascan for fdl
    CorrosionMap = 'Corrosion Map', // shown as grid for fdl
    Advanced = 'Advanced',
    Basic = 'Basic',
    SuperlineScan = 'Superline Scan',
    MultiLineScan = 'Multi-Line Scan', // for profometer
}

export type Location = {
    lat?: number;
    long?: number;
};

export interface SharedURLParams {
    k: string;
    d: string;
    n: number;
    t?: string;
    id?: string;
    source?: string;
}
