import { OrderDir, RuleConfig } from '../../api/measurementService';
import { QueryKey } from './ColumnConfig/columnConfigMeasurement';
import { FilterConfig } from '../../types/measurement';

export const convertKeyValueToFilter = (key: React.Key, values: (boolean | React.Key)[]) => {
    const rawKey = String(key);
    const [rawCategory, rawOrderBy] = rawKey.split('.$.');
    const orderBy = `$.${rawOrderBy}`;

    const rules: RuleConfig[] = [];
    if (key === QueryKey.MeanSecondary || key === QueryKey.MeanPrimary) {
        const [min, max] = values;
        if (min) {
            rules.push({
                op: '>=',
                field: orderBy,
                value: min.toString(),
            });
        }
        if (max) {
            rules.push({
                op: '<=',
                field: orderBy,
                value: max.toString(),
            });
        }
    } else {
        const [value] = values;
        if (value) {
            rules.push({
                op: '=',
                field: orderBy,
                value: value.toString(),
            });
        }
    }

    const filter: FilterConfig = {
        category: rawCategory,
        operator: 'AND',
        orderBy: '',
        orderDir: OrderDir.None,
        rules,
    };
    return filter;
};
