import React from 'react';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { ReactComponent as EditIcon } from '../../../images/iconEdit.svg';
import Tooltip from '../Tooltip';
import { useFormatMessage } from '../../../localization/useFormatMessage';

interface EditButtonProps {
    onClick: (e: React.MouseEvent) => void;
}

const EditButton: React.FunctionComponent<EditButtonProps> = ({ onClick }) => {
    const formatMessage = useFormatMessage();
    return (
        <AnalyticsButton onClick={onClick} type="link" style={{ display: 'flex', padding: 0, height: 'auto' }}>
            <Tooltip placement="top" title={formatMessage({ id: 'DataView.Folder.RenameFolder.Rename' })}>
                <EditIcon />
            </Tooltip>
        </AnalyticsButton>
    );
};

export default EditButton;
