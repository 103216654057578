import { Empty } from 'antd';
import React from 'react';
import { ReactComponent as NoDataImage } from '../../images/noData.svg';
import styles from './MyEmpty.styl';
import FormattedMessage from '../../localization/FormatMessage';
import { FormatIDs } from '../../types';

interface MyEmptyProps {
    descriptionText?: { title: FormatIDs; subtitle: FormatIDs };
}

const MyEmpty: React.FunctionComponent<MyEmptyProps> = ({ descriptionText }) => {
    const description = descriptionText
        ? {
              description: (
                  <>
                      <div>
                          <FormattedMessage id={descriptionText.title} />
                      </div>
                      <div style={{ fontSize: 12 }}>
                          <FormattedMessage id={descriptionText.subtitle} />
                      </div>
                  </>
              ),
          }
        : {};
    return (
        <Empty
            className={styles.my_empty}
            imageStyle={{ height: 'auto' }}
            image={<NoDataImage className={styles.my_empty_image} />}
            {...description}
        />
    );
};

export const tableLocaleSetting = { emptyText: <MyEmpty /> };
export const archivedTableLocaleSetting = {
    emptyText: (
        <MyEmpty
            descriptionText={{
                title: 'DataView.Measurement.Archive.NoData',
                subtitle: 'DataView.Measurement.Archive.NoData.Subtitle',
            }}
        />
    ),
};
export const spatialDataTableLocaleSetting = {
    emptyText: (
        <MyEmpty
            descriptionText={{
                title: 'DataView.Folder.SpatialData.NoData.Title',
                subtitle: 'DataView.Folder.SpatialData.NoData.Subtitle',
            }}
        />
    ),
};

export default MyEmpty;
