import { get } from 'lodash';
import { MeasurementFullData, PunditGridScanDataOfInterestType } from '../../../../../types/measurement';
import { renderFolderName } from '../../utils/renderFolderName';
import { FormatIDs, FormatMessageFunc } from '../../../../../types';
import { ScanType } from '../../../../../types/proceq';
import { isEqualOrAfterPD8050Launch, isProContractType } from '../../../../Common/Pundit/exportConstants';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import { getMeasurementMode } from '../../../../../utils/getMeasurementMode';

const getPWave = (sWave: number | undefined, poissonRatio: number | undefined) => {
    if (sWave === undefined || poissonRatio === undefined) return '-';
    return Math.sqrt((2 * sWave ** 2 * (poissonRatio - 1)) / (2 * poissonRatio - 1));
};

export const getPunditMeasurementPresets = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc,
    isFullList?: boolean
) => {
    if (!data) {
        return [];
    }

    const isStripeScan = data.measurement.type === ScanType.StripeScan;
    const isGridScan = data.measurement.type === ScanType.GridScan;
    const gridScanDataOfInterest: PunditGridScanDataOfInterestType = isGridScan
        ? data.settings[0].content.preset.dataInterest
        : undefined;
    // Copied from PQ Web
    // refs: https://jira.screeningeagle.com/browse/PDLIV-503
    // TempFix : whether to enable Macro should actually be checked from the features of contractId.Tier
    const isIQSAFT = get(data, 'settings.0.content.process.isIQSAFT');
    const version = data.measurement.content?.info?.version;
    const isEnableMacro =
        (isIQSAFT && !isEqualOrAfterPD8050Launch(version)) ||
        (isProContractType(data) && isEqualOrAfterPD8050Launch(version));

    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.GPR.MeasurementPresets.FileName',
            value: get(data, 'measurement.name'),
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.FileID',
            value: get(data, 'measurement.id'),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.StatisticsFolder',
            value: renderFolderName(data, formatMessage),
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.Mode',
            value: getMeasurementMode(data?.measurement?.type),
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.DataInterest',
            value: get(data, 'settings.0.content.preset.dataInterest'),
            enable: !!isFullList && isGridScan,
        },
        {
            label: 'App.HTML.PUNDIT.Display.Units',
            value: get(data, 'settings.0.content.display.unit'),
            enable: !isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.Display.DepthOfField',
            value: get(data, 'settings.0.content.preset.macro'),
            enable: isEnableMacro,
        },
        {
            label: 'App.HTML.PUNDIT.Display.HalfCycle',
            value: get(data, 'settings.0.content.preset.isHalfCycle')
                ? formatMessage({ id: 'Proceq.On' })
                : formatMessage({ id: 'Proceq.Off' }),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.LogbookSettingsAnalogGain',
            unitId: 'PUNDIT.Settings Panel.Analogue Gain',
            value: get(data, 'settings.0.content.preset.analogueGain'),
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.AnalogTGC',
            unitId: 'PUNDIT.Settings Panel.Time Gain Compensation',
            value: get(data, 'settings.0.content.preset.timeGainCompensation'),
            enable: !!isFullList,
        },
        {
            label: isStripeScan
                ? 'App.HTML.PUNDIT.MeasurementPresets.YSpacing'
                : 'App.HTML.PUNDIT.MeasurementPresets.XSpacing',
            unitId: (() => {
                let overlap = get(data, 'settings.0.content.process.overlap');
                overlap = overlap === 0 ? overlap : -1 * overlap;
                return overlap < 0
                    ? 'PUNDIT.Settings Panel.Gap and Overlap slider Channels'
                    : 'PUNDIT.Settings Panel.Gap and Overlap slider';
            })(),
            value: (() => {
                let overlap = get(data, 'settings.0.content.process.overlap');
                overlap = overlap === 0 ? overlap : -1 * overlap;
                return overlap;
            })(),
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.AScan',
            value: get(data, 'settings.0.content.process.aScanDrawingMode'),
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.Channels',
            value: get(data, 'settings.0.content.preset.numberOfChannels.value'),
        },
        {
            label: 'App.HTML.PUNDIT.Display.ImageStabilizer',
            value: get(data, 'settings.0.content.preset.averaging'),
            enable: isIQSAFT && !!isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.PulseDelay',
            unitId: 'PUNDIT.Settings Panel.Pulse Delay',
            value: get(data, 'settings.0.content.preset.pulseDelay'),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.LogbookSettingsBackwallDepth',
            unitId: 'PUNDIT.GridScan.BackwallDepth',
            value: data.settings[0].content.preset.presumedBackwallDepth,
            enable:
                !!isFullList && isGridScan && gridScanDataOfInterest === PunditGridScanDataOfInterestType.PulseVelocity,
        },
        {
            label: 'Proceq.LogbookSettingsPulseVelocity',
            unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
            value: data.settings[0].content.process.pulseVelocity,
            enable: !!isFullList && gridScanDataOfInterest !== PunditGridScanDataOfInterestType.PulseVelocity,
        },
        {
            label: 'Proceq.LogbookSettingsPulseVelocityPWave',
            unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
            value: getPWave(
                data.settings[0].content.process.pulseVelocity,
                data.settings[0].content.process.poissonRatio
            ),
            enable: !!isFullList,
        },
        {
            label: 'Proceq.LogbookSettingsCompressiveStrength',
            value: data.settings[0].content.process.compressiveStrength
                ? formatMessage({
                      id: `Proceq.LogbookSettingsCompressiveStrength.${data.settings[0].content.process.compressiveStrength}` as FormatIDs,
                  })
                : '-',
            enable: !!isFullList,
        },
        {
            label: 'Proceq.LogbookSettingsPoissonRatio',
            unitId: 'PUNDIT.Settings Panel.Poisson Ratio',
            value: data.settings[0].content.process.poissonRatio,
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.TxFrequency',
            unitId: 'PUNDIT.Snapshot.Center Frequency',
            value: get(data, 'settings.0.content.preset.transmitFrequency') / 1000,
            enable: isEqualOrAfterPD8050Launch(version) && !!isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.MeasurementPresets.TxVoltage',
            unitId: 'PUNDIT.MeasurementPresets.Energy',
            value: get(data, 'settings.0.content.preset.transmitVoltage'),
            enable: isEqualOrAfterPD8050Launch(version) && !!isFullList,
        },
        {
            label: 'App.HTML.PUNDIT.Display.MaxTransmissionTime',
            unitId: 'PUNDIT.Logbook Panel.Max Transmission Time',
            value: get(data, 'settings.0.content.preset.numberOfSamples'),
            enable: !isEnableMacro || isEqualOrAfterPD8050Launch(version),
        },
    ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};
