import { pkceUtils, routeUtils } from 'tds-common-fe';
import { Dispatch } from 'redux';
import { simpleID } from './mathUtils';
import routeURLs from '../components/Routes/urls';
import * as storageUtils from './storageUtils';
import config from '../config';
import { getDefaultLanguage, LanguageOptions } from '../localization';
import { getApiURL } from '../api/ApiURL';
import { storagePersistor } from '../store';
import * as profileActions from '../actions/profile';
import authInfo from '../api/AuthInfo';
import { API_SCOPE } from '../constants';
import { getScreeningEagleHeaders } from '../api/headerUtils';

export const defaultEagleIDRedirectUri = (language?: LanguageOptions | string) => {
    if (language) {
        return `${location.origin}${routeURLs.AUTH}?lang=${language}`;
    }
    return `${location.origin}${routeURLs.AUTH}`;
};

export const appEagleIDRedirectUri = (language?: LanguageOptions) => {
    if (language) {
        return `${location.origin}${routeURLs.AUTH}?appAuth=true&lang=${language}`;
    }
    return `${location.origin}${routeURLs.AUTH}?appAuth=true`;
};

export const checkEagleIDState = (state: string | null): Boolean => {
    return storageUtils.getSessionItem('eagleIDState') === state;
};

export const getRedirectURI = (language?: LanguageOptions | string): string => defaultEagleIDRedirectUri(language);

export const getSharedParams = (includeLanguage: boolean = false) => {
    const randomState = simpleID(6);
    storageUtils.saveSessionItem('eagleIDState', randomState);
    const language = getDefaultLanguage();
    const redirectURI = getRedirectURI(includeLanguage ? language : undefined);

    return {
        redirect_uri: redirectURI,
        client_id: config.EAGLE_ID_CLIENT_ID,
        state: randomState,
        product: 'workspace',
        client: 'web',
        version: config.APP_VERSION,
        lang: language,
        includeLang: 'true',
    };
};

export const redirectEagleIDLogin = (withCustomError: boolean = false, extraParams: { [key: string]: string } = {}) => {
    const path = getApiURL('CHECK_EAGLE_ID_AUTH');

    const { code_verifier: codeVerifier, ...otherPkceInfo } = pkceUtils.getPkceInfo();
    storageUtils.saveSessionItem('codeVerifier', codeVerifier);

    const screeningEagleHeaders = getScreeningEagleHeaders();

    const url = routeUtils.makeQueryPath(path, {
        ...screeningEagleHeaders,
        ...getSharedParams(true),
        response_type: 'code',
        scope: API_SCOPE,
        error: withCustomError ? 'loginFailed' : undefined,
        ...otherPkceInfo,
        ...extraParams,
    });

    window.open(url, '_self');
};

export const redirectEagleIDLogout = () => {
    const path = getApiURL('EAGLE_ID_LOGOUT');
    const url = routeUtils.makeQueryPath(path, getSharedParams());
    window.open(url, '_self');
};

// Sign Up
export const redirectEagleIDSignUp = () => {
    const path = routeURLs.EAGLE_ID_SIGN_UP_UI;

    const { inviteID, token, username } = routeUtils.parseQuery<{
        inviteID: string;
        token: string;
        username: string;
    }>(location.search);

    const url = routeUtils.makeQueryPath(path, {
        ...getSharedParams(),
        response_type: 'code',
        scope: API_SCOPE,
        inviteID,
        username,
        token,
    });

    window.open(url, '_self');
};

// Reset Password
export const redirectEagleIDResetPassword = () => {
    const path = routeURLs.EAGLE_ID_RESET_PASSWORD_UI;

    const { token, username } = routeUtils.parseQuery<{
        token: string;
        username: string;
    }>(location.search);

    const url = routeUtils.makeQueryPath(path, {
        ...getSharedParams(),
        response_type: 'code',
        scope: API_SCOPE,
        username,
        token,
    });

    window.open(url, '_self');
};

// Reference: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export const parseJwt = (token: string) => {
    const base64Str = token.split('.')[1];
    const base64 = base64Str.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = atob(base64);
    const result = JSON.parse(jsonPayload);
    return result;
};

export const cleanUpCredentials = async (dispatch: Dispatch) => {
    await storagePersistor.purge();
    dispatch(profileActions.cleanUpData());
    authInfo.clearInfo();
};
