import React from 'react';
import { SnapshotEntry, SnapshotType } from '../components/DataView/DataViewers/Readings/SnapshotsViewer/SnapshotTypes';
import { FormatIDs } from '../types';
import { formatDateTimeDefault } from '../utils/dateUtils';
import { renderUserInitials } from '../components/DataView/DataViewers/LogEvent/LogEvent';
import useProceqUsers from './useProceqUsers';
import { useFormatMessage } from '../localization/useFormatMessage';
import AnalyticsButton from '../components/AnalyticsComponents/Button';
import FormattedMessage from '../localization/FormatMessage';
import { ReactComponent as DownloadIcon } from '../images/iconImport.svg';

const SNAPSHOT_TITLES: { [key in SnapshotType]: FormatIDs } = {
    [SnapshotType.ExportedSnapshot]: 'DataView.Snapshot.Exported',
    [SnapshotType.SavedSnapshot]: 'DataView.Snapshot.Saved',
    [SnapshotType.LatestViewedSnapshot]: 'DataView.Snapshot.LastViewed',
};

interface UseSnapshotInfoParams {
    entry?: SnapshotEntry;
    onDownload?: () => void;
    primaryButton?: boolean;
    useDownloadIcon?: boolean;
}

const useSnapshotInfo = ({ entry, primaryButton, onDownload, useDownloadIcon }: UseSnapshotInfoParams) => {
    const formatMessage = useFormatMessage();
    const { users } = useProceqUsers();

    if (!entry) return { title: null, initials: null };

    const { type } = entry;
    const date = entry.log?.clientCreated;
    const uID = entry.log?.uID;
    const formattedDate = date === undefined ? '' : formatDateTimeDefault(date);
    const initials = renderUserInitials(users[String(uID)]);

    const fileName = entry.imageName || `${entry.log?.content.attachmentName}.png`;
    const imageUrl = entry.imageURL;

    return {
        title: formatMessage({ id: SNAPSHOT_TITLES[type] }),
        initials: `${formattedDate}${formattedDate && initials ? ' • ' : ''}${initials && formatMessage({ id: 'DataView.Snapshot.ByUser' }, { userName: initials })}`,
        downloadComponent: (
            <a
                href={imageUrl}
                download={fileName}
                onClick={(event) => {
                    event.stopPropagation();
                    onDownload?.();
                }}
            >
                <AnalyticsButton type={useDownloadIcon ? 'link' : primaryButton ? 'primary' : 'default'}>
                    {useDownloadIcon ? <DownloadIcon /> : <FormattedMessage id="App.Download" />}
                </AnalyticsButton>
            </a>
        ),
    };
};

export default useSnapshotInfo;
