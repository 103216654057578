import React from 'react';
import MenuList from '../shared/MenuList';
import styles from './DataView.styl';
import FormattedMessage from '../../localization/FormatMessage';
import { IconSpatialData } from './FolderIcons';
import Tooltip from '../shared/Tooltip';
import { FOLDER_COUNT_PARAM, SPATIAL_DATA_FILE_TYPE } from '../../types/userFiles';
import { useUserFileList } from '../../queries/userFileQueries';
import { useDataViewContext } from './DataViewProvider';

interface SpatialDataFolderProps {
    activeItem?: string;
    onSelect?: (key: string) => void;
}

const SpatialDataFolder: React.FunctionComponent<SpatialDataFolderProps> = (props) => {
    const { activeItem, onSelect } = props;
    const { product } = useDataViewContext();

    // Folder count obtained by setting no filters and keeping limit as 1
    const { data } = useUserFileList({ product, ...FOLDER_COUNT_PARAM });

    const SPATIAL_DATA_MENU = [
        {
            id: SPATIAL_DATA_FILE_TYPE,
            title: (
                <Tooltip title={<FormattedMessage id="DataView.Folder.SpatialData.Tooltip" />} placement="right">
                    <div className={styles.menu_title_content}>
                        <IconSpatialData />
                        <span>
                            <FormattedMessage id="DataView.Folder.SpatialData" />
                        </span>
                    </div>
                </Tooltip>
            ),
            count: data?.totalRecords ?? 0,
        },
    ];

    return <MenuList menuItems={SPATIAL_DATA_MENU} activeItem={activeItem} onSelect={onSelect} />;
};

export default SpatialDataFolder;
