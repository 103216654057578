import React, { useCallback, useContext, useState } from 'react';
import { ProductCode } from '../../types/proceq';
import { DataViewContext } from './DataViewContext';
import { CustomCurveFileType, SystemFolderID, ViewType } from '../../types/measurement';
import { useProductContext } from './ProductContextProvider';

export const DataViewProvider: React.FunctionComponent = (props) => {
    const { product, setProduct } = useProductContext();
    const [viewType, setViewType] = useState(ViewType.Active);
    const [activeFolder, setActiveFolder] = useState<string>(SystemFolderID.All);
    const [fileType, setFileType] = useState('');
    const [withUnsynced, setWithUnsynced] = useState(false);

    const handleSetProduct = useCallback(
        (product: ProductCode, folderID?: string) => {
            setProduct(product);
            if (folderID === CustomCurveFileType.dgscc) {
                // custom curves fall under the folderID 'all'
                setActiveFolder(SystemFolderID.All);
            } else {
                setActiveFolder(folderID ?? SystemFolderID.All);
            }
            setFileType('');
        },
        [setProduct]
    );

    return (
        <DataViewContext.Provider
            value={{
                product,
                setProduct: handleSetProduct,
                viewType,
                setViewType,
                activeFolder,
                setActiveFolder,
                fileType,
                setFileType,
                withUnsynced,
                setWithUnsynced,
            }}
        >
            {props.children}
        </DataViewContext.Provider>
    );
};

export const useDataViewContext = () => {
    return useContext(DataViewContext);
};
