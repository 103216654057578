import React from 'react';
import { RcFile } from 'antd/lib/upload';
import { ReactComponent as TrashIcon } from '../../../images/iconTrash.svg';
import { ReactComponent as FileIcon } from '../../../images/iconFile.svg';
import { convertBytes } from '../../../utils/mathUtils';
import styles from './ButtonUpload.styl';
import AnalyticsButton from '../../AnalyticsComponents/Button';

interface UploadedFileCardProps {
    file?: RcFile;
    onDelete: () => void;
}

const UploadedFileCard: React.FunctionComponent<UploadedFileCardProps> = (props) => {
    const { file, onDelete } = props;

    return file ? (
        <div className={styles.file_card_container}>
            <div className={styles.file_name_container}>
                <FileIcon />
                <div className={styles.file_name}>{file.name}</div>
                <div className={styles.byte}>{convertBytes(file.size)}</div>
            </div>
            <AnalyticsButton className={styles.trash_button} type="link" onClick={onDelete}>
                <TrashIcon />
            </AnalyticsButton>
        </div>
    ) : null;
};

export default UploadedFileCard;
