import { useQuery } from '@tanstack/react-query';
import * as probeService from '../api/probeService';
import { ReactQueryKeys } from './queryKeys';

export const useProbesList = () =>
    useQuery({
        queryKey: ReactQueryKeys.probeList,
        queryFn: probeService.getProbesList,
        staleTime: Infinity, // this call is expensive, only call once and use available data thereafter
        refetchOnWindowFocus: false,
    });
