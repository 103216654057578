import { AnyAction, Reducer } from 'redux';
import actionTypes, { PayloadAction } from '../actions/actionTypes';
import { ReceivedProceqAppConfigs } from '../actions/config';
import { ProductCode } from '../types/proceq';
import { ReceivedAppsStatusPayload, ReceivedPlanTiersPayload } from '../actions/config';
import { LicenseProduct, LicenseTier } from '../types/license';
import { DEFAULT_MAX_FILE_SIZE } from '../types/userFiles';

interface Configs {
    [key: string]: string | number;
}

type AppsStatus = {
    [key in ProductCode | 'INSPECT' | string]?: {
        active: boolean;
        url?: string;
        eligibleFreeTrial?: boolean;
    };
};

export interface ConfigState {
    proceqAppConfigs: { [key in ProductCode]?: Configs };
    appsStatus: AppsStatus;
    appsList: (ProductCode | 'INSPECT')[];
    planTiers: {
        [key in ProductCode | LicenseProduct]?: LicenseTier[];
    };
    maxFileDownloadSize: number;
    maxFileUploadSize: number;
}

export const initialState: ConfigState = {
    proceqAppConfigs: {},
    appsStatus: {},
    appsList: [],
    planTiers: {},
    maxFileDownloadSize: 0,
    maxFileUploadSize: DEFAULT_MAX_FILE_SIZE,
};

const config: Reducer<ConfigState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RECEIVED_PROCEQ_APP_CONFIG: {
            const { payload } = action as PayloadAction<ReceivedProceqAppConfigs>;
            const { product, appConfigs } = payload;
            state.proceqAppConfigs = {
                ...state.proceqAppConfigs,
                [product]: { ...appConfigs },
            };
            state.maxFileDownloadSize = Number(appConfigs.MAX_FILE_DOWNLOAD_SIZE_COMMON);
            state.maxFileUploadSize = Number(appConfigs.MAX_FILE_UPLOAD_SIZE_COMMON);
            return state;
        }

        case actionTypes.RECEIVED_APPS_STATUS: {
            const { payload } = action as PayloadAction<ReceivedAppsStatusPayload>;
            const { productsStatus } = payload;

            const appsStatus: AppsStatus = {};
            const appList: (ProductCode | 'INSPECT')[] = [];

            for (const item of productsStatus) {
                const productCode = item.product.toUpperCase() as ProductCode;
                appList.push(productCode);
                appsStatus[productCode] = item;
            }

            state.appsStatus = appsStatus;
            state.appsList = appList;
            return state;
        }

        case actionTypes.RECEIVED_PLAN_TIERS: {
            const { payload } = action as PayloadAction<ReceivedPlanTiersPayload>;
            const { product, tiers } = payload;

            state.planTiers = {
                ...state.planTiers,
                [product.toUpperCase()]: tiers,
            };

            return state;
        }

        default:
            return state;
    }
};

export default config;
