import { Modal, ModalProps } from 'antd';
import React from 'react';
import { ReactComponent as IconClose } from '../../../images/iconClose.svg';
import './StyledModal.global.styl';
import colors from '../../../styles/colors.json';
import { classNames } from '../../../utils/styleUtils';

interface StyledModalProps extends ModalProps {
    title?: string | React.ReactNode;
    defaultTitle?: boolean; // set as true to use title as it is without wrapping in h3
}

const StyledModal: React.FunctionComponent<StyledModalProps> = (props) => {
    const { title, defaultTitle, children, className, ...restProps } = props;

    return (
        <Modal
            {...restProps}
            className={classNames('styled-modal', className)}
            wrapClassName="styled-modal-wrapper"
            maskStyle={{ backgroundColor: colors.dark_mask }}
            title={defaultTitle ? title : title ? <h3 className="modal-title">{title}</h3> : undefined}
            closeIcon={<IconClose />}
        >
            {children}
        </Modal>
    );
};

export default StyledModal;
