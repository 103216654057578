import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import { renderFolderName } from '../../utils/renderFolderName';
import { ScanType } from '../../../../../types/proceq';
import { MeasurementFullData } from '../../../../../types/measurement';
import { FormatMessageFunc } from '../../../../../types';
import { getPunditImpactMode } from '../../../../../utils/getMeasurementMode';

export const getPunditImpactMeasurementPresets = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc,
    isFullList?: boolean
) => {
    if (!data) {
        return [];
    }
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.GPR.MeasurementPresets.FileName',
            value: data.measurement.name,
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.FileID',
            value: data.measurement.id,
            enable: !!isFullList,
        },
        {
            label: 'Proceq.StatisticsFolder',
            value: renderFolderName(data, formatMessage),
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.Mode',
            value: getPunditImpactMode(scanType, { count: data.measurement.content.piles.length }),
            enable: !!isFullList,
        },
        {
            label: 'App.Logbook.FDL.Item.velocity',
            unitId: 'PIT_IE.Settings Panel.Pulse Velocity',
            value: data.settings[0].content.processing.velocity,
            enable: !!isFullList,
        },
        {
            label: 'App.HTML.PI8000.MeasurementPresets.ImpactorSize',
            unitId: 'PIT_IE.Settings Panel.Impactor Size',
            value: data.settings[0].content.preset?.impactorSize,
            enable: !!isFullList && scanType !== ScanType.AScan,
        },
    ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};
