import React from 'react';
import { noop } from 'lodash';
import { UserFile, UserFileExtensions } from '../../../types/userFiles';

export interface UserFilesContextState {
    userFiles: UserFile[];
    isFetching: boolean;
    fileCount: number;

    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    setPageSize: (pageSize: number) => void;

    resetConfig: () => void;

    orderBy: string | undefined;
    setOrderBy: (name: string | undefined) => void;

    searchText: string | undefined;
    setSearchText: (value: string | undefined) => void;

    showRenameModal: { id: string; currentName: string; extension: string } | undefined;
    setShowRenameModal: (param: { id: string; currentName: string; extension: string } | undefined) => void;

    showDeleteConfirmationModal: { ids: string[]; name?: string } | undefined;
    setShowDeleteConfirmationModal: React.Dispatch<React.SetStateAction<{ ids: string[]; name?: string } | undefined>>;

    selectedKeys: string[];
    setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;

    showUploadModal: boolean;
    setShowUploadModal: (show: boolean) => void;

    fileExtensions: { [id: string]: UserFileExtensions };

    // query params that should be used when invalidating react query's user file list
    fileListQueryParams: { [key: string]: any };
}

export const UserFilesContext = React.createContext<UserFilesContextState>({
    userFiles: [],
    isFetching: false,
    fileCount: 0,

    page: 1,
    setPage: noop,
    pageSize: 20,
    setPageSize: noop,

    resetConfig: noop,

    orderBy: undefined,
    setOrderBy: noop,

    searchText: undefined,
    setSearchText: noop,

    showRenameModal: undefined,
    setShowRenameModal: noop,

    showDeleteConfirmationModal: undefined,
    setShowDeleteConfirmationModal: noop,

    selectedKeys: [],
    setSelectedKeys: noop,

    showUploadModal: false,
    setShowUploadModal: noop,

    fileExtensions: {},

    fileListQueryParams: {},
});
