import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useDataViewContext } from '../DataViewProvider';
import { FormatIDs } from '../../../types';
import FormattedMessage from '../../../localization/FormatMessage';
import StyledModal from '../../shared/StyledModal';
import Logbook from '../DataViewers/Common/Logbook';
import Attachments from '../DataViewers/Common/Attachments';
import { MeasurementJSONView, CustomMaterialJSONView } from '../DataViewers/Common/JSONViewer';
import styles from './DataViewModal.styl';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { exportMeasurements } from '../../../api/utilsService';
import { useMeasurementContext } from '../MeasurementContext/MeasurementProvider';
import { DataViewMode } from '../../../types/measurement';

const getTitle = (viewMode?: DataViewMode): FormatIDs => {
    switch (viewMode) {
        case DataViewMode.Logbook:
            return 'DataView.Modal.Title.Logbook';
        case DataViewMode.Attachments:
            return 'DataView.Modal.Title.Attachments';
        case DataViewMode.JSON:
            return 'DataView.Modal.Title.JSON';
        default:
            return ' ';
    }
};

const DataViewModal: React.FunctionComponent = () => {
    const { product } = useDataViewContext();
    const { modalViewerConfig, setModalViewerConfig } = useMeasurementContext();

    const { viewMode, measurementID, customMaterialID } = modalViewerConfig;

    const visible = viewMode !== undefined && (measurementID !== undefined || customMaterialID !== undefined);

    const [modalWidth, setModalWidth] = useState<CSSProperties['width']>(undefined);

    const onClose = useCallback(() => {
        setModalViewerConfig({});
        setAttachmentsFilteredIDs(undefined);
    }, [setModalViewerConfig]);

    const [downloadLoading, setDownloadLoading] = useState(false);
    const [attachmentsFilteredIDs, setAttachmentsFilteredIDs] = useState<string[] | undefined>(undefined);

    const onDownloadAll = async () => {
        if (!product || !measurementID) return;
        setDownloadLoading(true);
        try {
            const response = await exportMeasurements({
                product,
                mIDs: [measurementID],
                format: 'attachments',
                fileType: 'measurement',
                exportAsURL: true,
                attachmentIDs: attachmentsFilteredIDs,
            });
            if (!(response.data instanceof Blob)) {
                window.open(response.data.url, '_blank');
            }
        } finally {
            setDownloadLoading(false);
        }
    };

    useEffect(() => {
        if (viewMode === DataViewMode.Attachments) {
            setModalWidth('auto');
        }
    }, [viewMode]);

    const getViewer = () => {
        if (measurementID) {
            switch (viewMode) {
                case DataViewMode.Logbook:
                    return <Logbook product={product} measurementID={measurementID} />;
                case DataViewMode.Attachments:
                    return (
                        <Attachments
                            product={product}
                            measurementID={measurementID}
                            onFilter={setAttachmentsFilteredIDs}
                        />
                    );
                case DataViewMode.JSON:
                    return <MeasurementJSONView measurementID={measurementID} />;
            }
        }

        if (customMaterialID) {
            switch (viewMode) {
                case DataViewMode.JSON:
                    return <CustomMaterialJSONView customMaterialID={customMaterialID} />;
            }
        }

        return null;
    };

    return (
        <StyledModal
            className={styles.data_view_modal}
            open={visible}
            title={<FormattedMessage id={getTitle(viewMode)} />}
            onCancel={onClose}
            footer={
                <>
                    {viewMode === DataViewMode.Attachments && (
                        <AnalyticsButton
                            type="primary"
                            onClick={onDownloadAll}
                            loading={downloadLoading}
                            className={styles.download_all}
                            disabled={attachmentsFilteredIDs && attachmentsFilteredIDs.length === 0}
                        >
                            <FormattedMessage id="App.AttachmentDownloadAll" />
                        </AnalyticsButton>
                    )}
                    <AnalyticsButton type="primary" onClick={onClose}>
                        <FormattedMessage id="App.Done" />
                    </AnalyticsButton>
                </>
            }
            width={modalWidth}
            afterClose={() => setModalWidth(undefined)}
            destroyOnClose
        >
            {getViewer()}
        </StyledModal>
    );
};

export default DataViewModal;
