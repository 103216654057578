import React from 'react';
import styles from './Snapshots.styl';
import Spinner from '../../../../shared/Spinner';
import imagePlaceholderSrc from '../../../../../images/imagePlaceholder.svg';
import { SnapshotEntry } from '../SnapshotsViewer/SnapshotTypes';

interface ExportImagesProps {
    entryIDs: string[];
    entries: { [p: string]: SnapshotEntry };
    imagesPerRow: number;
}

// show images as it is instead of using slider
const ExportImages: React.FunctionComponent<ExportImagesProps> = (props) => {
    const { entryIDs, entries, imagesPerRow } = props;
    return (
        <div
            className={styles.HTML_export_container}
            style={entryIDs.length < imagesPerRow ? { justifyContent: 'center' } : {}}
        >
            {entryIDs.map((aID) => {
                const imageURL = entries[aID]?.imageURL;
                const isLoading = imageURL === undefined;
                return (
                    <div
                        className={styles.HTML_export_image}
                        style={{ width: `calc(100% / ${imagesPerRow})` }}
                        key={aID}
                    >
                        <div className={styles.image_wrapper} style={{ cursor: 'pointer' }}>
                            {isLoading ? (
                                <Spinner size="default" />
                            ) : (
                                <a href={imageURL}>
                                    <img src={imageURL || imagePlaceholderSrc} alt="" />
                                </a>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ExportImages;
