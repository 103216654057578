import React from 'react';
import { getASTMImperialDiameterRebarSize, MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { ScanType } from '../../../../../types/proceq';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import SectionTable from '../../shared/SectionTable';
import styles from '../../DataViewers.styl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { getProfometerValue } from './getMeasurementPresets';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { FormatMessageFunc } from '../../../../../types';
import { CORROSION_SCAN_TYPES } from '../../../../../types/profometer';
import { roundToNDigit } from '../../utils/conversionRates';

export const title = <FormattedMessage id={'Proceq.Settings.ImageProcessing'} />;

export const getMeasurementsData = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc
) => {
    if (!data) {
        return [];
    }
    const dataContent = data.settings[0].content;

    const {
        firstLayerRebarDiameter,
        secondLayerRebarDiameter,
        autoRebarSpacing,
        nrc,
        ambientTempInCelsius,
        ambientTempInFahrenheit,
        autoPotentialShift,
        potentialShift,
    } = dataContent.imageProcessing || {};

    const isLineScan = scanType === ScanType.LineScan || scanType === ScanType.MultiLineScan;
    const isLineAreaScan = scanType === ScanType.AreaScan || isLineScan;
    const isCorrosion = CORROSION_SCAN_TYPES.has(scanType);

    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.Profometer.Logbook.ConcreteTemperatureUnits',
            unitId: 'Profometer.Logbook.AmbientTemperature',
            value: isMetric ? ambientTempInCelsius : ambientTempInFahrenheit,
            enable: isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.Logbook.AutoPotentialShift',
            value: autoPotentialShift ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
            enable: isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.Logbook.PotentialShift',
            value: roundToNDigit(potentialShift ?? 0, 0),
            enable: isCorrosion,
        },
        {
            label: isLineScan
                ? isMetric
                    ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameterUnits.LineScan'
                    : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameter.LineScan'
                : isMetric
                  ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameterUnits'
                  : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarDiameter',
            unitId: 'Profometer.Measurement.RebarDiameter',
            value:
                !isMetric && firstLayerRebarDiameter
                    ? getASTMImperialDiameterRebarSize(firstLayerRebarDiameter)
                    : firstLayerRebarDiameter,
            enable: isLineAreaScan && !isCorrosion,
        },
        {
            label: isLineScan
                ? isMetric
                    ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameterUnits.LineScan'
                    : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameter.LineScan'
                : isMetric
                  ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameterUnits'
                  : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarDiameter',
            unitId: 'Profometer.Measurement.RebarDiameter',
            value:
                !isMetric && secondLayerRebarDiameter
                    ? getASTMImperialDiameterRebarSize(secondLayerRebarDiameter)
                    : secondLayerRebarDiameter,
            enable: isLineAreaScan && !isCorrosion,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.AutoRebarSpacingA1',
            value: autoRebarSpacing ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
            enable: !isCorrosion && nrc && isLineAreaScan,
        },
        {
            label: isLineScan
                ? 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarSpacing.LineScan'
                : 'App.HTML.Profometer.MeasurementSettings.FirstLayerRebarSpacing',
            unitId: 'Profometer.Measurement.RebarSpacing',
            value: dataContent.imageProcessing?.firstLayerRebarSpacing,
            enable: !isCorrosion && nrc && !autoRebarSpacing && isLineAreaScan,
        },
        {
            label: isLineScan
                ? 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarSpacing.LineScan'
                : 'App.HTML.Profometer.MeasurementSettings.SecondLayerRebarSpacing',
            unitId: 'Profometer.Measurement.RebarSpacing',
            value: dataContent.imageProcessing?.secondLayerRebarSpacing,
            enable: !isCorrosion && nrc && isLineAreaScan,
        },
        {
            label: 'App.HTML.Profometer.MeasurementSettings.AlignRebarPosition',
            value: dataContent.imageProcessing?.alignRebarPositions
                ? formatMessage({ id: 'Proceq.On' })
                : formatMessage({ id: 'Proceq.Off' }),
            enable: scanType === ScanType.MultiLineScan,
        },
    ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric, getProfometerValue(convert));
};

const ImageProcessing: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, scanType, isMetric, convert, showTitle } = props;
    const formatMessage = useFormatMessage();
    const sectionTableData = getMeasurementsData(data, scanType, isMetric, convert, formatMessage);

    return (
        <SectionTable
            showTitle={showTitle}
            className={[styles.table, 'table-measurement-presets'].join(' ')}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={sectionTableData}
        />
    );
};

export default ImageProcessing;
