import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { browserUtils, dateUtils, routeUtils } from 'tds-common-fe';
import { LoadingOutlined } from '@ant-design/icons';
import { useUserLicense } from '../../../hooks/useCurrentUser';
import { FormatIDs } from '../../../types';
import { ProductCode } from '../../../types/proceq';
import {
    ActivateLicenseProduct,
    LicenseParamProductMap,
    LicenseProduct,
    UserLicenseInfo,
    UserLicenseParams,
} from '../../../types/license';
import { formatDate } from '../../../utils/dateUtils';
import FormattedMessage from '../../../localization/FormatMessage';
import AppIcon, { productCodeToIconMap, ProductName } from '../../shared/AppIcon';
import * as appService from '../../../api/appService';
import styles from './UserLicense.styl';
import { SUPPORT_CONTACT_URL } from '../constants';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { useAppsStatus, usePlanTiers } from '../../../hooks/useConfig';
import FreeTrialModal, { LicenseActionState } from '../../License/FreeTrialModal';
import ActivateLicenseModal from './ActivateLicenseModal';
import { getLabelFromGlobalDataKey } from '../../DataView/RegisteredInfo/helper';
import ExpandButton from '../../shared/Buttons/ExpandButton';
import TierFeatures from './TierFeatures';
import analytics from '../../../analytics/firebaseAnalytics';
import { SubscriptionAction } from '../../../analytics/analyticsConstants';
import { useActiveApps } from '../../../queries/appQueries';
import { useLicenseList } from '../../../queries/userQueries';
import UpgradeModal from '../../License/UpgradeModal';
import { useProductBinaries } from '../../../queries/productQueries';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { downloadBinaryFile } from '../../../utils/fileUtils';
import Tooltip from '../../shared/Tooltip';

const getLicenseDescription = (amount: number): FormatIDs => {
    switch (amount) {
        case 0:
            return 'License.Count.Description.Empty';
        case 1:
            return 'License.Count.Description.Singular';
        default:
            return 'License.Count.Description';
    }
};

type LicenseFreeTrialProps = {
    onActivateLicense: (showActivateModal: boolean) => void;
};

const LicenseFreeTrial: React.FunctionComponent<LicenseFreeTrialProps> = (props) => {
    const { onActivateLicense } = props;
    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const formatMessage = useFormatMessage();

    const planTiers = usePlanTiers(ProductCode.GPR_INSIGHTS);
    const firstWebLicenseName = planTiers?.find((tier) => !tier.free && !tier.requireDeviceID)?.name ?? '';
    const { data } = useProductBinaries(ProductCode.GPR_INSIGHTS);
    const binaryFileName = data?.binaries?.['win_x86-64'];
    const isWindowsOS = browserUtils.getOSName(true).includes('windows');

    return (
        <div className={styles.license_group_container}>
            <div className={styles.license_item_free}>
                <div className={styles.icon_wrapper}>
                    <AppIcon product={ProductName.GPRInsights} size={40} />
                </div>
                <div>
                    <div className={styles.body1}>
                        <FormattedMessage id="License.Subscription.GPRInsightsFreeTrialCaption" />
                    </div>
                </div>
                <div className={styles.right_container}>
                    <AnalyticsButton type="link" onClick={() => setShowFreeTrialModal(true)}>
                        <FormattedMessage id="License.Subscription.TryItNow" />
                    </AnalyticsButton>
                </div>
                <FreeTrialModal
                    open={showFreeTrialModal}
                    productCode={ProductCode.GPR_INSIGHTS}
                    licenseActionState={LicenseActionState.activateFreeTrial}
                    onClose={() => setShowFreeTrialModal(false)}
                />
            </div>
            <div className={styles.free_trial_activate_now_container}>
                <FormattedMessage
                    id="License.Subscription.AlreadyHaveAccess"
                    values={{
                        activateNow: (
                            <AnalyticsButton
                                type="link"
                                className={styles.activate_now_button}
                                onClick={() => onActivateLicense(true)}
                            >
                                <FormattedMessage
                                    id="License.Subscription.AlreadyHaveAccess.ActivateNow"
                                    values={{ license: firstWebLicenseName }}
                                />
                            </AnalyticsButton>
                        ),
                        downloadStandalone: (
                            <Tooltip
                                title={
                                    isWindowsOS ? undefined : (
                                        <FormattedMessage id="Profile.Subscriptions.WindowsOnly" />
                                    )
                                }
                            >
                                <div>
                                    <AnalyticsButton
                                        type="link"
                                        className={styles.activate_now_button}
                                        disabled={!isWindowsOS || isDownloading}
                                        onClick={() => {
                                            if (binaryFileName) {
                                                setIsDownloading(true);
                                                downloadBinaryFile({
                                                    formatMessage,
                                                    product: ProductCode.GPR_INSIGHTS,
                                                    fileName: binaryFileName,
                                                    onComplete: () => setIsDownloading(false),
                                                });
                                            }
                                        }}
                                    >
                                        <FormattedMessage id="License.Subscription.AlreadyHaveAccess.DownloadStandalone" />
                                        {isDownloading && (
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined style={{ fontSize: 12, marginLeft: 5 }} spin />
                                                }
                                            />
                                        )}
                                    </AnalyticsButton>
                                </div>
                            </Tooltip>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

enum LicenseGroupType {
    active,
    expired,
}

type LicenseGroupProps = {
    title: FormatIDs;
    licenseKeys: (ProductCode | LicenseProduct)[];
    licenseGroupType: LicenseGroupType;
    onActivate: (activate: boolean) => void;
};

const LicenseGroup: React.FunctionComponent<LicenseGroupProps> = (props) => {
    const { title, licenseKeys, licenseGroupType, onActivate } = props;
    const { license } = useUserLicense();

    return (
        <div className={styles.license_group_container}>
            <span className={styles.license_group_title}>
                <FormattedMessage id={title} />
            </span>
            {licenseKeys.map((product) => {
                const productLicense = license[product];
                return (
                    <LicenseItem
                        product={product}
                        license={productLicense}
                        key={product}
                        licenseGroupType={licenseGroupType}
                        onActivate={onActivate}
                    />
                );
            })}
        </div>
    );
};

type LicenseItemProps = {
    product: ProductCode | LicenseProduct;
    license: Partial<UserLicenseInfo>;
    licenseGroupType: LicenseGroupType;
    onActivate: (activate: boolean) => void;
};

const LicenseItem: React.FunctionComponent<LicenseItemProps> = (props) => {
    const { license, product, licenseGroupType, onActivate } = props;
    const productName = isProductCode(product) ? productCodeToIconMap[product] : undefined;
    const { expirationDate } = license;
    const { expired } = dateUtils.getDurationInfo(expirationDate ?? 0);
    const shouldShowExpiration = expirationDate !== 0;
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const tiers = usePlanTiers(product);
    const { activeLicenseKeys, expiredLicenseKeys } = useUserLicense();

    const productFullName = getLabelFromGlobalDataKey('availproductsfull', product);
    const formattedDate = expirationDate !== undefined ? formatDate(expirationDate) : '';

    const formatMessage = useFormatMessage();
    const { data } = useProductBinaries(ProductCode.GPR_INSIGHTS);
    const binaryFileName = data?.binaries?.['win_x86-64'];
    const [isDownloading, setIsDownloading] = useState(false);

    // includes any tier that has higher rank that current tier that DOES NOT require device id during activation
    const hasUpgradableWebTier = tiers
        ?.filter((tier) => tier.rank > (license.tier?.rank ?? 0))
        .some((tier) => !tier.requireDeviceID);

    const isFreeTier = license.tier?.free;

    useEffect(() => {
        setShowDetails(false);
    }, [activeLicenseKeys, expiredLicenseKeys]);

    const activateButton = (
        <AnalyticsButton
            type="link"
            onClick={() => {
                onActivate(true);
                analytics.logSubscriptionEvent(SubscriptionAction.mySubscriptionsActivate, product);
            }}
        >
            <FormattedMessage id="License.Activate" />
        </AnalyticsButton>
    );

    const upgradeButton = (
        <AnalyticsButton
            type="link"
            onClick={() => {
                setShowUpgradeModal(true);
                analytics.logSubscriptionEvent(SubscriptionAction.mySubscriptionsUpgrade, product);
            }}
        >
            <FormattedMessage id="License.Action.Upgrade" />
        </AnalyticsButton>
    );

    const actionButtons = (
        <>
            {hasUpgradableWebTier && activateButton}
            {upgradeButton}
            {license.tier?.requireDeviceID && binaryFileName && (
                <AnalyticsButton
                    type="link"
                    disabled={isDownloading}
                    onClick={() => {
                        setIsDownloading(true);
                        analytics.logSubscriptionEvent(SubscriptionAction.mySubscriptionsDownload, product);
                        downloadBinaryFile({
                            formatMessage,
                            product: product as ProductCode,
                            fileName: binaryFileName,
                            onComplete: () => setIsDownloading(false),
                        });
                    }}
                >
                    <FormattedMessage id="App.Download" />
                </AnalyticsButton>
            )}
        </>
    );

    const getActionButtons = () => {
        // not supporting any license related actions for inspect currently
        if (product === LicenseProduct.inspect) return undefined;

        if (licenseGroupType === LicenseGroupType.expired) {
            return (
                <>
                    {activateButton}
                    {upgradeButton}
                </>
            );
        }

        if (isFreeTier || license.tier?.upgradable) {
            return actionButtons;
        }
        return undefined;
    };

    return (
        <>
            <div className={styles.license_item}>
                <div className={styles.icon_wrapper}>
                    <AppIcon product={productName} size={40} />
                </div>
                <div>
                    <div className={styles.body1}>{productFullName}</div>
                    <div className={styles.caption}>{license.tier?.name ?? ''}</div>
                </div>
                <div className={styles.right_container}>
                    <div className={styles.expiration_text}>
                        {expirationDate !== undefined && shouldShowExpiration ? (
                            expired ? (
                                <FormattedMessage id="License.CurrentPlan.Expired" values={{ date: formattedDate }} />
                            ) : (
                                <FormattedMessage
                                    id={
                                        isFreeTier
                                            ? 'License.CurrentPlan.Expiration'
                                            : 'License.CurrentPlan.NextBillingDate'
                                    }
                                    values={{ date: formattedDate }}
                                />
                            )
                        ) : (
                            <span>&nbsp;</span>
                        )}
                    </div>
                    <div className={styles.license_button_container}>
                        {getActionButtons()}
                        <ExpandButton
                            isActive={showDetails}
                            className={styles.expand_icon}
                            onClick={(isActive: boolean) => {
                                setShowDetails(!isActive);
                                analytics.logSubscriptionEvent(SubscriptionAction.mySubscriptionsExpand, product);
                            }}
                        />
                    </div>
                </div>
            </div>
            {showDetails && tiers && (
                <TierFeatures
                    product={product}
                    tiers={tiers}
                    currentTier={license.tier}
                    isFreeTrial={isFreeTier ?? false}
                    expirationDate={formattedDate}
                    binaryFileName={binaryFileName}
                />
            )}
            <UpgradeModal
                license={license}
                product={product}
                onCancel={() => setShowUpgradeModal(false)}
                open={showUpgradeModal}
            />
        </>
    );
};

const UserLicense: React.FunctionComponent = () => {
    const query = routeUtils.parseQuery<UserLicenseParams>(location.search);
    const { product, licenseKey } = query;
    const { license, activeLicenseKeys, expiredLicenseKeys } = useUserLicense();
    const { appsStatus } = useAppsStatus();
    const [showActivateModal, setShowActivateModal] = useState<boolean>(false);

    const { isSuccess: isSuccessActiveApps, isLoading: isLoadingActiveApps } = useActiveApps();
    const { isSuccess: isSuccessLicenses, isLoading: isLoadingLicenses } = useLicenseList();

    useEffect(() => {
        if (isSuccessLicenses && isSuccessActiveApps) {
            if (!!product && !!licenseKey) {
                const licenseProduct = LicenseParamProductMap[product as ActivateLicenseProduct];
                if (licenseProduct === LicenseProduct.gpr_insights) {
                    if (
                        license[licenseProduct] === undefined ||
                        license[licenseProduct].active === false ||
                        license[licenseProduct].tier?.free
                    ) {
                        setShowActivateModal(true);
                    }
                }
            }
        }
    }, [isSuccessActiveApps, isSuccessLicenses, license, licenseKey, product]);

    useEffect(() => {
        for (const product in LicenseProduct) {
            appService.getLicenseTier(product as LicenseProduct);
        }
    }, []);

    const shouldShowLoading = (isLoadingLicenses || isLoadingActiveApps) && !activeLicenseKeys.length;
    return (
        <>
            {!shouldShowLoading && (
                <p>
                    <FormattedMessage
                        id={getLicenseDescription(activeLicenseKeys.length)}
                        values={{ count: activeLicenseKeys.length }}
                    />
                </p>
            )}
            {showActivateModal && (
                <ActivateLicenseModal
                    product={
                        product
                            ? LicenseParamProductMap[product as ActivateLicenseProduct]
                            : LicenseProduct.gpr_insights
                    }
                    onClose={() => setShowActivateModal(false)}
                    licenseKey={licenseKey ? licenseKey.toUpperCase() : undefined}
                />
            )}
            <div className={styles.license_wrapper}>
                {shouldShowLoading ? (
                    <Spin size="default" />
                ) : (
                    <>
                        {appsStatus[ProductCode.GPR_INSIGHTS]?.eligibleFreeTrial && (
                            <LicenseFreeTrial onActivateLicense={setShowActivateModal} />
                        )}
                        {activeLicenseKeys.length > 0 && (
                            <LicenseGroup
                                title="License.Subscription.Active"
                                licenseKeys={activeLicenseKeys}
                                licenseGroupType={LicenseGroupType.active}
                                onActivate={setShowActivateModal}
                            />
                        )}
                        {expiredLicenseKeys.length > 0 && (
                            <LicenseGroup
                                title="License.Subscription.Expired"
                                licenseKeys={expiredLicenseKeys}
                                licenseGroupType={LicenseGroupType.expired}
                                onActivate={setShowActivateModal}
                            />
                        )}
                    </>
                )}
            </div>

            <div className={styles.caption}>
                <FormattedMessage
                    id="License.Contact"
                    values={{
                        supportUnit: (
                            <a href={SUPPORT_CONTACT_URL} rel="noreferrer" target="_blank">
                                <FormattedMessage id="App.SupportUnit" />
                            </a>
                        ),
                    }}
                />
            </div>
        </>
    );
};

function isProductCode(product: string): product is ProductCode | 'INSPECT' {
    return product in productCodeToIconMap;
}

export default UserLicense;
