import React from 'react';
import { MeasurementFullData } from '../../../../../../types/measurement';
import { DerivedProps } from '../../../SharedTypes';
import SectionTable from '../../../shared/SectionTable';
import styles from '../../../DataViewers.styl';
import FormattedMessage from '../../../../../../localization/FormatMessage';
import { SectionTableData } from '../../../../../../utils/tableDataUtils';
import { getStatisticsValue } from './statisticsUtils';

const AdvancedStatistics: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, showTitle } = props;
    const statisticsData = data.measurement?.content?.statistics;
    const sectionData: SectionTableData[] = [
        {
            key: 'count',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.NumberOfReadings" />,
            value: statisticsData?.noOfReadings,
        },
        {
            key: 'mean',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.Mean" values={{ unit: 'mV' }} />,
            value: getStatisticsValue({ roundToDigit: 0, value: statisticsData?.mean }),
        },
        {
            key: 'median',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.Median" values={{ unit: 'mV' }} />,
            value: getStatisticsValue({ roundToDigit: 0, value: statisticsData?.median }),
        },
        {
            key: 'stdev',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.StDev" values={{ unit: 'mV' }} />,
            value: getStatisticsValue({ roundToDigit: 1, value: statisticsData?.stdDeviation }),
        },
        {
            key: 'min',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.Min" values={{ unit: 'mV' }} />,
            value: getStatisticsValue({ roundToDigit: 0, value: statisticsData?.min }),
        },
        {
            key: 'Max',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.Max" values={{ unit: 'mV' }} />,
            value: getStatisticsValue({ roundToDigit: 0, value: statisticsData?.max }),
        },
        {
            key: 'PassiveThreshold',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.PassiveThreshold" />,
            value: getStatisticsValue({ roundToDigit: 0, value: statisticsData?.passiveThreshold }),
        },
        {
            key: 'CorrosionThreshold',
            label: <FormattedMessage id="App.HTML.Profometer.Statistics.CorrosionThreshold" />,
            value: getStatisticsValue({ roundToDigit: 0, value: statisticsData?.corrosionThreshold }),
        },
    ];

    return (
        <SectionTable
            showTitle={showTitle}
            className={[styles.table, 'table-measurement-presets'].join(' ')}
            title={
                <span className={styles.sub_header}>
                    <FormattedMessage id="Proceq.ExportFieldStatistics" />
                </span>
            }
            dataSource={sectionData}
        />
    );
};

export default AdvancedStatistics;
