import React, { useCallback, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './SnapshotsViewer.styl';
import Spinner from '../../../../shared/Spinner';
import { classNames } from '../../../../../utils/styleUtils';
import { MeasurementFullData } from '../../../../../types/measurement';
import PropertiesTable from '../PropertiesTable';
import FormattedMessage from '../../../../../localization/FormatMessage';
import SnapshotInformation from './SnapshotInformation';
import imagePlaceholderSrc from '../../../../../images/imagePlaceholder.svg';
import useMeasurementImages, { MeasurementImageType } from '../../../../../hooks/useMeasurementImages';
import ImageSlider from '../../../../shared/ImageSlider';
import { ImageSliderAction, ImageSliderCategory } from '../../../../../analytics/analyticsConstants';
import { ProductCode } from '../../../../../types/proceq';
import analytics from '../../../../../analytics/firebaseAnalytics';

interface SnapshotsViewerProps {
    measurement?: MeasurementFullData;
    product: ProductCode;
}

const SnapshotsViewer: React.FunctionComponent<SnapshotsViewerProps> = (props) => {
    const { measurement, product } = props;

    const { onLazyLoad, entryIDs, entries, openedID, setOpenedID } = useMeasurementImages({
        attachments: measurement?.attachments,
        logs: measurement?.logs,
        measurementImageType: MeasurementImageType.snapshots,
    });

    const currentSnapshotURL = entries[openedID]?.imageURL;

    const tempPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

    const handlePointerDown = useCallback((event: React.PointerEvent) => {
        tempPos.current = { x: event.clientX, y: event.clientY };
    }, []);

    const handlePointerUp = useCallback(
        (event: React.PointerEvent, aID: string) => {
            const xMovement = event.clientX - tempPos.current.x;
            const yMovement = event.clientY - tempPos.current.y;
            const tolerance = 10;
            if (xMovement ** 2 + yMovement ** 2 < tolerance ** 2) {
                setOpenedID(aID);
                analytics.logImageSliderAction({
                    product,
                    category: ImageSliderCategory.snapshots,
                    action: ImageSliderAction.clickImage,
                });
            }
        },
        [product, setOpenedID]
    );

    if (!entryIDs.length) {
        return null;
    }

    return (
        <>
            <PropertiesTable title={<FormattedMessage id="Proceq.DashboardSnapshots" />} />
            <div className={styles.slider_wrapper}>
                <div className={styles.current_snapshot}>
                    {currentSnapshotURL === undefined ? (
                        <Spinner size="default" />
                    ) : (
                        <>
                            <img src={currentSnapshotURL || imagePlaceholderSrc} alt="" />
                            <SnapshotInformation
                                entry={entries[openedID]}
                                product={measurement?.productFamily}
                                allowDownload={!!currentSnapshotURL}
                            />
                        </>
                    )}
                </div>
                <ImageSlider
                    slidesToShow={4}
                    slidesToScroll={4}
                    onLazyLoad={onLazyLoad}
                    product={product}
                    analyticsCategory={ImageSliderCategory.snapshots}
                >
                    {entryIDs.map((aID) => {
                        const imageURL = entries[aID]?.imageURL;
                        const isLoading = imageURL === undefined;
                        return (
                            <div
                                className={classNames(styles.slide, openedID === aID && styles.active_slide)}
                                key={aID}
                                style={{ maxWidth: 'calc(100% / 4)' }}
                                onPointerDown={handlePointerDown}
                                onPointerUp={(event) => handlePointerUp(event, aID)}
                            >
                                <div className={styles.image_wrapper}>
                                    {isLoading ? (
                                        <Spinner size="default" />
                                    ) : (
                                        <img src={imageURL || imagePlaceholderSrc} alt="" />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </ImageSlider>
            </div>
        </>
    );
};

export default SnapshotsViewer;
