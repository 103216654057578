import { ScanType } from '../../../types/proceq';
import LineScanDoc from './Line Scan.json';
import AreaScanDoc from './Area Scan.json';
import StripeScanDoc from './Stripe Scan.json';
import IndicationDoc from './Indication.json';
import AScanDoc from './AScan.json';
import SpotScanDoc from './Spot Scan.json';
import GMFreePathScanDoc from './GMFreePath.json';
import BScanDoc from './BScan.json';
import ProfometerCorrosionDoc from './ProfometerCorrosion.json';
import ProfometerStatisticsDoc from './ProfometerStatistics.json';

export const getUnitDocumentation = (scanType: ScanType) => {
    switch (scanType) {
        case ScanType.SuperlineScan:
            return LineScanDoc;
        case ScanType.LineScan:
            return { ...LineScanDoc, ...ProfometerStatisticsDoc };
        case ScanType.Advanced:
        case ScanType.Basic:
            return { ...AreaScanDoc, ...ProfometerCorrosionDoc, ...ProfometerStatisticsDoc };
        case ScanType.FreePath:
            return { ...AreaScanDoc, ...GMFreePathScanDoc };
        case ScanType.MultiLineScan:
        case ScanType.AreaScan:
            return { ...AreaScanDoc, ...GMFreePathScanDoc, ...ProfometerStatisticsDoc };
        case ScanType.GridScan:
        case ScanType.StripeScan:
            return StripeScanDoc;
        case ScanType.Conventional:
        case ScanType.CorrosionMap:
        case ScanType.Indication:
            return IndicationDoc;
        case ScanType.ImpactEchoGrid:
        case ScanType.ImpactEchoSpot:
        case ScanType.AScan:
            return AScanDoc;
        case ScanType.SpotScan:
            return { ...SpotScanDoc, ...ProfometerStatisticsDoc };
        case ScanType.BScan:
            return BScanDoc;
        default:
            return {};
    }
};
