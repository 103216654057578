import { ProductCode, ScanType } from '../types/proceq';
import { FormatIDs } from '../types';
import { intlProxy } from '../localization/IntlProxy';

export const PRODUCTS_WITH_NO_MODES = new Set([ProductCode.EQUOTIP, ProductCode.SCHMIDT, ProductCode.GLM]);

const MODE_TEXT: Record<ScanType, FormatIDs> = {
    [ScanType.LineScan]: 'App.Mode.LineScan',
    [ScanType.AreaScan]: 'App.Mode.AreaScan',
    [ScanType.StripeScan]: 'App.Mode.Full3DMatrix',
    [ScanType.Indication]: 'App.Mode.Indication',
    [ScanType.AScan]: 'App.Mode.AScan',
    [ScanType.FreePath]: 'App.Mode.FreePath',
    [ScanType.GridScan]: 'App.Mode.GridScan',
    [ScanType.ImpactEchoSpot]: 'App.Mode.ImpactEchoSpot',
    [ScanType.ImpactEchoGrid]: 'App.Mode.ImpactEchoGrid',
    [ScanType.SpotScan]: 'App.Mode.SpotScan',
    [ScanType.BScan]: 'App.Mode.BScan',
    [ScanType.Conventional]: 'App.Mode.A-Scan', // shown as ascan for fdl
    [ScanType.CorrosionMap]: 'App.Mode.Grid', // shown as grid for fdl
    [ScanType.Advanced]: 'App.Mode.Advanced',
    [ScanType.Basic]: 'App.Mode.Basic',
    [ScanType.SuperlineScan]: 'App.Mode.SuperlineScan',
    [ScanType.MultiLineScan]: 'App.Mode.MultiLineScan',
};

export const getPunditImpactMode = (type: ScanType, values?: Record<string, any>) => {
    const formatMessage = intlProxy.formatMessage;
    switch (type) {
        case ScanType.AScan:
            return formatMessage({ id: 'App.HTML.PI8000.Mode.AScan' }, values) ?? '-';
        case ScanType.ImpactEchoSpot:
            return formatMessage({ id: 'App.HTML.PI8000.Mode.ImpactEchoSpot' }) ?? '-';
        case ScanType.ImpactEchoGrid:
            return formatMessage({ id: 'App.HTML.PI8000.Mode.ImpactEchoGrid' }) ?? '-';
        default:
            return '-';
    }
};

export const getMeasurementMode = (scanType: string, product?: ProductCode, values?: Record<string, any>) => {
    if (Object.values(ScanType).includes(scanType as ScanType)) {
        if (product === ProductCode.PIT_IE) return getPunditImpactMode(scanType as ScanType, values);
        const formatMessage = intlProxy.formatMessage;
        return formatMessage({ id: MODE_TEXT[scanType as ScanType] });
    }
    return scanType;
};
