import React from 'react';
import { classNames } from '../../../utils/styleUtils';
import styles from './DeleteButton.styl';
import Tooltip from '../Tooltip';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import FormattedMessage from '../../../localization/FormatMessage';
import { ReactComponent as IconTrash } from '../../../images/iconTrash.svg';

interface DeleteButtonProps {
    onClick: (e: React.MouseEvent) => void;
    isTableButton?: boolean; // true if button is within the table
    isPermanentDelete?: boolean;
    primaryStyle?: boolean;
}

const DeleteButton: React.FunctionComponent<DeleteButtonProps> = (props) => {
    const { onClick, isTableButton, isPermanentDelete, primaryStyle } = props;
    const text = <FormattedMessage id={isPermanentDelete ? 'DataView.Measurement.Delete.Permanently' : 'App.Delete'} />;
    return (
        <AnalyticsButton
            onClick={onClick}
            className={classNames(styles.button, isTableButton ? styles.text_button : '')}
            type={isTableButton ? 'link' : isPermanentDelete && primaryStyle ? 'primary' : 'default'}
            danger={isTableButton}
        >
            <Tooltip title={isTableButton ? text : undefined}>
                <div className={isTableButton ? styles.delete_icon_only : styles.delete_icon}>
                    <IconTrash />
                </div>
            </Tooltip>
            {!isTableButton && text}
        </AnalyticsButton>
    );
};

export default DeleteButton;
