import { get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { getMeasurementFullData } from '../../../../api/measurementService';
import { useMeasurements } from '../../../../hooks/useProductData';
import { ProductCode } from '../../../../types/proceq';
import '../shared/Reading.global.styl';
import Spinner from '../../../shared/Spinner';
import GPRReadings from './GPRReadings';
import EquotipReadings from './EquotipReadings';
import PunditReadings from './PunditReadings';
import GPRSoilReadings from './GPRSoilReadings';
import SnapshotReadings from './SnapshotReadings';
import GLMReadings from './GLMReadings';
import FDLReading from './FDLReadings';
import ProfometerReadings from './ProfometerReadings';
import { TimestampType, useMeasurementReloadLimiter } from '../../../../hooks/useLimiter';
import { BluetoothFileType } from '../../../../types/measurement';
import PunditImpactReadings from './PunditImpactReadings';
import { useMeasurementViewerContext } from '../../../StandaloneMeasurementView/MeasurementViewerContext';
import { useMeasurementContext } from '../../MeasurementContext/MeasurementProvider';

interface ReadingsProps {
    product?: ProductCode;
    measurementID: string;
}

const Readings: React.FunctionComponent<ReadingsProps> = (props) => {
    const { product, measurementID } = props;

    const { isStandalone, isHTMLExport, isHTMLView } = useMeasurementViewerContext();

    const { showHaze } = useMeasurementContext();

    const measurements = useMeasurements();
    const data = measurements?.[measurementID];
    const [isLoading, setIsLoading] = useState(false);
    const isVerificationMode = get(data, 'measurement.type') === BluetoothFileType.VerificationData;

    const fetchMeasurementData = useCallback(async () => {
        if (product && !isHTMLExport && !isHTMLView) {
            setIsLoading(true);
            try {
                await getMeasurementFullData({ measurementID });
            } finally {
                setIsLoading(false);
            }
        }
    }, [measurementID, product, isHTMLExport, isHTMLView]);

    useMeasurementReloadLimiter({
        measurementID,
        timestampType: TimestampType.Full,
        callback: fetchMeasurementData,
    });

    const reading = useMemo(() => {
        if (isLoading) {
            return (
                <div style={{ padding: 24 }}>
                    <Spinner size="default" />
                </div>
            );
        }
        if (!isStandalone) {
            switch (product) {
                case ProductCode.GPR:
                case ProductCode.GPR_SOIL:
                case ProductCode.GPR_MOUNTED:
                case ProductCode.PUNDIT:
                case ProductCode.FDL:
                case ProductCode.PROFOMETER:
                    return <SnapshotReadings product={product} data={data} />;
            }
        }
        switch (product) {
            case ProductCode.GPR:
                return <GPRReadings product={product} data={data} isStandalone />;
            case ProductCode.GPR_MOUNTED:
            case ProductCode.GPR_SOIL:
                return <GPRSoilReadings product={product} data={data} isStandalone />;
            case ProductCode.PUNDIT:
                return <PunditReadings product={product} data={data} isStandalone />;
            case ProductCode.FDL:
                return <FDLReading product={product} data={data} isStandalone={isStandalone} />;
            case ProductCode.EQUOTIP:
            case ProductCode.SCHMIDT:
                return (
                    <EquotipReadings
                        product={product}
                        data={data}
                        isStandalone={isStandalone}
                        isVerificationMode={isVerificationMode}
                    />
                );
            case ProductCode.GLM:
                return <GLMReadings product={product} data={data} showHaze={showHaze} isStandalone={isStandalone} />;
            case ProductCode.PIT_IE:
                return <PunditImpactReadings product={product} data={data} isStandalone />;
            case ProductCode.PROFOMETER:
                return <ProfometerReadings product={product} data={data} isStandalone={isStandalone} />;

            default:
                return null;
        }
    }, [isLoading, isStandalone, product, data, isVerificationMode, showHaze]);

    return reading;
};

export default Readings;
