import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import MeasurementPresets, { title as titleMeasurementPresets } from './shared/MeasurementPresets';
import ImageProcessing, { title as titleImageProcessing } from './Profometer/ImageProcessing';
import { DerivedProps } from '../SharedTypes';
import { convertAndDisplay } from '../utils/conversionRates';
import { GPRFamilyReadingsProps, ReadingContainer, ReadingPanel } from './ReadingComponents';
import { ProductModel, ScanType } from '../../../../types/proceq';
import User, { title as UserTitle } from '../Common/User';
import Markers, { title as MarkersTitle } from './shared/Markers';
import { PROFOMETER_METRIC_UNITS } from '../../../../types/measurement';
import Snapshots from './Profometer/Snapshots';
import SupportingImages from './Profometer/SupportingImages';
import MultipleColumnsWrapper from './shared/MultipleColumnsWrapper';
import FormattedMessage from '../../../../localization/FormatMessage';
import SpotScanStatistics from './Profometer/Statistics/SpotScanStatistics';
import LineAreaScanStatistics from './Profometer/Statistics/LineAreaScanStatistics';
import { CORROSION_SCAN_TYPES } from '../../../../types/profometer';
import AdvancedStatistics from './Profometer/Statistics/AdvancedStatistics';
import { ProductFeature, useProductContext } from '../../ProductContextProvider';

// EC-1916 temporary measure till it is supported in app
const SCAN_TYPES_WITH_NO_FULL_STATS = new Set([ScanType.MultiLineScan, ScanType.AreaScan]);

const ProfometerReadings: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data, isStandalone } = props;
    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const scanType = get(data, 'measurement.type');
    const isMetric = PROFOMETER_METRIC_UNITS.has(data?.settings[0].content.preset.unit);

    const { isFeatureEnabled } = useProductContext();
    const isProfoStatsEnabled = isFeatureEnabled(ProductFeature.PROFOMETER_STATISTICS);

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    if (!data || !scanType) {
        return null;
    }

    const derivedProps: DerivedProps & { productModel: ProductModel } = {
        product,
        productModel,
        scanType,
        isMetric,
        convert: convertor,
        showTitle: isStandalone,
    };

    return (
        <ReadingContainer>
            <div id="newHTMLFormat">
                <Snapshots data={data} {...derivedProps} />
                {CORROSION_SCAN_TYPES.has(scanType) ? (
                    scanType === ScanType.Advanced &&
                    (data.measurement?.content?.statistics?.noOfReadings ?? 0) >= 20 ? (
                        <MultipleColumnsWrapper>
                            <div>
                                <ReadingPanel key="user" header={UserTitle}>
                                    <User data={data} {...derivedProps} />
                                </ReadingPanel>
                                <ReadingPanel
                                    key="statistics"
                                    header={<FormattedMessage id="Proceq.ExportFieldStatistics" />}
                                >
                                    <AdvancedStatistics data={data} {...derivedProps} />
                                </ReadingPanel>
                            </div>
                            <div>
                                <ReadingPanel key="measurementPresets" header={titleMeasurementPresets}>
                                    <MeasurementPresets data={data} {...derivedProps} />
                                </ReadingPanel>
                                <ReadingPanel key="measurements" header={titleImageProcessing}>
                                    <ImageProcessing data={data} {...derivedProps} />
                                </ReadingPanel>
                            </div>
                        </MultipleColumnsWrapper>
                    ) : (
                        <MultipleColumnsWrapper>
                            <div>
                                <ReadingPanel key="user" header={UserTitle}>
                                    <User data={data} {...derivedProps} />
                                </ReadingPanel>
                                <ReadingPanel key="measurements" header={titleImageProcessing}>
                                    <ImageProcessing data={data} {...derivedProps} />
                                </ReadingPanel>
                            </div>
                            <ReadingPanel key="measurementPresets" header={titleMeasurementPresets}>
                                <MeasurementPresets data={data} {...derivedProps} />
                            </ReadingPanel>
                        </MultipleColumnsWrapper>
                    )
                ) : scanType === ScanType.SpotScan ? (
                    <MultipleColumnsWrapper>
                        <div>
                            <ReadingPanel key="user" header={UserTitle}>
                                <User data={data} {...derivedProps} />
                            </ReadingPanel>
                            <ReadingPanel key="measurementPresets" header={titleMeasurementPresets}>
                                <MeasurementPresets data={data} {...derivedProps} />
                            </ReadingPanel>
                        </div>
                        <SpotScanStatistics data={data} {...derivedProps} />
                    </MultipleColumnsWrapper>
                ) : !SCAN_TYPES_WITH_NO_FULL_STATS.has(scanType) || isProfoStatsEnabled ? (
                    <MultipleColumnsWrapper>
                        <div>
                            <ReadingPanel key="user" header={UserTitle}>
                                <User data={data} {...derivedProps} />
                            </ReadingPanel>
                            <ReadingPanel key="measurementPresets" header={titleMeasurementPresets}>
                                <MeasurementPresets data={data} {...derivedProps} />
                            </ReadingPanel>
                            <ReadingPanel key="measurements" header={titleImageProcessing}>
                                <ImageProcessing data={data} {...derivedProps} />
                            </ReadingPanel>
                        </div>
                        <LineAreaScanStatistics data={data} {...derivedProps} />
                    </MultipleColumnsWrapper>
                ) : (
                    <MultipleColumnsWrapper>
                        <div>
                            <ReadingPanel key="user" header={UserTitle}>
                                <User data={data} {...derivedProps} />
                            </ReadingPanel>
                            <ReadingPanel key="measurements" header={titleImageProcessing}>
                                <ImageProcessing data={data} {...derivedProps} />
                            </ReadingPanel>
                        </div>
                        <ReadingPanel key="measurementPresets" header={titleMeasurementPresets}>
                            <MeasurementPresets data={data} {...derivedProps} />
                        </ReadingPanel>
                    </MultipleColumnsWrapper>
                )}
                {scanType !== ScanType.SpotScan && data.objects?.some((obj) => obj.type === 'marker') && (
                    <ReadingPanel key="markers" header={MarkersTitle}>
                        <Markers data={data} {...derivedProps} />
                    </ReadingPanel>
                )}
                <SupportingImages data={data} {...derivedProps} />
            </div>
        </ReadingContainer>
    );
};

export default ProfometerReadings;
