import { LanguageOptions } from '../localization';

interface SessionStorageItems {
    eagleIDState: string;
    codeVerifier: string;
    product: string;
    client: string;
    version: string;
    landingPathname: string;
    landingQuery: string;
    token: string;
    insightsReloadCount: string;
}

interface LocalStorageItems {
    language?: LanguageOptions;
    readyUsers?: string;
    devFeatureConfig?: string;
    'onesignal-notification-prompt': string;
}

export function getSessionItem(key: keyof SessionStorageItems): string | null {
    return sessionStorage.getItem(key);
}

export function saveSessionItem(key: keyof SessionStorageItems, value: string) {
    sessionStorage.setItem(key, value);
}

export function removeSessionItem(key: keyof SessionStorageItems) {
    sessionStorage.removeItem(key);
}

export function getLocalItem(key: keyof LocalStorageItems): string | null {
    return localStorage.getItem(key);
}

export function saveLocalItem(key: keyof LocalStorageItems, value: string) {
    localStorage.setItem(key, value);
}

export function removeLocalItem(key: keyof LocalStorageItems) {
    localStorage.removeItem(key);
}

export const updateReadyUsers = (userID: string | undefined) => {
    if (!userID) return;
    const localStorageReadyUsers = getLocalItem('readyUsers') ?? '';
    if (!localStorageReadyUsers) {
        saveLocalItem('readyUsers', userID);
    } else {
        const userIDs = localStorageReadyUsers.split(',');
        if (!userIDs.includes(userID)) {
            userIDs.push(userID);
        }
        saveLocalItem('readyUsers', userIDs.join(','));
    }
};
