import { UserRole } from '../types/proceq';

export const expandRoles = (roleString: string): Set<UserRole> => {
    const roleArray = roleString.split(',').filter(Boolean) as UserRole[];

    const rolesSet = new Set(roleArray);

    if (rolesSet.has(UserRole.Super)) {
        rolesSet.add(UserRole.Admin);
    }
    if (rolesSet.has(UserRole.Admin)) {
        rolesSet.add(UserRole.PO);
        rolesSet.add(UserRole.Dev);
    }
    if (rolesSet.has(UserRole.PO)) {
        rolesSet.add(UserRole.BUAdmin);
    }
    if (rolesSet.has(UserRole.BUAdmin)) {
        rolesSet.add(UserRole.BU);
    }
    if (!rolesSet.has(UserRole.User)) {
        rolesSet.add(UserRole.User);
    }

    return rolesSet;
};

export const convertRolesSetToState = (rolesSet: Set<UserRole>) => {
    return {
        isSuper: rolesSet.has(UserRole.Super),
        isAdmin: rolesSet.has(UserRole.Admin),
        isPO: rolesSet.has(UserRole.PO),
        isBUAdmin: rolesSet.has(UserRole.BUAdmin),
        isBU: rolesSet.has(UserRole.BU),
        isDev: rolesSet.has(UserRole.Dev),
        isUser: rolesSet.has(UserRole.User),
        isAnalyst: rolesSet.has(UserRole.Analyst),
        isInternalUser: rolesSet.size > 1,
    };
};
