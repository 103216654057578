import { noop } from 'lodash';
import React, { createContext } from 'react';
import { DataViewMode, FilterConfig, NonEditableMeasurements, OrderConfig } from '../../../types/measurement';

export interface ViewModeConfig {
    viewMode?: DataViewMode;
    measurementID?: string;
    customMaterialID?: string;
}

export interface MeasurementContextState {
    fetchMeasurementList: (changePage?: boolean) => Promise<void>;
    fetchCustomMaterialList: (changePage?: boolean) => Promise<void>;
    isFetching: boolean;

    modalViewerConfig: ViewModeConfig;
    setModalViewerConfig: (config: ViewModeConfig) => void;
    openMeasurementInNewTab: (id: string) => void;

    resetConfig: () => void;

    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;

    orderConfig: OrderConfig | undefined;
    setOrderConfig: (config: OrderConfig | undefined) => void;

    filterConfig: { [key: string]: FilterConfig };
    setFilterConfig: React.Dispatch<React.SetStateAction<{ [key: string]: FilterConfig }>>;

    searchText: string;
    setSearchText: (value: string) => void;

    productModel: string | undefined;
    setProductModel: (value: string | undefined) => void;

    probeTypeId: number | undefined;
    setProbeTypeId: (value: number | undefined) => void;

    showHaze: boolean;
    setShowHaze: (value: ((prevState: boolean) => boolean) | boolean) => void;

    nonEditableMeasurements: undefined | NonEditableMeasurements;
    setNonEditableMeasurements: (value: undefined | NonEditableMeasurements) => void;

    selectedKeys: string[];
    setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
}

export const MeasurementContext = createContext<MeasurementContextState>({
    fetchMeasurementList: async () => {},
    fetchCustomMaterialList: async () => {},
    isFetching: false,

    modalViewerConfig: {},
    setModalViewerConfig: noop,
    openMeasurementInNewTab: noop,

    page: 1,
    setPage: noop,
    pageSize: 20,
    setPageSize: noop,

    resetConfig: noop,

    orderConfig: undefined,
    setOrderConfig: noop,

    filterConfig: {},
    setFilterConfig: noop,

    searchText: '',
    setSearchText: noop,

    productModel: '',
    setProductModel: noop,

    probeTypeId: undefined,
    setProbeTypeId: noop,

    showHaze: true,
    setShowHaze: noop,

    nonEditableMeasurements: undefined,
    setNonEditableMeasurements: noop,

    selectedKeys: [],
    setSelectedKeys: noop,
});
