import React from 'react';
import { MeasurementFullData } from '../../../../../../types/measurement';
import { DerivedProps } from '../../../SharedTypes';
import FormattedMessage from '../../../../../../localization/FormatMessage';
import styles from '../../../DataViewers.styl';
import { ReadingPanel } from '../../ReadingComponents';
import SectionTable from '../../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../../utils/tableDataUtils';
import { getImperialDiameter, getStatisticsValue, getValMillimetersToMeters } from './statisticsUtils';
import { ScanType } from '../../../../../../types/proceq';

const getCoverSectionData = (statistics: any, isMetric: boolean, scanType: ScanType) => {
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.Profometer.Statistics.NumberOfReadings',
            value: statistics?.coverNoOfReadings,
        },
        {
            label: 'App.HTML.Profometer.Statistics.Mean',
            value: getValMillimetersToMeters(statistics?.coverMean),
            unitId: 'Profometer.Statistics.CoverMean',
        },
        {
            label: 'App.HTML.Profometer.Statistics.Median',
            value: getValMillimetersToMeters(statistics?.coverMedian),
            unitId: 'Profometer.Statistics.CoverMean',
        },
        {
            label: 'App.HTML.Profometer.Statistics.StDev',
            value: getValMillimetersToMeters(statistics?.coverStdDeviation),
            unitId: 'Profometer.Statistics.CoverStdDev',
        },
        {
            label: 'App.HTML.Profometer.Statistics.Min',
            value: getValMillimetersToMeters(statistics?.coverMin),
            unitId: 'Profometer.Statistics.MinMax',
        },
        {
            label: 'App.HTML.Profometer.Statistics.Max',
            value: getValMillimetersToMeters(statistics?.coverMax),
            unitId: 'Profometer.Statistics.MinMax',
        },
        {
            label: 'App.HTML.Profometer.Statistics.F(cmin)',
            value: getStatisticsValue({ roundToDigit: 2, value: statistics?.fCmin }),
        },
        {
            label: 'App.HTML.Profometer.Statistics.c5',
            value: getStatisticsValue({ roundToDigit: 2, value: statistics?.c5 }),
        },
        {
            label: 'App.HTML.Profometer.Statistics.c10',
            value: getStatisticsValue({ roundToDigit: 2, value: statistics?.c10 }),
        },
    ];
    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};

const getRebarSpacingSectionData = (statistics: any, isMetric: boolean, scanType: ScanType) => {
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.Profometer.Statistics.NumberOfReadings',
            value: statistics?.spacingNoOfReadings,
        },
        {
            label: 'App.HTML.Profometer.Statistics.Mean',
            value: statistics?.spacingMean,
            unitId: 'Profometer.Statistics.RebarSpacingMean',
        },
        {
            label: 'App.HTML.Profometer.Statistics.Median',
            value: statistics?.spacingMedian,
            unitId: 'Profometer.Statistics.RebarSpacingMean',
        },
        {
            label: 'App.HTML.Profometer.Statistics.StDev',
            value: statistics?.spacingStdDeviation,
            unitId: 'Profometer.Statistics.RebarSpacingMean',
        },
        {
            label: 'App.HTML.Profometer.Statistics.Min',
            value: statistics?.spacingMin,
            unitId: 'Profometer.Statistics.RebarSpacingMinMax',
        },
        {
            label: 'App.HTML.Profometer.Statistics.Max',
            value: statistics?.spacingMax,
            unitId: 'Profometer.Statistics.RebarSpacingMinMax',
        },
    ];
    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};

const getRebarDiameterSectionData = (statistics: any, isMetric: boolean, scanType: ScanType) => {
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.Profometer.Statistics.NumberOfReadings',
            value: statistics?.diameterNoOfDiameter,
        },
        {
            label: 'App.HTML.Profometer.Statistics.RebarDiameterMin',
            value: isMetric
                ? getValMillimetersToMeters(statistics?.diameterMin)
                : getImperialDiameter(statistics?.diameterMin),
            unitId: isMetric ? 'Profometer.Statistics.MinMax' : undefined,
        },
        {
            label: 'App.HTML.Profometer.Statistics.RebarDiameterMax',
            value: isMetric
                ? getValMillimetersToMeters(statistics?.diameterMax)
                : getImperialDiameter(statistics?.diameterMax),
            unitId: isMetric ? 'Profometer.Statistics.MinMax' : undefined,
        },
    ];
    return getConvertedSectionTableData(presetsRaw, scanType, isMetric);
};

const LineAreaScanStatistics: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, showTitle, isMetric, scanType } = props;
    const statistics = data.measurement?.content?.lineScanContent?.lineStatistics?.profometerStatistics;

    return (
        <div>
            <ReadingPanel
                key="StatisticsOfCover"
                header={<FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfCovers" />}
            >
                <SectionTable
                    showTitle={showTitle}
                    className={[styles.table, 'table-measurement-presets'].join(' ')}
                    title={
                        <span className={styles.sub_header}>
                            <FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfCovers" />
                        </span>
                    }
                    dataSource={getCoverSectionData(statistics, isMetric, scanType)}
                />
            </ReadingPanel>
            <ReadingPanel
                key="StatisticsOfRebarSpacing"
                header={<FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfRebarSpacing" />}
            >
                <SectionTable
                    showTitle={showTitle}
                    className={[styles.table, 'table-measurement-presets'].join(' ')}
                    title={
                        <span className={styles.sub_header}>
                            <FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfRebarSpacing" />
                        </span>
                    }
                    dataSource={getRebarSpacingSectionData(statistics, isMetric, scanType)}
                />
            </ReadingPanel>
            {(statistics?.diameterNoOfDiameter ?? 0) >= 1 && (
                <ReadingPanel
                    key="StatisticsOfRebarDiameter"
                    header={<FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfRebarDiameters" />}
                >
                    <SectionTable
                        showTitle={showTitle}
                        className={[styles.table, 'table-measurement-presets'].join(' ')}
                        title={
                            <span className={styles.sub_header}>
                                <FormattedMessage id="App.HTML.Profometer.Statistics.StatisticsOfRebarDiameters" />
                            </span>
                        }
                        dataSource={getRebarDiameterSectionData(statistics, isMetric, scanType)}
                    />
                </ReadingPanel>
            )}
        </div>
    );
};

export default LineAreaScanStatistics;
