import { objectUtils } from 'tds-common-fe';
import { proceqURL } from './RegionalAPI';
import config from '../config';
import { URLConfig } from './ApiURL';

const APP_URL = `${config.EAGLE_CLOUD_DOMAIN}/api` ?? '';
const EAGLE_ID_URL = config.EAGLE_ID_DOMAIN ?? '';
export const EAGLE_ID_API_URL = `${EAGLE_ID_URL}/api`;

const OAUTH = 'oauth';
const EAGLE_ID_OAUTH = 'eagleid/oauth';
const PROFILE = 'profile';
const USER = 'user';
const USER_FILE = 'user/file';
const MEASUREMENTS = 'measurements';
const FOLDERS = 'folders';
const FILES = 'files';
const LOGS = 'logs';
const ACCOUNT = 'account';
const ATTACHMENTS = 'attachments';
const SHARING = 'sharing';
const EXPORT = 'export';
const LONG_RUNNING_EXPORT = 'longrunningjob/export';
const META = 'meta';
const WORKSPACE = 'workspace';
const LICENSE = 'license';
const GENERIC = 'generic';
const CONTRACTS = 'contracts';
const SERVICE = 'service';
const NOTIFICATION = 'notification';
const IMPORT = 'import';
const EVENTS = 'events';
const APPSTREAM = 'streaming-session';
const CREDENTIALS = 'credentials';

export const ApiURLConfig = objectUtils.asTypedObject<URLConfig>()({
    // OAuth
    SET_EAGLE_ID_AUTH: { baseURL: EAGLE_ID_API_URL, domain: OAUTH, subDomain: 'verify' },
    CHECK_EAGLE_ID_AUTH: { baseURL: EAGLE_ID_API_URL, domain: OAUTH, subDomain: 'authorization' },
    EAGLE_ID_LOGOUT: { baseURL: EAGLE_ID_API_URL, domain: OAUTH, subDomain: 'logout' },
    MASQUERADE: { baseURL: EAGLE_ID_API_URL, domain: OAUTH, subDomain: 'masquerade' },

    // Eagle Workspace OAuth
    SET_EAGLE_CLOUD_AUTH: { baseURL: APP_URL, domain: EAGLE_ID_OAUTH, subDomain: 'verify' },
    GET_OAUTH_TOKEN: { baseURL: APP_URL, domain: EAGLE_ID_OAUTH, subDomain: 'token' },
    OAUTH_LOGOUT: { baseURL: APP_URL, domain: EAGLE_ID_OAUTH, subDomain: 'logout' },

    // APP APIs
    APPS_STATUS: { baseURL: APP_URL, domain: WORKSPACE, subDomain: 'app/active' },
    APPS_CMS_NEWS: { baseURL: APP_URL, domain: WORKSPACE, subDomain: 'cms/news' },

    // Notification APIs
    GET_NOTIFICATIONS: { baseURL: APP_URL, domain: NOTIFICATION },
    NOTIFICATION_MARK_AS_READ: { baseURL: APP_URL, domain: NOTIFICATION, subDomain: '{notificationID}/read' },
    NOTIFICATION_MARK_ALL_AS_READ: { baseURL: APP_URL, domain: NOTIFICATION, subDomain: 'read' },
    NOTIFICATION_CLEAR_ALL: { baseURL: APP_URL, domain: NOTIFICATION, subDomain: 'clear' },

    // Profile
    EAGLE_ID_PROFILE: { baseURL: EAGLE_ID_API_URL, domain: PROFILE, subDomain: '{userID}' },
    EAGLE_ID_PROFILE_PICTURE: { baseURL: EAGLE_ID_API_URL, domain: PROFILE, subDomain: '{userID}/picture' },
    PASSWORD_CHANGE: { baseURL: EAGLE_ID_API_URL, domain: USER, subDomain: 'password/change' },
    USER_LICENSE: { baseURL: EAGLE_ID_API_URL, domain: USER, subDomain: 'license' },
    LICENSE_TIER: { baseURL: EAGLE_ID_API_URL, domain: LICENSE, subDomain: 'tier' },
    USER_LICENSE_LIST: { baseURL: EAGLE_ID_API_URL, domain: USER, subDomain: 'license/list' },
    LICENSE_ACTIVATE_TRIAL: { baseURL: EAGLE_ID_API_URL, domain: USER, subDomain: 'license/trial/activate' },
    LICENSE_ACTIVATE: { baseURL: EAGLE_ID_API_URL, domain: USER, subDomain: 'license/activate' },
    GET_DELETE_ACCOUNT: { baseURL: EAGLE_ID_API_URL, domain: USER, subDomain: 'delete' },

    // Probe Data APIs
    GET_PROBES: { baseURL: proceqURL.getRegionAPI, domain: USER, subDomain: 'probe', version: '' },
    GET_PROBE_INFO: {
        baseURL: proceqURL.getRegionAPI,
        domain: USER,
        subDomain: 'probe/{mLogID}',
        version: '',
    },

    // User file APIs
    USER_FILE: { baseURL: proceqURL.getRegionAPI, domain: USER_FILE, version: '' },
    USER_FILE_NAME_EXISTS: { baseURL: proceqURL.getRegionAPI, domain: USER_FILE, subDomain: 'exists', version: '' },
    USER_FILE_EXTENSIONS: { baseURL: proceqURL.getRegionAPI, domain: USER_FILE, subDomain: 'category', version: '' },
    UPDATE_USER_FILE: { baseURL: proceqURL.getRegionAPI, domain: USER_FILE, subDomain: '{fileID}', version: '' },
    USER_FILE_OPTION: { baseURL: proceqURL.getRegionAPI, domain: USER_FILE, subDomain: 'option', version: '' },

    // Measurement Data APIs
    MEASUREMENT_FOLDERS: { baseURL: proceqURL.getRegionAPI, domain: FOLDERS, version: '' },
    RENAME_MEASUREMENT_FOLDER: {
        baseURL: proceqURL.getRegionAPI,
        domain: FOLDERS,
        subDomain: 'rename/{folderID}',
        version: '',
    },
    DELETE_ARCHIVED_MEASUREMENT_FOLDERS: {
        baseURL: proceqURL.getRegionAPI,
        domain: FOLDERS,
        subDomain: 'archive',
        version: '',
    },
    ARCHIVE_MEASUREMENT_FOLDER: {
        baseURL: proceqURL.getRegionAPI,
        domain: FOLDERS,
        subDomain: '{folderID}/archive',
        version: '',
    },
    MEASUREMENT_LIST: { baseURL: proceqURL.getRegionAPI, domain: MEASUREMENTS, subDomain: 'all', version: '' },
    MEASUREMENT: { baseURL: proceqURL.getRegionAPI, domain: MEASUREMENTS, subDomain: '{measurementID}', version: '' },
    RENAME_MEASUREMENT: {
        baseURL: proceqURL.getRegionAPI,
        domain: MEASUREMENTS,
        subDomain: 'rename/{measurementID}',
        version: '',
    },
    MOVE_MEASUREMENT: { baseURL: proceqURL.getRegionAPI, domain: MEASUREMENTS, subDomain: 'move', version: '' },
    CREATE_MEASUREMENT: { baseURL: proceqURL.getRegionAPI, domain: MEASUREMENTS, version: '' },
    PERMANENT_DELETE_MEASUREMENTS: { baseURL: proceqURL.getRegionAPI, domain: MEASUREMENTS, version: '' },
    DELETE_RESTORE_MEASUREMENTS: {
        baseURL: proceqURL.getRegionAPI,
        domain: MEASUREMENTS,
        subDomain: 'trash/{isDelete}',
        version: '',
    },
    ARCHIVE_MEASUREMENTS: { baseURL: proceqURL.getRegionAPI, domain: MEASUREMENTS, subDomain: 'archive', version: '' },
    FLAG_MEASUREMENT: {
        baseURL: proceqURL.getRegionAPI,
        domain: MEASUREMENTS,
        subDomain: 'flag/{measurementID}/{flagStatus}',
        version: '',
    },
    MEASUREMENT_LOGS: { baseURL: proceqURL.getRegionAPI, domain: LOGS, version: '' },
    MEASUREMENT_ATTACHMENTS: { baseURL: proceqURL.getRegionAPI, domain: ATTACHMENTS, version: '' },
    MEASUREMENT_NAME_EXISTS: {
        baseURL: proceqURL.getRegionAPI,
        domain: MEASUREMENTS,
        subDomain: 'exists',
        version: '',
    },

    // DGS Custom Curve APIs
    DGS_SMOOTHEN: {
        baseURL: proceqURL.getRegionAPI,
        domain: MEASUREMENTS,
        subDomain: 'dgs/curve/smoothen/',
        version: '',
    },

    // Appstream APIs
    APPSTREAM_DETAILS: { baseURL: '{baseURL}', domain: APPSTREAM, version: '' },
    REFRESH_WEBSOCKET_TOKEN: { baseURL: '{baseURL}', domain: CREDENTIALS, version: '' },
    APPSTREAM_SESSION_FINISHED: { baseURL: '{baseURL}', domain: 'session-finished', version: '' },

    // Custom Material APIs
    CUSTOM_MATERIAL_LIST: { baseURL: proceqURL.getRegionAPI, domain: GENERIC, subDomain: 'all', version: '' },
    CUSTOM_MATERIAL: { baseURL: proceqURL.getRegionAPI, domain: GENERIC, subDomain: '{id}', version: '' },
    RENAME_CUSTOM_MATERIAL: { baseURL: proceqURL.getRegionAPI, domain: GENERIC, subDomain: 'rename/{id}', version: '' },
    DELETE_CUSTOM_MATERIAL: { baseURL: proceqURL.getRegionAPI, domain: GENERIC, version: '' },
    FLAG_CUSTOM_MATERIAL: {
        baseURL: proceqURL.getRegionAPI,
        domain: GENERIC,
        subDomain: 'flag/{id}/{flagStatus}',
        version: '',
    },

    // Import Measurement APIS
    IMPORT_MEASUREMENTS: { baseURL: proceqURL.getRegionAPI, domain: IMPORT, subDomain: 'async', version: '' },
    GET_IMPORT_MEASUREMENTS_STATUS: {
        baseURL: proceqURL.getRegionAPI,
        domain: IMPORT,
        subDomain: 'async/status',
        version: '',
    },

    // Utils APIs
    GET_PROCEQ_APP_CONFIG: { baseURL: proceqURL.getManagementAPI, domain: META, subDomain: 'appconfigs', version: '' },
    GET_FILE: { baseURL: proceqURL.getRegionAPI, domain: FILES, subDomain: 'download', version: '' },
    UPLOAD_FILE: { baseURL: proceqURL.getRegionAPI, domain: FILES, subDomain: 'upload', version: '' },
    GET_SHARING_URL: { baseURL: proceqURL.getRegionAPI, domain: SHARING, subDomain: 'v3', version: '' },
    GET_SHARED_MEASUREMENTS: { baseURL: proceqURL.getRegionAPI, domain: SHARING, subDomain: 'v2/{key}', version: '' },
    EXPORT_REPORTS: { baseURL: proceqURL.getRegionAPI, domain: EXPORT, version: '' },
    EXPORT_REPORT_AS_URL: {
        baseURL: proceqURL.getRegionAPI,
        domain: LONG_RUNNING_EXPORT,
        subDomain: 'init',
        version: '',
    },
    CONTRACTS: {
        baseURL: proceqURL.getManagementAPI,
        domain: CONTRACTS,
        subDomain: 'bulk',
        version: '',
    },
    APPLE_MAP_TOKEN: {
        baseURL: EAGLE_ID_API_URL,
        domain: SERVICE,
        subDomain: 'apple/mapkit/token',
    },
    APPLE_MAP_TOKEN_PQ: {
        baseURL: EAGLE_ID_API_URL,
        domain: SERVICE,
        subDomain: 'apple/mapkit/token/pq',
    },
    BACKEND_HEALTH: {
        baseURL: EAGLE_ID_API_URL,
        domain: SERVICE,
        subDomain: 'healthz/',
    },

    // Management APIs
    GET_ACCOUNT: { baseURL: proceqURL.getManagementAPI, domain: ACCOUNT, version: '' },
    GET_ALL_ACCOUNTS: { baseURL: proceqURL.getManagementAPI, domain: ACCOUNT, subDomain: 'all', version: '' },
    GET_SHARED_ACCOUNTS: { baseURL: proceqURL.getManagementAPI, domain: ACCOUNT, subDomain: 'profiles', version: '' },

    // Analytics event API
    LOG_EVENT: { baseURL: proceqURL.getManagementAPI(), domain: EVENTS, version: '' },
});
