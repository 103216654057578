import React, { useCallback, useRef } from 'react';
import { Spin } from 'antd';

import { NotificationRecord } from '../../types/notification';
import NotificationHeader from './NotificationHeader';
import NotificationItem from './NotificationItem';
import useAtScrollBottom from '../../hooks/useAtScrollBottom';
import { SyncMeta } from '../../types/api';
import styles from './Notification.styl';
import { useNotifications } from '../../queries/notificationQueries';

interface NotificationListProps {
    groupedNotifications: { [key: string]: NotificationRecord[] };
    dates: string[];
    unreadCount: number;
    meta?: SyncMeta;
}

const NotificationList: React.FunctionComponent<NotificationListProps> = (props) => {
    const { groupedNotifications, dates, unreadCount, meta } = props;

    const listContainer = useRef<HTMLDivElement | null>(null);

    const notificationIDX = meta?.idx;
    const hasMoreNotification = meta?.more ?? false;
    const needToFetchNotification = !!notificationIDX && hasMoreNotification;

    const { refetch, isFetching } = useNotifications({ sync: false, idx: notificationIDX }, false);

    const handleScrollBottom = useCallback(async () => {
        if (notificationIDX) {
            await refetch();
        }
    }, [notificationIDX, refetch]);

    useAtScrollBottom(listContainer, handleScrollBottom, needToFetchNotification);

    return (
        <div ref={listContainer} className={styles.notification_list}>
            {dates.map((date, index) => (
                <React.Fragment key={date}>
                    <NotificationHeader date={date} showAction={index === 0} unreadCount={unreadCount} />
                    {groupedNotifications[date].map((notification) => (
                        <NotificationItem key={notification.id} notification={notification} />
                    ))}
                </React.Fragment>
            ))}
            {isFetching && (
                <div className={styles.center} style={{ padding: 8 }}>
                    <Spin />
                </div>
            )}
        </div>
    );
};

export default NotificationList;
