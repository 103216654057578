import React from 'react';
import { Button } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styles from './Notification.styl';
import FormattedMessage from '../../localization/FormatMessage';
import * as notificationService from '../../api/notificationService';
import { ReactQueryKeys } from '../../queries/queryKeys';

interface NotificationHeaderProps {
    date: string;
    showAction: boolean;
    unreadCount: number;
}

const NotificationHeader: React.FunctionComponent<NotificationHeaderProps> = (props) => {
    const { date, showAction, unreadCount } = props;
    const isReadAll = unreadCount > 0;

    const queryClient = useQueryClient();
    const { mutate: readAllNotifications, isLoading: readAllLoading } = useMutation({
        mutationFn: () => notificationService.markAllAsRead(),
        // update all notifications instead of using current idx to avoid getting old notifications that were recently updated
        onSuccess: () =>
            queryClient.invalidateQueries({ queryKey: ReactQueryKeys.notificationsFilter({ sync: false, idx: 0 }) }),
    });
    const { mutate: clearAllNotifications, isLoading: clearAllLoading } = useMutation({
        mutationFn: () => notificationService.clearAllNotifications(),
    });

    const handleClick = () => {
        if (isReadAll) {
            readAllNotifications();
        } else {
            clearAllNotifications();
        }
    };

    return (
        <div className={styles.header}>
            {date}
            {showAction && (
                <Button type="link" size="small" onClick={handleClick} loading={readAllLoading || clearAllLoading}>
                    <FormattedMessage id={isReadAll ? 'Notification.ReadAll' : 'Notification.ClearAll'} />
                </Button>
            )}
        </div>
    );
};

export default NotificationHeader;
