import { ProductCode } from '../types/proceq';

export enum EventName {
    ButtonClick = 'button_click',
    ViewDiscovery = 'view_discovery',
    ViewAllProbes = 'view_all_probes',
    SidebarSwitchProduct = 'sidebar_switch_product',
    ClickHeaderLogo = 'click_header_logo',

    // profile related
    ViewProfileMenu = 'view_profile_menu',
    SwitchProfileMenu = 'switch_profile_menu',
    EditPersonalProfile = 'edit_personal_profile',
    ViewProbeDetails = 'view_probe_details',
    ViewSubscriptions = 'view_subscriptions',
    DeleteAccount = 'delete_account',
    EditSecurity = 'edit_security',

    // measurement related
    ViewMeasurement = 'view_measurement',
    DownloadSnapshot = 'download_snapshot',
    DownloadAttachment = 'download_attachment',
    ExportFile = 'export_file',
    SortTable = 'sort_table',
    ViewExportOptions = 'view_export_options',
    ExportMeasurement = 'export_measurement',
    ClickMeasurementMenu = 'click_measurement_menu',
    ViewLogBook = 'view_logbook',
    RefreshMeasurements = 'refresh_measurements',
    ShareLink = 'share_link',
    FileManagement = 'file_management',
    NonEditableMeasurement = 'non_editable_measurement',

    // full page measurement
    ShortlistImageForReport = 'shortlist_image_for_report',
    SnapshotModal = 'snapshot_modal',
    ImageSlider = 'image_slider',

    // folder related
    FolderManagement = 'folder_management',

    // insights related
    InsightsActions = 'insights_actions',

    // Custom curve upload
    UploadCustomCurve = 'upload_custom_curve',

    // spatial file upload
    uploadCadgisFile = 'upload_cadgis_file',

    // GM sync data
    syncMeasurement = 'sync_measurement',
    launchApp = 'launch_app',
}

export enum EventParameter {
    category = 'category',
    action = 'action',
    page = 'page',
    product = 'product',
}

export enum PersonalProfileMenu {
    personalProfile = 'personal_profile',
    passwordAndSecurity = 'password_and_security',
    probes = 'probes',
    mySubscriptions = 'my_subscriptions',
}

export enum PersonalProfileAction {
    edit = 'edit',
    done = 'done',
    changePassword = 'change_password',
    cancelChangePassword = 'cancel_change_password',
}

export enum MeasurementMenuCategory {
    more = 'more',
    attachments = 'attachments',
    json = 'json',
    newTab = 'new_tab',
    rename = 'rename',
    delete = 'delete',
    deletePermanently = 'delete_permanently',
    restore = 'restore',
    move = 'move',
    archive = 'archive',
    share = 'share',
}

export enum FolderManagementCategory {
    sidebarNewFolder = 'sidebar_new_folder',
    sidebarDelete = 'sidebar_delete',
    sidebarRename = 'sidebar_rename',
    sidebarRestore = 'sidebar_restore',
    sidebarDeletePermanently = 'sidebar_delete_permanently',
    createCancel = 'create_cancel',
    create = 'create',
    deleteCancel = 'delete_cancel',
    delete = 'delete',
    renameCancel = 'rename_cancel',
    rename = 'rename',
    sidebarArchive = 'sidebar_archive',
    archiveCancel = 'archive_cancel',
    archive = 'archive',
    archivedDeletePermanently = 'archived_delete_permanently',
    archivedDeletePermanentlyCancel = 'archived_delete_permanently_cancel',
}

export enum FileManagementCategory {
    createCancel = 'create_cancel',
    create = 'create',
    deleteBulk = 'delete_bulk',
    permanentDeleteBulk = 'permanent_delete_bulk',
    permanentDelete = 'permanent_delete',
    permanentDeleteCancel = 'permanent_delete_cancel',
    renameCancel = 'rename_cancel',
    rename = 'rename',
    moveCancel = 'move_cancel',
    move = 'move',
    moveAddNewFolder = 'move_add_new_folder',
    excludeAndMove = 'exclude_and_move',
    flag = 'flag',
    archiveBulk = 'archive_bulk',
    archiveCancel = 'archive_cancel',
    excludeAndArchive = 'exclude_and_archive',
    restoreBulk = 'restore_bulk',
    restoreCancel = 'restore_cancel',
    excludeAndRestore = 'exclude_and_restore',
}

export enum DiscoveryCategory {
    news = 'news',
    recommendation = 'recommendation',
}

export enum ProbeDetailsPage {
    homepage = 'homepage',
    probeList = 'probe_list',
}

export enum MeasurementPage {
    homepage = 'homepage',
    measurement = 'measurement',
    htmlViewer = 'html_viewer',
    viewInNewTab = 'view_in_new_tab',
}

export enum ShareLinkAction {
    copy = 'copy',
    share = 'share',
    done = 'done',
    cancel = 'cancel',
    excludeAndShare = 'exclude_and_share',
}

export enum UploadCustomCurveAction {
    upload = 'upload',
    uploadViewGuidelines = 'upload_view_guidelines',
    uploadViewGuidelinesBack = 'upload_view_guidelines_back',
    uploadPreviewCurve = 'upload_preview_curve',
    uploadDeleteFile = 'upload_delete_file',
    uploadClose = 'upload_close',
    previewBack = 'preview_back',
    previewDownloadCurve = 'preview_download_curve',
    previewDownloadColored = 'preview_download_colored',
    previewDownloadBlackAndWhite = 'preview_download_black_and_white',
    previewUpload = 'preview_upload',
    confirmUpload = 'confirm_upload',
    confirmUploadBack = 'confirm_upload_back',
}

export enum ExportAction {
    export = 'export',
    cancel = 'cancel',
    excludeAndExport = 'exclude_and_share',
}

export enum SnapshotAction {
    logbook = 'logbook',
    detail = 'detail',
}

export enum InsightsAction {
    endSession = 'end_session',
    shareSession = 'share_session',
    showKeyboard = 'show_keyboard',
    support = 'support',
    tools = 'tools',
}

export enum SubscriptionAction {
    mySubscriptionsExpand = 'my_subscriptions_expand',
    mySubscriptionsActivate = 'my_subscriptions_activate',
    mySubscriptionsUpgrade = 'my_subscriptions_upgrade',
    mySubscriptionsDownload = 'my_subscriptions_download',
    downloadStandalone = 'download_standalone',
    activate = 'activate',
    activateClose = 'activate_close',
    activateConfirm = 'activate_confirm',
    activateCancel = 'activate_cancel',
    startFreeTrial = 'start_free_trial',
    startFreeTrialClose = 'start_free_trial_close',
    upgradeClose = 'upgrade_close',
    upgradeContactSales = 'upgrade_contact_sales',
}

export enum SecurityAction {
    resetAuthApp = 'reset_auth_app_2fa',
    viewRecoveryKey = 'view_recovery_key_2fa',
}

export const ProductName: { [key in ProductCode | string]: string } = {
    [ProductCode.GPR]: 'gpr',
    [ProductCode.GPR_SOIL]: 'gprSubsurface',
    [ProductCode.EQUOTIP]: 'equotip',
    [ProductCode.PUNDIT]: 'pundit',
    [ProductCode.SCHMIDT]: 'schmidt',
    [ProductCode.FDL]: 'flawDetector',
    [ProductCode.GLM]: 'glossmeter',
    [ProductCode.PIT_IE]: 'punditImpact',
    [ProductCode.GPR_INSIGHTS]: 'gprInsights',
    [ProductCode.PROFOMETER]: 'profometer',
    [ProductCode.GPR_MOUNTED]: 'gprMounted',
    inspect: 'inspect',
};

export enum SnapshotModalAction {
    download = 'download',
    shortlistForReport = 'shortlist_for_report',
    removeFromReport = 'remove_from_report',
    cancel = 'cancel',
    rightButton = 'right_button',
    leftButton = 'left_button',
}

export enum ImageSliderAction {
    rightButton = 'right_button',
    leftButton = 'left_button',
    clickImage = 'click_image',
}

export enum ImageSliderCategory {
    snapshots = 'snapshots',
    supportingImages = 'supporting_images',
}

export enum ShortlistImageForReportCategory {
    snapshotInfo = 'snapshot_info',
    sectionHeader = 'section_header',
}

export enum ButtonName {
    refresh = 'refresh',
    upload = 'upload',
}

export enum UploadCadgisFileAction {
    upload = 'upload',
    cancel = 'cancel',
    deleteFile = 'delete_file',
    selectProjectionType = 'select_projection_type',
    selectUtmZone = 'select_utm_zone',
    selectCountry = 'select_country',
    selectCoordinateSystem = 'select_coordinate_system',
}
