import { ExportFormat, PDFExtendedFileType } from '../../api/utilsService';
import { LanguageOptions } from '../../localization';
import { FormatIDs } from '../../types';

export const longRunningExcludedFormat = [ExportFormat.JSON];

export const formatConfig: {
    [key: string]: {
        titleID: FormatIDs;
        format: string;
        fileType?: string;
    };
} = {
    [ExportFormat.HTML]: {
        titleID: 'Proceq.TableActionExportHTML',
        format: ExportFormat.HTML,
    },
    [ExportFormat.SEGY]: {
        titleID: 'Proceq.TableActionExportSEGY',
        format: ExportFormat.SEGY,
    },
    [ExportFormat.KML]: {
        titleID: 'Proceq.TableActionExportKML',
        format: ExportFormat.KML,
    },
    [ExportFormat.JSON]: {
        titleID: 'Proceq.TableActionExportJSON',
        format: ExportFormat.JSON,
    },
    [ExportFormat.DXF]: {
        titleID: 'Proceq.TableActionExportDXF',
        format: ExportFormat.DXF,
    },
    [ExportFormat.SHP]: {
        titleID: 'Proceq.TableActionExportSHP',
        format: ExportFormat.SHP,
    },
    [ExportFormat.PDF]: {
        titleID: 'Proceq.TableActionExportPDF',
        format: ExportFormat.PDF,
    },
    [ExportFormat.CSV]: {
        titleID: 'Proceq.TableActionExportCSV',
        format: ExportFormat.CSV,
    },
    [ExportFormat.RAW]: {
        titleID: 'Proceq.TableActionExportRaw',
        format: ExportFormat.RAW,
    },
    [ExportFormat.DOCX]: {
        titleID: 'Proceq.TableActionExportDOCX',
        format: ExportFormat.DOCX,
    },
    [PDFExtendedFileType.Uniformity]: {
        titleID: 'Proceq.TableActionExportPDF.TestRegionUniformity',
        format: ExportFormat.PDF,
        fileType: PDFExtendedFileType.Uniformity,
    },
    [PDFExtendedFileType.China]: {
        titleID: 'Proceq.TableActionExportPDF.TestRegionChina',
        format: ExportFormat.PDF,
        fileType: PDFExtendedFileType.China,
    },
    [PDFExtendedFileType.Europe]: {
        titleID: 'Proceq.TableActionExportPDF.TestRegionEU',
        format: ExportFormat.PDF,
        fileType: PDFExtendedFileType.Europe,
    },
};

export const languageTitleMap: { [key in LanguageOptions]: FormatIDs } = {
    en: 'Language.EN',
    zh: 'Language.ZH',
    ja: 'Language.JA',
    ko: 'Language.KO',
    de: 'Language.DE',
    fr: 'Language.FR',
    it: 'Language.IT',
    pt: 'Language.PT',
    ru: 'Language.RU',
    es: 'Language.ES',
};

export const languageKeys = Object.keys(languageTitleMap) as LanguageOptions[];
