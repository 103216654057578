import React, { useCallback, useMemo } from 'react';
import Pagination from 'antd/es/pagination';
import { Table } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useQueryClient } from '@tanstack/react-query';
import { getFileName } from 'tds-common-fe/src/lib/utils/fileUtils';
import styles from '../DataView.styl';
import ButtonRefresh from '../ButtonRefresh';
import { useDataViewContext } from '../DataViewProvider';
import TableWrapper from '../../TableWrapper';
import { spatialDataTableLocaleSetting } from '../../shared/MyEmpty';
import { useUserFilesContext } from './UserFilesProvider';
import { UserFile } from '../../../types/userFiles';
import { QueryKey } from '../ColumnConfig/columnConfigMeasurement';
import FormattedMessage from '../../../localization/FormatMessage';
import { getSpatialDataColumns } from '../ColumnConfig/columnConfigSpatialData';
import UploadButton from '../../shared/Buttons/UploadButton';
import DeleteButton from '../../shared/Buttons/DeleteButton';
import { ReactQueryKeys } from '../../../queries/queryKeys';
import { DEFAULT_PAGE_SIZE } from '../../../types/measurement';
import analytics from '../../../analytics/firebaseAnalytics';
import { ButtonName } from '../../../analytics/analyticsConstants';

const SpatialDataTable: React.FunctionComponent = () => {
    const queryClient = useQueryClient();
    const { product } = useDataViewContext();
    const {
        page,
        pageSize,
        isFetching,
        fileCount,
        setPage,
        setPageSize,
        setOrderBy,
        searchText,
        setSearchText,
        userFiles,
        setShowRenameModal,
        setShowUploadModal,
        setSelectedKeys,
        selectedKeys,
        setShowDeleteConfirmationModal,
    } = useUserFilesContext();

    // refreshes list and folder count
    const handleRefresh = useCallback(async () => {
        queryClient.invalidateQueries({ queryKey: [...ReactQueryKeys.userFile, 'list'] });
        analytics.logButtonClick(ButtonName.refresh);
    }, [queryClient]);

    const handleSelectKeys = useCallback(
        (values: React.Key[]) => {
            setSelectedKeys(values.map((d) => d.toString()));
        },
        [setSelectedKeys]
    );

    const handleOnChange = useCallback(
        (
            _: any,
            tableFilters: Record<string, (React.Key | boolean)[] | null>,
            tableSorter: SorterResult<UserFile> | SorterResult<UserFile>[]
        ) => {
            let shouldResetConfig = false;
            if (tableFilters) {
                for (const key in tableFilters) {
                    const values = tableFilters[key];
                    if (key === QueryKey.Name) {
                        if (values) {
                            const newSearchText = values?.[0]?.toString() ?? '';
                            if (searchText !== newSearchText) {
                                setSearchText(newSearchText);
                                shouldResetConfig = true;
                            }
                        } else {
                            setSearchText('');
                        }
                    }
                }
            }
            if (!Array.isArray(tableSorter) && tableSorter.column) {
                const orderBy = String(tableSorter?.columnKey);
                setOrderBy(tableSorter?.order === 'ascend' ? orderBy : `-${orderBy}`);
            } else {
                setOrderBy(undefined);
            }

            if (shouldResetConfig) {
                setSelectedKeys([]);
                setPage(1);
            }
        },
        [searchText, setSearchText, setOrderBy, setSelectedKeys, setPage]
    );

    const handleRenameFile = useCallback(
        (record: UserFile) => {
            const fileName = getFileName(record.name);
            setShowRenameModal({ id: record.id, currentName: fileName, extension: record.extension });
        },
        [setShowRenameModal]
    );

    const columns = useMemo(() => {
        const userFileColumns: ColumnsType<UserFile> = [...getSpatialDataColumns(handleRenameFile)];

        const actionColumn: ColumnType<UserFile> = {
            key: 'action',
            className: styles.action_cell,
            title: <FormattedMessage id="App.Actions" />,
            dataIndex: 'id',
            width: 120,
            fixed: 'right',
            render: (_, record) => {
                return (
                    <div className={styles.action_wrapper}>
                        <DeleteButton
                            onClick={() => setShowDeleteConfirmationModal({ ids: [record.id], name: record.name })}
                            isPermanentDelete
                            isTableButton
                        />
                    </div>
                );
            },
        };

        userFileColumns.push(actionColumn);
        return userFileColumns;
    }, [handleRenameFile, setShowDeleteConfirmationModal]);

    return (
        <div className={styles.content_column}>
            <div className={styles.dataView_header}>
                <div className={styles.common_actions}>
                    {selectedKeys.length === 0 && (
                        <>
                            <ButtonRefresh onClick={handleRefresh} disabled={isFetching} />
                            <UploadButton
                                onClick={() => {
                                    setShowUploadModal(true);
                                    analytics.logButtonClick(ButtonName.upload);
                                }}
                            />
                        </>
                    )}
                    {product && selectedKeys.length > 0 && (
                        <DeleteButton
                            onClick={() => {
                                const firstFileName =
                                    selectedKeys.length === 1
                                        ? userFiles.find((file) => file.id === selectedKeys[0])?.name ?? ''
                                        : undefined;
                                setShowDeleteConfirmationModal({ ids: selectedKeys, name: firstFileName });
                            }}
                            isPermanentDelete
                            primaryStyle
                        />
                    )}
                </div>
                <Pagination
                    size={'small'}
                    pageSize={pageSize}
                    current={page}
                    total={fileCount}
                    pageSizeOptions={['20', '50', '100']}
                    onChange={(page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize ?? DEFAULT_PAGE_SIZE);
                    }}
                    className={styles.dataView_pagination}
                    showSizeChanger
                />
            </div>
            <div className={styles.dataView_content}>
                <TableWrapper>
                    {(tableHeight) => (
                        <Table<UserFile>
                            key={product}
                            columns={columns}
                            className={'scroll-table'}
                            rowKey="id"
                            dataSource={userFiles}
                            pagination={{
                                pageSize,
                                current: page,
                                style: {
                                    display: 'none',
                                },
                            }}
                            onChange={handleOnChange}
                            scroll={{ y: (tableHeight ?? 0) - 55 }} // 55 is header row height
                            loading={isFetching}
                            locale={spatialDataTableLocaleSetting}
                            sticky
                            rowSelection={{ selectedRowKeys: selectedKeys, onChange: handleSelectKeys }}
                        />
                    )}
                </TableWrapper>
            </div>
        </div>
    );
};

export default SpatialDataTable;
