import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import DataViewFolders from './DataViewFolder';
import DataTableMeasurement from './DataTableMeasurement';
import DataTableCustomMaterial from './DataTableCustomMaterial';
import { DataViewProvider, useDataViewContext } from './DataViewProvider';
import DataViewModal from './DataViewModal';
import AppLayout from '../shared/AppLayout';
import { getDataViewRoute } from '../Routes/urls';
import { productMap } from '../shared/AppIcon';
import { BluetoothFileType, productDataCategoryRoutes, SystemFolderID, ViewType } from '../../types/measurement';
import { ProductCode } from '../../types/proceq';
import useRefWithChange from '../../hooks/useRefWithChange';
import NonEditableModal from './NonEditableModal';
import ImportFileExists from '../MeasurementImport/ImportFileExists';
import { useProceqAppConfig } from '../../queries/utilsQueries';
import { SPATIAL_DATA_FILE_TYPE } from '../../types/userFiles';
import SpatialDataTable from './SpatialData/SpatialDataTable';
import { UserFilesProvider } from './SpatialData/UserFilesProvider';
import { MeasurementProvider } from './MeasurementContext/MeasurementProvider';

interface DataViewProps {
    customCurveNameConflicts?: string[];
}

const DataView: React.FunctionComponent = () => {
    const history = useHistory();
    const params = useParams<{ productName: string; viewType: ViewType; folderID: string }>();
    const productCode = productMap[params.productName]?.productCode ?? (params.productName as ProductCode);

    const locationDataViewState = useLocation<DataViewProps>();
    const [customCurveNameConflicts, setCustomCurveNameConflicts] = useState<string[] | undefined>(
        locationDataViewState.state?.customCurveNameConflicts
    );

    const stateFolderIDRef = useRefWithChange(params.folderID);

    const { setProduct, fileType, setFileType, setViewType, setActiveFolder } = useDataViewContext();

    const isMeasurement = fileType !== BluetoothFileType.CustomMaterial;

    useProceqAppConfig(productCode);

    useEffect(() => {
        setActiveFolder(params.folderID);
    }, [params.folderID, setActiveFolder]);

    useEffect(() => {
        setProduct(productCode, stateFolderIDRef.current);
    }, [productCode, setProduct, stateFolderIDRef]);

    useEffect(() => {
        if (params.viewType === ViewType.Archived) {
            setViewType(ViewType.Archived);
        } else {
            setViewType(ViewType.Active);
        }
    }, [params.viewType, setViewType]);

    useEffect(() => {
        if (productDataCategoryRoutes.some((category) => category === params.folderID)) {
            setFileType(params.folderID);
        } else {
            const route = getDataViewRoute({
                productName: params.productName,
                viewType: params.viewType,
                folderID: params.folderID || SystemFolderID.All,
            });
            if (location.pathname !== route) {
                history.push(route);
            }
            // keep file type if navigating between measurement related listing as it is used to filter mode (eg area scan)
            setFileType((prevState) => {
                // if user is navigating from non-measurement related listing to measurement listing, reset the file type
                if (productDataCategoryRoutes.some((category) => category === prevState)) {
                    return '';
                }
                return prevState;
            });
        }
    }, [history, params.folderID, params.productName, params.viewType, setFileType]);

    return (
        <AppLayout>
            <DataViewFolders />
            {fileType === SPATIAL_DATA_FILE_TYPE ? (
                <UserFilesProvider>
                    <SpatialDataTable />
                </UserFilesProvider>
            ) : (
                <MeasurementProvider>
                    {isMeasurement ? <DataTableMeasurement /> : <DataTableCustomMaterial />}
                    <ImportFileExists
                        onClose={() => setCustomCurveNameConflicts(undefined)}
                        customCurveNameConflicts={customCurveNameConflicts}
                    />
                    <DataViewModal />
                    <NonEditableModal />
                </MeasurementProvider>
            )}
        </AppLayout>
    );
};

const DataViewWithProvider: React.FunctionComponent = () => {
    return (
        <DataViewProvider>
            <DataView />
        </DataViewProvider>
    );
};

export default DataViewWithProvider;
