import { routeUtils } from 'tds-common-fe';
import { getApiURL } from './ApiURL';
import { thunkDelete, thunkGet, thunkPost, thunkPut } from './apiService';
import { handleError } from './error';
import { UserFile, UserFileExtensions, UserFileOption } from '../types/userFiles';
import { APIResponse } from '../types/api';

interface CreateUserFileParams {
    fID: string;
    name: string;
    extension: string;
    attributes?: { [key: string]: string | number | undefined };
}

export const createUserFile = async (params: CreateUserFileParams) => {
    const url = getApiURL('USER_FILE');
    const { response } = await thunkPost<{ data: UserFile }>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: { ...params },
    });
    return response;
};

export interface GetUserFileParams {
    limit?: number;
    offset?: number;
    orderBy?: string;
    q?: string; // query param to search by text
}

export const getUserFiles = async (params: GetUserFileParams) => {
    const url = getApiURL('USER_FILE');
    const { response } = await thunkGet<{ data: UserFile[]; totalRecords: number }>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: { ...params },
    });
    return response;
};

export const updateUserFile = async (fileID: string, fileName: string) => {
    const url = getApiURL('UPDATE_USER_FILE').replace('{fileID}', fileID);
    const { response } = await thunkPut(url, {
        errorHandler: handleError,
        injectToken: true,
        params: { name: fileName },
    });
    return response;
};

export const deleteUserFiles = async (ids: string[]) => {
    const url = getApiURL('USER_FILE');
    const { response } = await thunkDelete(url, {
        errorHandler: handleError,
        injectToken: true,
        params: { ids },
    });
    return response;
};

interface CheckUserFileNameExistsParams {
    name: string;
    id?: string;
}

export const checkUserFileNameExists = async (params: CheckUserFileNameExistsParams) => {
    const url = routeUtils.makeQueryPath(getApiURL('USER_FILE_NAME_EXISTS'), { ...params });
    const { response } = await thunkGet<{ data: { exists: boolean } }>(url, {
        errorHandler: handleError,
        injectToken: true,
    });
    return response.data.exists;
};

export const getUserFileExtensions = async () => {
    const url = getApiURL('USER_FILE_EXTENSIONS');
    const { response } = await thunkGet<APIResponse<UserFileExtensions[]>>(url, {
        errorHandler: handleError,
        injectToken: true,
    });
    return response.data;
};

export const getUserFileOption = async () => {
    const url = getApiURL('USER_FILE_OPTION');
    const { response } = await thunkGet<APIResponse<UserFileOption>>(url, {
        errorHandler: handleError,
        injectToken: true,
    });
    return response.data;
};
