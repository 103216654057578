import React, { useState } from 'react';
import { Button, Popover, Progress } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useInterval } from 'tds-common-fe';
import { useDispatch } from 'react-redux';
import styles from '../DataView.styl';
import { MeasurementListItem } from '../../../types/measurement';
import colors from '../../../styles/colors.json';
import IconButton from '../../shared/Buttons/IconButton';
import FormattedMessage from '../../../localization/FormatMessage';
import * as measurementActions from '../../../actions/measurement';
import { ProductCode } from '../../../types/proceq';
import TooltipText from '../../shared/TooltipText';
import getIsMeasurementUnsynced from '../DataViewers/utils/getIsMeasurementUnsynced';
import ExpandButton from '../../shared/Buttons/ExpandButton';
import Tooltip from '../../shared/Tooltip';
import MeasurementEditButton from './MeasurementEditButton';

// Update 3% every 10 seconds, max out at 5 minutes
const PROGRESS_DELAY = 10 * 1000;
const PROGRESS_PER_DELAY = 3;
const MAX_PROGRESS = 90;

interface NameColumnProps {
    measurement: MeasurementListItem;
    allowExpansion?: boolean;
    isExpanded?: boolean;
    onExpand?: (id: string, isExpanded: boolean) => void;
    hideEditButton?: boolean;
}

const NameColumn: React.FunctionComponent<NameColumnProps> = (props) => {
    const { measurement, hideEditButton, allowExpansion, isExpanded = false, onExpand } = props;
    const { name, isImport = false, isCreate = false, importStatus, timestamp, id, productType } = measurement;
    const [progress, setProgress] = useState(() => {
        if (!timestamp) return 0;
        return Math.min(Math.floor((Date.now() - timestamp) / PROGRESS_DELAY) * PROGRESS_PER_DELAY, MAX_PROGRESS);
    });
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);

    const dispatch = useDispatch();

    const delay =
        ((isImport && importStatus === 'pending' && !!timestamp) || isCreate) && progress < MAX_PROGRESS
            ? PROGRESS_DELAY
            : null;

    // Fake progress. It will update 10% in every second and max out at 90%.
    useInterval(() => {
        setProgress((currentProgress) => currentProgress + PROGRESS_PER_DELAY);
    }, delay);

    const handlePopover = () => {
        setIsPopoverVisible(false);
        dispatch(measurementActions.removeImportMeasurement({ id, product: productType.toUpperCase() as ProductCode }));
    };

    const isUnsynced = getIsMeasurementUnsynced(measurement);

    return (
        <div className={styles.title_wrapper}>
            <div>
                {((isImport && importStatus === 'pending') || isCreate) && (
                    <Progress
                        type="circle"
                        percent={progress}
                        showInfo={false}
                        width={22}
                        strokeWidth={12}
                        style={{ padding: '0 1px' }}
                        strokeColor={colors.primary}
                    />
                )}
                {allowExpansion && !isImport && !isCreate && !isUnsynced && (
                    <ExpandButton
                        isActive={isExpanded}
                        className={styles.name_expand_icon}
                        onClick={(isExpanded: boolean) => onExpand?.(measurement.id, isExpanded)}
                    />
                )}
                <TooltipText text={name} />
                <MeasurementEditButton measurement={measurement} hideButton={hideEditButton || isImport || isCreate} />
                {isImport && importStatus === 'failure' && (
                    <Popover
                        content={
                            <>
                                <FormattedMessage id="Import.Failure.Message" />
                                <Button type="link" onClick={handlePopover}>
                                    <FormattedMessage id="App.Remove" />
                                </Button>
                            </>
                        }
                        trigger="click"
                        open={isPopoverVisible}
                        onOpenChange={setIsPopoverVisible}
                    >
                        <IconButton>
                            <ExclamationCircleOutlined style={{ color: colors.red600 }} />
                        </IconButton>
                    </Popover>
                )}
                {isUnsynced && (
                    <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                        <Tooltip title={<FormattedMessage id={'App.HTML.GPR_ARRAY_VM.IncompleteSync'} />}>
                            <ExclamationCircleOutlined style={{ color: colors.yellow700 }} />
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NameColumn;
