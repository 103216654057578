import React, { createRef, useCallback, useState } from 'react';
import { Button, Dropdown } from 'antd';
import styles from './Header.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import { ReactComponent as IconArrowDown } from '../../../images/iconArrowDown.svg';
import { ReactComponent as IconEnvelope } from '../../../images/iconEnvelope.svg';
import { SUPPORT_EMAIL } from '../../../constants';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import analytics from '../../../analytics/firebaseAnalytics';
import { InsightsAction } from '../../../analytics/analyticsConstants';
import * as mobileUtils from '../../../utils/mobileUtils';
import { AntdMenuItem } from '../../../types/antd';
import { ProductFeature, useProductContext } from '../../DataView/ProductContextProvider';
import logger from '../../../logging/logger';

export interface InsightsMenuProps {
    endSession?: () => void;
    isAppstreamReady?: boolean;
    shareSession?: () => void;
    relaunchSession?: () => void;
    isStandaloneTool?: boolean;
}

const InsightsMenu: React.FunctionComponent<InsightsMenuProps> = (props) => {
    const { relaunchSession, endSession, isAppstreamReady = false, shareSession, isStandaloneTool = false } = props;
    const inputRef = createRef<HTMLInputElement>();
    const [isSoftKeyboardShown, setIsSoftKeyboardShown] = useState(false);
    const showKeyboardButton = mobileUtils.isTablet();
    const formatMessage = useFormatMessage();
    const handleMailTo = useCallback(() => {
        logger.log('header | email support');
        window.open(`mailto:${SUPPORT_EMAIL}?subject=${formatMessage({ id: 'EmailSupport.Title' })}`, '_blank');
        analytics.logInsightsAction(InsightsAction.support);
    }, [formatMessage]);

    const { isFeatureEnabled } = useProductContext();
    const isRelaunchEnabled = isFeatureEnabled(ProductFeature.INSIGHTS_RELAUNCH_APP);

    const launchVirtualKeyboard = useCallback(() => {
        logger.log('header | launch virtual keyboard');
        if (inputRef.current?.style) {
            if (isSoftKeyboardShown) {
                inputRef.current.style.visibility = 'hidden';
            } else {
                inputRef.current.style.visibility = 'visible';
                inputRef.current.focus();
                analytics.logInsightsAction(InsightsAction.showKeyboard);
            }
        }
        setIsSoftKeyboardShown((prevState) => !prevState);
    }, [inputRef, isSoftKeyboardShown]);

    const menuItems: AntdMenuItem[] = [
        ...(showKeyboardButton
            ? [
                  {
                      key: 'keyboard',
                      onClick: launchVirtualKeyboard,
                      disabled: !(isAppstreamReady && !isSoftKeyboardShown),
                      label: <FormattedMessage id="Header.Insights.ShowKeyboard" />,
                  },
              ]
            : []),
        {
            key: 'shareSession',
            onClick: () => {
                logger.log('header | share session');
                shareSession?.();
                analytics.logInsightsAction(InsightsAction.shareSession);
            },
            disabled: !isAppstreamReady,
            label: <FormattedMessage id="Header.Insights.ShareSession" />,
        },
        {
            key: 'emailSupport',
            onClick: handleMailTo,
            label: <FormattedMessage id="EmailSupport.Button" />,
        },
    ];

    return (
        <div className={styles.insights_container}>
            {isStandaloneTool ? (
                <Button type="link" className={styles.support_link} onClick={handleMailTo}>
                    <IconEnvelope />
                    <FormattedMessage id="EmailSupport.Button" />
                </Button>
            ) : (
                <>
                    {showKeyboardButton && (
                        <input
                            id="keyboardInput"
                            ref={inputRef}
                            className={styles.keyboard_input}
                            onBlur={() => {
                                if (inputRef.current?.style) {
                                    if (isSoftKeyboardShown) {
                                        inputRef.current.style.visibility = 'hidden';
                                    }
                                }
                                setIsSoftKeyboardShown(false);
                            }}
                        />
                    )}
                    {isRelaunchEnabled && (
                        <AnalyticsButton
                            id={'insights_relaunch_app'}
                            type="link"
                            className={styles.tools_dropdown}
                            onClick={() => {
                                logger.log('header | relaunch app');
                                relaunchSession?.();
                            }}
                        >
                            <FormattedMessage id="Tool.Insights.RelaunchApp" />
                        </AnalyticsButton>
                    )}
                    <Button
                        type="link"
                        className={styles.center}
                        onClick={() => {
                            logger.log('header | end session');
                            endSession?.();
                            analytics.logInsightsAction(InsightsAction.endSession);
                        }}
                        disabled={!isAppstreamReady}
                    >
                        <FormattedMessage id="Header.Insights.EndSession" />
                    </Button>
                    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
                        <AnalyticsButton
                            type="link"
                            className={styles.tools_dropdown}
                            onClick={() => analytics.logInsightsAction(InsightsAction.tools)}
                        >
                            <FormattedMessage id="Header.Insights.Tools" />
                            <IconArrowDown />
                        </AnalyticsButton>
                    </Dropdown>
                </>
            )}
        </div>
    );
};

export default InsightsMenu;
