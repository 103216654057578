import { merge, union } from 'lodash';
import { AnyAction, Reducer } from 'redux';
import actionTypes, { PayloadAction } from '../actions/actionTypes';
import { AccountDeleteStatus, UserProfileInfo } from '../types/profile';
import {
    ReceivedRoleInfoPayload,
    ReceivedUserLicenseListPayload,
    ReceivedUserLicensePayload,
} from '../actions/profile';
import { convertRolesSetToState, expandRoles } from '../utils/rolesHelper';
import { LicenseProduct, UserLicenseInfo } from '../types/license';
import { ProductCode } from '../types/proceq';
import { normalizer } from '../utils/genericNormalizer';

export interface UserProfileState extends Partial<UserProfileInfo> {}

export interface UserLicenseState {
    [product: string]: Partial<UserLicenseInfo>;
}

export interface RegionProfileState {
    pqRegion?: string;
    pqRegionalDomain?: string;
    pqManagementDomain?: string;
}

export interface RoleState {
    isSuper: boolean;
    isAdmin: boolean;
    isPO: boolean;
    isBUAdmin: boolean;
    isBU: boolean;
    isDev: boolean;
    isUser: boolean;
    isAnalyst: boolean;
    isInternalUser: boolean;
}

export interface ProfileState {
    userProfile: UserProfileState;
    userActiveLicenseKeys: (ProductCode | LicenseProduct)[];
    userExpiredLicenseKeys: (ProductCode | LicenseProduct)[];
    userLicense: UserLicenseState;
    regionInfo: RegionProfileState;
    roleState: RoleState;
    accountDeleteStatus: AccountDeleteStatus | undefined;
}

export const initialState: ProfileState = {
    userProfile: {},
    userActiveLicenseKeys: [],
    userExpiredLicenseKeys: [],
    userLicense: {},
    regionInfo: {},
    roleState: {
        isSuper: false,
        isAdmin: false,
        isPO: false,
        isBUAdmin: false,
        isBU: false,
        isDev: false,
        isUser: false,
        isAnalyst: false,
        isInternalUser: false,
    },
    accountDeleteStatus: undefined,
};

const profile: Reducer<ProfileState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RECEIVED_USER_PROFILE: {
            const { payload } = action as PayloadAction<UserProfileState>;
            const userProfile =
                state.userProfile.id === payload.id ? { ...state.userProfile, ...payload } : { ...payload };
            return {
                ...state,
                userProfile,
            };
        }
        case actionTypes.REMOVED_PROFILE_PICTURE: {
            const { payload } = action as PayloadAction<UserProfileState>;
            const userProfile = { ...state.userProfile, ...payload };
            return {
                ...state,
                userProfile,
            };
        }
        case actionTypes.RECEIVED_USER_LICENSE_LIST: {
            const { payload } = action as PayloadAction<ReceivedUserLicenseListPayload>;
            const { data } = payload;
            const { entityIds, entities } = normalizer(data, 'product');
            const userActiveLicenseKeys: LicenseProduct[] = [];
            const userExpiredLicenseKeys: LicenseProduct[] = [];
            data.forEach((license) => {
                const licenseProduct: LicenseProduct = license.product.toUpperCase() as LicenseProduct;
                if (license.active) {
                    userActiveLicenseKeys.push(licenseProduct);
                } else {
                    userExpiredLicenseKeys.push(licenseProduct);
                }
            });
            for (const product of entityIds) {
                state.userLicense[product.toUpperCase()] = entities[product];
            }
            return {
                ...state,
                userActiveLicenseKeys,
                userExpiredLicenseKeys,
            };
        }
        case actionTypes.RECEIVED_USER_LICENSE: {
            const { payload } = action as PayloadAction<ReceivedUserLicensePayload>;
            const { product, license } = payload;

            return {
                ...state,
                userActiveLicenseKeys: union([product], state.userActiveLicenseKeys),
                userLicense: merge({ [product]: license }, state.userLicense),
            };
        }
        case actionTypes.RECEIVED_REGION_INFO: {
            const { payload } = action as PayloadAction<RegionProfileState>;
            const regionInfo = { ...state.regionInfo, ...payload };
            return {
                ...state,
                regionInfo,
            };
        }

        case actionTypes.RECEIVED_ROLE_INFO: {
            const { payload } = action as PayloadAction<ReceivedRoleInfoPayload>;
            const { roleString } = payload;

            const rolesSet = expandRoles(roleString);
            const roleState = convertRolesSetToState(rolesSet);
            return {
                ...state,
                roleState,
            };
        }
        default:
            return state;
    }
};

export default profile;
