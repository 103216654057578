import React from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';
import FormattedMessage from '../../../localization/FormatMessage';
import { getLabelFromGlobalDataKey } from '../RegisteredInfo/helper';
import { renderDate } from './columnUtils';
import { CustomMaterialItem } from '../../../types/customMaterial';
import styles from '../DataView.styl';
import FlagColumn, { FlagColumnType } from './FlagColumn';
import TooltipText from '../../shared/TooltipText';
import ExpandButton from '../../shared/Buttons/ExpandButton';
import MeasurementEditButton from './MeasurementEditButton';

enum QueryKey {
    Name = 'name',
    Created = 'created',
    Flagged = 'flagged',
}

const flagColumn: ColumnType<CustomMaterialItem> = {
    title: '',
    dataIndex: QueryKey.Flagged,
    key: QueryKey.Flagged,
    fixed: 'left',
    width: 40,
    render: (value: string, record) => (
        <FlagColumn customMaterialItem={record} flagColumnType={FlagColumnType.customMaterial} allowClick />
    ),
};

interface GetDescriptionColumnParams {
    isExpanded: (id: string) => boolean;
    onExpand: (id: string, isExpanded: boolean) => void;
}

const getDescriptionColumn = ({ isExpanded, onExpand }: GetDescriptionColumnParams): ColumnType<CustomMaterialItem> => {
    return {
        key: QueryKey.Name,
        dataIndex: ['name'],
        title: <FormattedMessage id="Proceq.TableDescription" />,
        width: 200,
        fixed: 'left',
        sorter: true,
        render: (value: string, record) => {
            const isMeasurementExpanded = isExpanded(record.id);
            return (
                <div className={styles.title_wrapper}>
                    <div>
                        <ExpandButton
                            isActive={isMeasurementExpanded}
                            className={styles.name_expand_icon}
                            onClick={() => {
                                onExpand?.(record.id, isMeasurementExpanded);
                            }}
                        />
                        <TooltipText text={value} />
                        <MeasurementEditButton measurement={record} />
                    </div>
                </div>
            );
        },
    };
};

export const clientCreatedColumn: ColumnType<CustomMaterialItem> = {
    dataIndex: QueryKey.Created,
    key: QueryKey.Created,
    title: <FormattedMessage id="App.Created" />,
    width: 180,
    sorter: true,
    render: renderDate,
};

export const getEquotipColumnConfig = (params: GetDescriptionColumnParams): ColumnsType<CustomMaterialItem> => [
    flagColumn,
    getDescriptionColumn(params),
    clientCreatedColumn,
    {
        title: <FormattedMessage id="Proceq.TableBaseMaterial" />,
        dataIndex: ['content', 'baseMaterialId'],
        width: 180,
        render: (value) => <IntlFormattedMessage id={getLabelFromGlobalDataKey('materials', value) ?? ' '} />,
    },
    {
        title: <FormattedMessage id="Proceq.TableScaleUnit" />,
        dataIndex: ['content', 'targetScaleId'],
        width: 180,
        render: (value) => getLabelFromGlobalDataKey('units', value),
    },
];

export const getSchmidtColumnConfig = (params: GetDescriptionColumnParams): ColumnsType<CustomMaterialItem> => [
    flagColumn,
    getDescriptionColumn(params),
    clientCreatedColumn,
    {
        title: <FormattedMessage id="Proceq.TableScaleUnit" />,
        dataIndex: ['content', 'scaleId'],
        width: 180,
        render: (value) => getLabelFromGlobalDataKey('unitsschmidt', value),
    },
    {
        title: <FormattedMessage id="Proceq.ExportFieldCurveType" />,
        dataIndex: ['content', 'curveTypeId'],
        width: 180,
        render: (value) => (
            <IntlFormattedMessage id={getLabelFromGlobalDataKey('schmidtcurvetypesshort', value) ?? ' '} />
        ),
    },
];
