import { useCallback, useEffect, useState } from 'react';
import JSZip from 'jszip';
import { find } from 'lodash';
import { SnapshotEntry, SnapshotType } from '../components/DataView/DataViewers/Readings/SnapshotsViewer/SnapshotTypes';
import { getSharedProceqUsers } from '../api/userService';
import { getFile } from '../api/utilsService';
import { Log } from '../types/logs';
import { Attachment } from '../types/measurement';
import { useMeasurementViewerContext } from '../components/StandaloneMeasurementView/MeasurementViewerContext';

export enum MeasurementImageType {
    snapshots = 'snapshots',
    others = 'others',
}

interface UseMeasurementImagesParams {
    logs?: Log[];
    attachments?: Attachment[];
    measurementImageType: MeasurementImageType;
}

const useMeasurementImages = (params: UseMeasurementImagesParams) => {
    const { logs, attachments, measurementImageType } = params;
    const [entries, setEntries] = useState<{ [key: string]: SnapshotEntry }>({});
    const [entryIDs, setEntryIDs] = useState<string[]>([]);
    const [openedID, setOpenedID] = useState('');
    const isSnapshot = measurementImageType === MeasurementImageType.snapshots;
    const { isHTMLExport } = useMeasurementViewerContext();

    useEffect(() => {
        const imageLogs = logs
            ?.filter((log) =>
                isSnapshot
                    ? log.type === SnapshotType.SavedSnapshot || log.type === SnapshotType.ExportedSnapshot
                    : log.type === 'userPhoto'
            )
            .sort((logA, logB) => logB.clientCreated - logA.clientCreated);
        const latestSnapshot = isSnapshot
            ? attachments?.find((attachment) => attachment.type === SnapshotType.LatestViewedSnapshot)
            : undefined;

        const newEntries: { [key: string]: SnapshotEntry } = {};

        const entryIDs: string[] = [];
        if (latestSnapshot) {
            const aID = latestSnapshot.id;
            const mID = latestSnapshot.mID;
            newEntries[aID] = { mID, type: SnapshotType.LatestViewedSnapshot };
            entryIDs.push(aID);
        }
        if (imageLogs) {
            const userIDs = new Set<number>();
            for (const log of imageLogs) {
                const aID = log.content?.attachmentId;
                const mID = log.mID;
                if (!aID) {
                    continue;
                }
                userIDs.add(log.uID);
                newEntries[aID] = {
                    mID,
                    type: log.type as SnapshotType,
                    log,
                };
                entryIDs.push(aID);
            }
            if (!isHTMLExport && isSnapshot && userIDs.size > 0) {
                getSharedProceqUsers({ ids: Array.from(userIDs) });
            }
        }
        if (entryIDs.length) {
            setEntries((entries) => {
                for (const aID of entryIDs) {
                    newEntries[aID] = {
                        ...entries[aID],
                        ...newEntries[aID],
                        ...(isHTMLExport ? { imageURL: `media/${aID}.jpg`, imageName: `${aID}.jpg` } : {}),
                    };
                }
                return {
                    ...entries,
                    ...newEntries,
                };
            });
            setEntryIDs(entryIDs);
            setOpenedID(entryIDs[0]);
        }

        return () => {
            setEntries({});
            setOpenedID('');
            setEntryIDs([]);
        };
    }, [attachments, isHTMLExport, isSnapshot, logs]);

    const fetchData = useCallback(async (mID: string, aID: string) => {
        let imageURL = '';
        let imageName = '';
        try {
            const arrayBuffer = await getFile({ mID, aID });
            const zip = await JSZip.loadAsync(arrayBuffer);
            const image = find(zip.files, (_file, fileName: string) => /\.(jpg|png)$/.test(fileName));

            if (image) {
                const blob = await image.async('blob');
                imageURL = window.URL.createObjectURL(blob);
                imageName = image.name;
            }
        } finally {
            setEntries((entries) => {
                return { ...entries, [aID]: { ...entries[aID], imageURL, imageName } };
            });
        }
    }, []);

    const onLazyLoad = useCallback(
        (slides: number[]) => {
            if (!entryIDs.length || isHTMLExport) {
                return;
            }
            for (const slide of slides) {
                const fixedIndex = (slide + entryIDs.length) % entryIDs.length;
                const aID = entryIDs[fixedIndex];
                const mID = entries[aID].mID;
                if (mID && aID && !entries[aID].imageName) {
                    fetchData(mID, aID);
                }
            }
        },
        [entries, entryIDs, fetchData, isHTMLExport]
    );

    return {
        entries,
        setEntries,
        entryIDs,
        setEntryIDs,
        openedID,
        setOpenedID,
        onLazyLoad,
    };
};

export default useMeasurementImages;
