import { MeasurementFullData, ProfometerUnit } from '../../../../../types/measurement';
import { FormatIDs, FormatMessageFunc } from '../../../../../types';
import { ScanType } from '../../../../../types/proceq';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import { CORROSION_SCAN_TYPES, PROBE_TYPE_TEXT, ProbeType } from '../../../../../types/profometer';
import { getMeasurementMode } from '../../../../../utils/getMeasurementMode';

enum MeasuringRange {
    off = 'off',
    auto = 'auto',
    small = 'small',
    large = 'large',
}

const MEASURING_RANGE_TEXT: Record<MeasuringRange, FormatIDs> = {
    [MeasuringRange.off]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Off',
    [MeasuringRange.auto]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Auto',
    [MeasuringRange.small]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Standard',
    [MeasuringRange.large]: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange.Deep',
};

const CORROSION_PRESET_UNIT_TEXT: Record<ProfometerUnit, FormatIDs> = {
    [ProfometerUnit.metric]: 'Proceq.Settings.Unit.Metric',
    [ProfometerUnit.metricJapanese]: 'Proceq.Settings.Unit.Metric',
    [ProfometerUnit.imperial]: 'Proceq.Settings.Unit.Imperial',
    [ProfometerUnit.imperialDiameter]: 'Proceq.Settings.Unit.Imperial',
};

const PRESET_UNIT_TEXT: Record<ProfometerUnit, FormatIDs> = {
    [ProfometerUnit.metric]: 'Proceq.Settings.Unit.Metric',
    [ProfometerUnit.metricJapanese]: 'App.HTML.Profometer.MeasurementSettings.Unit.MetricJapanese',
    [ProfometerUnit.imperial]: 'App.HTML.Profometer.MeasurementSettings.Unit.Imperial',
    [ProfometerUnit.imperialDiameter]: 'App.HTML.Profometer.MeasurementSettings.Unit.ImperialDiameter',
};

export const getUnitFormatID = (unit: ProfometerUnit, scanType?: ScanType) =>
    scanType && CORROSION_SCAN_TYPES.has(scanType) ? CORROSION_PRESET_UNIT_TEXT[unit] : PRESET_UNIT_TEXT[unit];

enum MeasuringPattern {
    uniDirectional = 'uniDirectional',
    zigZag = 'zigZag',
}

const MEASURING_PATTERN_TEXT: Record<MeasuringPattern, FormatIDs> = {
    [MeasuringPattern.uniDirectional]: 'App.HTML.Profometer.MeasurementSettings.MeasuringPattern.Unidirectional',
    [MeasuringPattern.zigZag]: 'App.HTML.Profometer.MeasurementSettings.MeasuringPattern.Zigzag',
};

export const getProfometerValue = (convert: (value: string | number, unitId: string) => string | number) => {
    return (row: RawSectionTableData) => {
        // skip conversion as all rebar diameters will be shown either in mm or bar size
        if (row.unitId && row.unitId === 'Profometer.Measurement.RebarDiameter') {
            return row.value;
        }
        return row.unitId ? convert(row.value, row.unitId) : row.value;
    };
};

export const getProfometerMeasurementPresets = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc,
    isFullList?: boolean
) => {
    if (!data) {
        return [];
    }
    const mode = data.measurement.type;
    const isCorrosion = CORROSION_SCAN_TYPES.has(scanType);
    const {
        gridSpacingX = 0,
        gridSpacingY = 0,
        numberOfHorizontalLines = 0,
        numberOfVerticalLines = 0,
        isFlexible,
    } = data.settings[0].content.corrosionScanStatus?.areaScanParameters ?? {};

    const {
        gridSpacingX: areaScanGridSpacingX = 0,
        gridSpacingY: areaScanGridSpacingY = 0,
        numberOfHorizontalLines: areaScanNumberOfHorizontalLines = 0,
        numberOfVerticalLines: areaScanNumberOfVerticalLines = 0,
        startDirection,
        isFlexible: areaScanIsFlexible,
    } = data.settings[0].content.areaScanStatus?.areaScanParameters ?? {};

    const isAreaMultiLineScan = scanType === ScanType.AreaScan || scanType === ScanType.MultiLineScan;

    const presetsRaw: RawSectionTableData[] = isCorrosion
        ? [
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.MeasuringMode',
                  value: `${formatMessage({ id: 'App.HTML.Profometer.MeasurementSettings.Corrosion' })} - ${getMeasurementMode(mode)}`,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.MeasurementOrientation',
                  value: formatMessage({
                      id:
                          data.settings[0].content?.corrosionScanStatus?.areaScanParameters?.startDirection === 'X'
                              ? 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Horizontal'
                              : 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Vertical',
                  }),
                  enable: !!isFullList,
              },
              {
                  label: 'App.HTML.FDL.IndicationInfo.ProbeType',
                  value: formatMessage({
                      id: PROBE_TYPE_TEXT[data.settings[0].content?.preset?.corrosionProbeType as ProbeType] ?? ' ',
                  }),
              },
              {
                  label: 'App.HTML.Profometer.Logbook.HalfCellSolution',
                  value: data.settings[0].content?.preset?.halfCellSolution,
              },
              {
                  label: 'Units',
                  value:
                      data.settings[0].content.preset.unit && data.settings[0].content.preset.unit in ProfometerUnit
                          ? formatMessage({
                                id: getUnitFormatID(data.settings[0].content.preset.unit as ProfometerUnit, scanType),
                            })
                          : '',
              },

              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup',
                  value: formatMessage({
                      id: data.settings[0].content?.corrosionScanStatus?.areaScanParameters?.isFlexible
                          ? 'App.HTML.Profometer.MeasurementSettings.GridSetup.Dynamic'
                          : 'App.HTML.Profometer.MeasurementSettings.GridSetup.Fixed',
                  }),
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.GridDimensionX',
                  value: (numberOfVerticalLines - 1) * gridSpacingX,
                  unitId: 'Profometer.Measurement.GridDimension',
                  enable: !!isFullList && !isFlexible,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.GridDimensionY',
                  value: (numberOfHorizontalLines - 1) * gridSpacingY,
                  unitId: 'Profometer.Measurement.GridDimension',
                  enable: !!isFullList && !isFlexible,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionX',
                  value: gridSpacingX,
                  unitId: 'Profometer.Measurement.GridDimension',
                  enable: !!isFullList && !isFlexible,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionY',
                  value: gridSpacingY,
                  unitId: 'Profometer.Measurement.GridDimension',
                  enable: !!isFullList && !isFlexible,
              },
          ]
        : [
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.MeasuringMode',
                  value: getMeasurementMode(mode),
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.MeasuringRange',
                  value:
                      data.settings[0].content.preset.measuringRange &&
                      data.settings[0].content.preset.measuringRange in MeasuringRange
                          ? formatMessage({
                                id: MEASURING_RANGE_TEXT[
                                    data.settings[0].content.preset.measuringRange as MeasuringRange
                                ],
                            })
                          : '',
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.MeasuringPattern',
                  value:
                      data.settings[0].content.preset.measuringPattern &&
                      data.settings[0].content.preset.measuringPattern in MeasuringPattern
                          ? formatMessage({
                                id: MEASURING_PATTERN_TEXT[
                                    data.settings[0].content.preset.measuringPattern as MeasuringPattern
                                ],
                            })
                          : '',
                  enable: !!isFullList && isAreaMultiLineScan,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionX',
                  unitId: 'Profometer.Measurement.GridSize',
                  value: areaScanGridSpacingX,
                  enable: !!isFullList && isAreaMultiLineScan && !areaScanIsFlexible,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.CellDimensionY',
                  unitId: 'Profometer.Measurement.GridSize',
                  value: areaScanGridSpacingY,
                  enable: !!isFullList && isAreaMultiLineScan && !areaScanIsFlexible,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.GridDimensionX',
                  value: areaScanGridSpacingX * (areaScanNumberOfVerticalLines - 1),
                  unitId: 'Profometer.Measurement.GridSize',
                  enable: !!isFullList && isAreaMultiLineScan && !areaScanIsFlexible,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.GridSetup.GridDimensionY',
                  value: areaScanGridSpacingY * (areaScanNumberOfHorizontalLines - 1),
                  unitId: 'Profometer.Measurement.GridSize',
                  enable: !!isFullList && isAreaMultiLineScan && !areaScanIsFlexible,
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.MeasurementOrientation',
                  value: formatMessage({
                      id:
                          startDirection === 'X'
                              ? 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Horizontal'
                              : 'App.HTML.Profometer.MeasurementSettings.ScanDirection.Vertical',
                  }),
                  enable: !!isFullList && isAreaMultiLineScan,
              },
              {
                  label: 'Units',
                  value:
                      data.settings[0].content.preset.unit && data.settings[0].content.preset.unit in ProfometerUnit
                          ? formatMessage({
                                id: getUnitFormatID(data.settings[0].content.preset.unit as ProfometerUnit, scanType),
                            })
                          : '',
              },
              {
                  label: 'App.HTML.Profometer.MeasurementSettings.CoverOffset',
                  unitId: 'Profometer.Presets.CoverOffset',
                  value: data.settings[0].content.preset.coverOffset,
                  enable: scanType !== ScanType.SpotScan,
              },
          ];

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric, getProfometerValue(convert));
};
