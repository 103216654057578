import React from 'react';
import styles from '../../DataView/Button.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { ReactComponent as UploadIcon } from '../../../images/iconUpload.svg';

interface UploadButtonProps {
    onClick: () => void;
}

const UploadButton: React.FunctionComponent<UploadButtonProps> = ({ onClick }) => {
    return (
        <AnalyticsButton type="primary" className={styles.center} onClick={onClick}>
            <UploadIcon className={styles.icon} />
            <FormattedMessage id="App.Upload" />
        </AnalyticsButton>
    );
};

export default UploadButton;
