import React from 'react';
import { Link } from 'react-router-dom';
import FormattedMessage from '../../localization/FormatMessage';
import routeURLs from '../Routes/urls';
import DashboardBlock from './DashboardBlock';
import ProbeList from '../Probes/ProbeList';
import { ReactComponent as IconArrowRight } from '../../images/iconArrowRight.svg';
import styles from './Dashboard.styl';
import useProbes from '../../hooks/useProbes';
import analytics from '../../analytics/firebaseAnalytics';
import { useProbesList } from '../../queries/probeQueries';

const ProbeListBlock: React.FunctionComponent = () => {
    useProbesList();

    const action = (
        <Link
            to={routeURLs.PROFILE_PROBES}
            className={styles.dashboard_link}
            onClick={() => analytics.logViewAllProbes()}
        >
            <FormattedMessage id="Probe.All" />
            <IconArrowRight style={{ marginLeft: 10 }} />
        </Link>
    );

    const { probeIDs } = useProbes();

    if (probeIDs.length <= 0) {
        return null;
    }

    return (
        <DashboardBlock title={<FormattedMessage id="App.Probes" />} actions={action} height={274}>
            <ProbeList isShortList />
        </DashboardBlock>
    );
};

export default ProbeListBlock;
