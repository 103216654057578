import React from 'react';
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import FormattedMessage from '../../localization/FormatMessage';
import { useDataViewContext } from './DataViewProvider';
import { FormatIDs } from '../../types';
import { useRoleState } from '../../hooks/useCurrentUser';
import { useMeasurementContext } from './MeasurementContext/MeasurementProvider';

interface ToggleSwitchProps {
    checked: boolean;
    onChange: SwitchChangeEventHandler;
    title: FormatIDs;
}

export const ToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = (props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch checked={props.checked} onChange={props.onChange} />
            <span style={{ marginLeft: 10 }} />
            <FormattedMessage id={props.title} />
        </div>
    );
};

export const ToggleHaze: React.FunctionComponent = () => {
    const { showHaze, setShowHaze } = useMeasurementContext();
    return (
        <ToggleSwitch
            checked={showHaze}
            onChange={() => {
                setShowHaze((prevState) => !prevState);
            }}
            title="DataView.Measurement.Show.Haze"
        />
    );
};

export const ToggleUnsynced: React.FunctionComponent = () => {
    const enableUnsyncedToggle = useRoleState().isDev;
    const { withUnsynced, setWithUnsynced } = useDataViewContext();
    return enableUnsyncedToggle ? (
        <ToggleSwitch
            checked={withUnsynced}
            onChange={() => {
                setWithUnsynced((prevState) => !prevState);
            }}
            title="DataView.Measurement.Debug.Unsynced"
        />
    ) : null;
};
