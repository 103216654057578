import { useQuery } from '@tanstack/react-query';
import { merge, union } from 'lodash';
import { ReactQueryKeys } from './queryKeys';
import * as measurementService from '../api/measurementService';
import { FilterParams, GetCustomMaterialListParams, GetMeasurementListParams } from '../api/measurementService';
import { ProductCode, productsWithVerificationData } from '../types/proceq';
import {
    BluetoothFileType,
    CustomCurveFileType,
    defaultOrderConfig,
    FilterConfig,
    OrderConfig,
    SystemFolderID,
    ViewType,
} from '../types/measurement';
import { SPATIAL_DATA_FILE_TYPE } from '../types/userFiles';

export interface MeasurementListParams {
    product?: ProductCode;
    fileType: string;
    activeFolder: string;
    page: number;
    pageSize: number;
    searchText: string;
    withUnsynced: boolean;
    productModel?: string;
    probeTypeId?: number;
    viewType: ViewType;
    filterConfig: { [key: string]: FilterConfig };
    orderConfig?: OrderConfig;
    mIDs?: string[];
    enablePolling?: boolean;
}

// poll every 30 seconds
const UNSYNCED_MEASUREMENTS_POLLING = 30 * 1000;

export const useMeasurementList = (params: MeasurementListParams, enabled = true) => {
    return useQuery({
        queryKey: ReactQueryKeys.measurementList(params),
        queryFn: () => {
            const {
                product,
                searchText,
                withUnsynced,
                probeTypeId,
                productModel,
                fileType,
                activeFolder,
                page,
                viewType,
                orderConfig,
                filterConfig,
                pageSize,
                mIDs,
                enablePolling,
            } = params;
            const jsonBody: GetMeasurementListParams = {
                product: product!,
                fileType: fileType || (productsWithVerificationData.has(product!) ? 'measurement' : 'all'),
                folderID:
                    fileType === BluetoothFileType.VerificationData || fileType === CustomCurveFileType.dgscc
                        ? SystemFolderID.All
                        : activeFolder,
                offset: enablePolling ? 0 : (page - 1) * pageSize, // for polling, mIDs are used and thus offset is 0
                search: searchText,
                limit: pageSize,
                withUnsynced,
                productModel,
                probeTypeId,
                archived: viewType === ViewType.Archived,
                includeSize: !(
                    fileType === BluetoothFileType.VerificationData || fileType === CustomCurveFileType.dgscc
                ),
                mIDs,
                isPolling: enablePolling,
            };

            if (filterConfig) {
                const filters: { [key: string]: FilterParams } = {};
                for (const key in filterConfig) {
                    const { category, ...filter } = filterConfig[key];
                    if (filters[category]) {
                        filters[category] = {
                            ...filters[category],
                            rules: union(filters[category].rules, filter.rules),
                        };
                    } else {
                        filters[category] = filter;
                    }
                }
                jsonBody.filter = filters;
            }

            const myOrderConfig = orderConfig ?? defaultOrderConfig;

            if (myOrderConfig.orderBy?.includes('.$.')) {
                const [rawCategory, rawOrderBy] = myOrderConfig.orderBy.split('.$.');
                const filter: FilterParams = {
                    operator: 'AND',
                    orderBy: `$.${rawOrderBy}`,
                    orderDir: myOrderConfig.orderDir,
                };

                jsonBody.filter = {
                    ...jsonBody.filter,
                    [rawCategory]: merge(jsonBody?.filter?.[rawCategory], filter),
                };
            } else {
                jsonBody.orderBy = myOrderConfig.orderBy;
                jsonBody.orderDir = myOrderConfig.orderDir;
            }
            return measurementService.getMeasurementList(jsonBody);
        },
        enabled:
            enabled &&
            !!params.product &&
            params.fileType !== BluetoothFileType.CustomMaterial &&
            params.fileType !== SPATIAL_DATA_FILE_TYPE,
        refetchOnWindowFocus: false,
        refetchInterval: params.enablePolling && params.mIDs ? UNSYNCED_MEASUREMENTS_POLLING : false,
    });
};

export const useMeasurementsPoll = (params: MeasurementListParams, enabled = true) => {
    return useMeasurementList({ ...params, enablePolling: true }, enabled);
};

export interface CustomMaterialListParams {
    product?: ProductCode;
    page: number;
    pageSize: number;
    fileType: string;
    withUnsynced: boolean;
    orderConfig?: OrderConfig;
}

export const useCustomMaterialList = (params: CustomMaterialListParams) => {
    return useQuery({
        queryKey: ReactQueryKeys.customMaterialList(params),
        queryFn: () => {
            const { product, withUnsynced, page, orderConfig, pageSize } = params;
            const jsonBody: GetCustomMaterialListParams = {
                product: product!,
                offset: (page - 1) * pageSize,
                limit: pageSize,
                withUnsynced,
            };

            const myOrderConfig = orderConfig ?? defaultOrderConfig;

            if (myOrderConfig.orderBy?.includes('.$.')) {
                const filter: FilterParams = {
                    operator: 'AND',
                    orderBy: myOrderConfig?.orderBy,
                    orderDir: myOrderConfig.orderDir,
                };
                jsonBody.filter = window.encodeURI(JSON.stringify({ measurement: filter }));
                jsonBody.orderDir = myOrderConfig.orderDir;
            } else {
                jsonBody.orderBy = myOrderConfig.orderBy;
                jsonBody.orderDir = myOrderConfig.orderDir;
            }
            return measurementService.getCustomMaterialList(jsonBody);
        },
        enabled: !!params.product && params.fileType === BluetoothFileType.CustomMaterial,
        refetchOnWindowFocus: false,
    });
};

export const useCustomMaterialDataCount = (product: ProductCode | undefined, withUnsynced: boolean) => {
    return useQuery({
        queryKey: ReactQueryKeys.customMaterialCount(product!, withUnsynced),
        queryFn: () =>
            measurementService.getCustomMaterialList({
                product: product!,
                limit: 0,
                orderBy: defaultOrderConfig.orderBy,
                orderDir: defaultOrderConfig.orderDir,
                withUnsynced,
            }),
        enabled: !!product,
        refetchOnWindowFocus: false,
        select: (data) => data.totalRecords,
    });
};

export const useVerificationDataCount = (product: ProductCode | undefined, withUnsynced: boolean) => {
    return useQuery({
        queryKey: ReactQueryKeys.verificationDataCount(product!, withUnsynced),
        queryFn: () =>
            measurementService.getMeasurementList({
                product: product!,
                fileType: BluetoothFileType.VerificationData,
                limit: 1, // limit 100 automatically returned if limit is set as 0
                orderBy: defaultOrderConfig.orderBy,
                orderDir: defaultOrderConfig.orderDir,
                withUnsynced,
            }),
        enabled: !!product,
        refetchOnWindowFocus: false,
        select: (data) => data.totalRecords,
    });
};
