import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from './queryKeys';
import * as userFileService from '../api/userFileService';
import { GetUserFileParams } from '../api/userFileService';
import { ProductCode, productsWithSpatialData } from '../types/proceq';
import { SPATIAL_DATA_FILE_TYPE, SpatialDataModalFieldOptions } from '../types/userFiles';
import { normalizer } from '../utils/genericNormalizer';

interface UseUserFileListParams extends GetUserFileParams {
    product?: ProductCode;
}

export const useUserFileList = (params: UseUserFileListParams) => {
    const { product, ...otherParams } = params;
    return useQuery({
        queryKey: ReactQueryKeys.userFileList(otherParams),
        queryFn: () => userFileService.getUserFiles(otherParams),
        refetchOnWindowFocus: false,
        enabled: !!product && productsWithSpatialData.has(product),
    });
};

export const useUserFileExtensions = (fileType: string, product?: ProductCode) => {
    return useQuery({
        queryKey: ReactQueryKeys.userFileExtensions,
        queryFn: () => userFileService.getUserFileExtensions(),
        refetchOnWindowFocus: false,
        enabled: fileType === SPATIAL_DATA_FILE_TYPE && !!product && productsWithSpatialData.has(product),
    });
};

export const useUserFileOption = (product?: ProductCode) => {
    return useQuery({
        queryKey: ReactQueryKeys.userFileOption,
        queryFn: () => userFileService.getUserFileOption(),
        refetchOnWindowFocus: false,
        enabled: !!product && productsWithSpatialData.has(product),
        select: (data) => {
            const { entities, entityIds } = normalizer(data.countryCoordinateSystems, 'country');
            const countryCoordinateSystems = entityIds.reduce((previousValue, currentValue) => {
                return {
                    ...previousValue,
                    [currentValue]: entities[currentValue].coordinateSystems.map((coordinateSystem, index) => ({
                        label: coordinateSystem.name,
                        value: coordinateSystem.name,
                        epsg: coordinateSystem.epsg,
                        key: `${coordinateSystem.name}-${index}`,
                    })),
                };
            }, {});
            return {
                utmZones: data.utmZones.map((zone) => ({ value: zone.name, label: zone.name, epsg: zone.epsg })),
                countries: entityIds.map((country) => ({ value: country, label: country })),
                countryCoordinateSystems,
            } as SpatialDataModalFieldOptions;
        },
    });
};
