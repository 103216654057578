import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import { useIntl } from 'react-intl';
import { title as titleUserComponent, useUserProperties } from '../Common/User';
import { getGPRMeasurementPresets as getGPRPresets } from './GPR/getMeasurementPresets';
import { getGPRSoilMeasurementPresets as getGPRSoilPresets } from './GPRSoil/getMeasurementPresets';
import { getPunditMeasurementPresets as getPunditPresets } from './Pundit/getMeasurementPresets';
import { getProfometerMeasurementPresets as getProfometerPresets } from './Profometer/getMeasurementPresets';
import { getMeasurementPresetsForSnapshotReading as getFDLPresets } from './FDL/MeasurementPresets';
import { DerivedProps } from '../SharedTypes';
import { convertAndDisplay } from '../utils/conversionRates';
import styles from '../DataViewers.styl';
import { GPRFamilyReadingsProps } from './ReadingComponents';
import PropertiesTable from './PropertiesTable';
import FormattedMessage from '../../../../localization/FormatMessage';
import SnapshotsViewer from './SnapshotsViewer';
import { ProductCode } from '../../../../types/proceq';
import { PROFOMETER_METRIC_UNITS } from '../../../../types/measurement';

const getPresetMap: { [key in ProductCode]?: typeof getGPRPresets } = {
    [ProductCode.GPR]: getGPRPresets,
    [ProductCode.GPR_SOIL]: getGPRSoilPresets,
    [ProductCode.PUNDIT]: getPunditPresets,
    [ProductCode.FDL]: getFDLPresets,
    [ProductCode.PROFOMETER]: getProfometerPresets,
};

const SnapshotReadings: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data } = props;
    const scanType = get(data, 'measurement.type');
    const isMetric =
        product === ProductCode.PROFOMETER
            ? PROFOMETER_METRIC_UNITS.has(data?.settings[0].content.preset.unit)
            : data?.settings[0].content.settings?.display?.unit?.toLowerCase() === 'metric';

    const formatMessage = useIntl().formatMessage;

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    const userProperties = useUserProperties(data, product);

    if (!data || !scanType) {
        return null;
    }

    const settingsProperties = (getPresetMap[product] ?? getGPRSoilPresets)(
        data,
        scanType,
        isMetric,
        convertor,
        formatMessage
    );

    const titleSettings = <FormattedMessage id="Proceq.ExportFieldSettings" />;

    return (
        <div>
            {data.uniqueName && (
                <h1 id={data.measurement.id} className={styles.file_name}>
                    {data.uniqueName}
                </h1>
            )}

            <SnapshotsViewer measurement={data} product={product} />
            <PropertiesTable title={titleUserComponent} properties={userProperties} />
            <PropertiesTable title={titleSettings} properties={settingsProperties} />
        </div>
    );
};

export default SnapshotReadings;
