import React, { useState } from 'react';
import { Button, Dropdown } from 'antd';
import { classNames } from '../../../utils/styleUtils';
import styles from './MenuList.styl';
import { ReactComponent as IconEllipses } from '../../../images/iconEllipses.svg';
import FormattedMessage from '../../../localization/FormatMessage';
import CreateRenameModal, { CreateRenameModalAction } from '../../DataView/FolderManagement/CreateRenameModal';
import DeleteConfirmationModal, { DeletedType } from '../../DataView/FolderManagement/DeleteConfirmationModal';
import { FormatIDs } from '../../../types';
import { NonEditableType, SystemFolderType } from '../../../types/measurement';
import analytics from '../../../analytics/firebaseAnalytics';
import { FolderManagementCategory } from '../../../analytics/analyticsConstants';
import { useDataViewContext } from '../../DataView/DataViewProvider';
import { AntdMenuItem } from '../../../types/antd';
import ArchiveConfirmationModal from '../../DataView/FolderManagement/ArchiveConfirmationModal';
import { ProductFeature, useProductContext } from '../../DataView/ProductContextProvider';
import { archiveRestoreFolder, getMeasurementFolders } from '../../../api/folderService';
import { useMeasurementContext } from '../../DataView/MeasurementContext/MeasurementProvider';

interface MenuItem {
    id: string;
    title: string | React.ReactNode;
    count?: number;
    name?: string;
    deletable?: boolean;
    restorable?: boolean;
}

interface MenuListProps {
    menuItems: (MenuItem | null)[];
    activeItem?: string;
    onSelect?: (key: string) => void;
    isUserFolder?: boolean;
    isArchiveViewType?: boolean;
}

enum FolderMenuAction {
    delete = 'delete',
    rename = 'rename',
    archive = 'archive',
    restore = 'restore',
    deletePermanently = 'deletePermanently',
}

const getFolderMenuItems = (isArchiveEnabled: boolean, isArchiveViewType?: boolean): FolderMenuAction[] => {
    if (isArchiveViewType) {
        return [FolderMenuAction.restore, FolderMenuAction.deletePermanently];
    }
    if (isArchiveEnabled) {
        return [FolderMenuAction.rename, FolderMenuAction.archive, FolderMenuAction.delete];
    }
    return [FolderMenuAction.rename, FolderMenuAction.delete];
};

const FOLDER_MENU_ITEM_MAP: Record<
    FolderMenuAction,
    { key: string; text: FormatIDs; logEvent: (product: string) => void }
> = {
    [FolderMenuAction.rename]: {
        key: 'rename',
        text: 'DataView.Folder.RenameFolder.Rename',
        logEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.sidebarRename, product);
        },
    },
    [FolderMenuAction.delete]: {
        key: 'delete',
        text: 'App.Delete',
        logEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.sidebarDelete, product);
        },
    },
    [FolderMenuAction.archive]: {
        key: 'archive',
        text: 'App.Archive',
        logEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.sidebarArchive, product);
        },
    },
    [FolderMenuAction.restore]: {
        key: 'restore',
        text: 'DataView.Measurement.Restore',
        logEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.sidebarRestore, product);
        },
    },
    [FolderMenuAction.deletePermanently]: {
        key: 'deletePermanently',
        text: 'DataView.Measurement.Delete.Permanently',
        logEvent: (product) => {
            analytics.logFolderManagement(FolderManagementCategory.sidebarDeletePermanently, product);
        },
    },
};

const MenuList: React.FunctionComponent<MenuListProps> = (props) => {
    const { menuItems, activeItem, onSelect, isUserFolder = false, isArchiveViewType } = props;
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [menuItem, setMenuItem] = useState<MenuItem>();
    const { product, withUnsynced } = useDataViewContext();
    const { setNonEditableMeasurements, fetchMeasurementList } = useMeasurementContext();

    const folderRedirect = () => {
        if (menuItem?.id === activeItem && onSelect) {
            onSelect(SystemFolderType.All);
        } else {
            // fetch measurement as list may change if user is in all measurements
            fetchMeasurementList();
        }
    };

    const handleButtonClick = (
        key: FolderMenuAction,
        domEvent: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
        deletable?: boolean,
        restorable?: boolean
    ) => {
        domEvent.stopPropagation();
        switch (key) {
            case FolderMenuAction.delete: {
                if (deletable === false) {
                    setNonEditableMeasurements({
                        allInvalid: true,
                        measurementIds: [],
                        nonEditableType: NonEditableType.editFolder,
                    });
                } else {
                    setShowDeleteModal(true);
                }
                break;
            }
            case FolderMenuAction.rename:
                setShowRenameModal(true);
                break;
            case FolderMenuAction.archive:
                if (deletable === false) {
                    setNonEditableMeasurements({
                        allInvalid: true,
                        measurementIds: [],
                        nonEditableType: NonEditableType.archiveFolder,
                    });
                } else {
                    setShowArchiveModal(true);
                }
                break;
            case FolderMenuAction.deletePermanently:
                setShowDeleteModal(true);
                break;
            case FolderMenuAction.restore:
                if (restorable === false) {
                    setNonEditableMeasurements({
                        allInvalid: true,
                        measurementIds: [],
                        nonEditableType: NonEditableType.restoreFolder,
                    });
                } else if (menuItem && product) {
                    archiveRestoreFolder({ id: menuItem.id, product, isArchive: false }).then(() => {
                        getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType });
                        folderRedirect();
                    });
                }
                break;
        }
        FOLDER_MENU_ITEM_MAP[key].logEvent(product ?? '');
    };

    const { isFeatureEnabled } = useProductContext();
    const isArchiveEnabled = isFeatureEnabled(ProductFeature.ARCHIVE_FOLDERS);

    const antdMenuItems: AntdMenuItem[] = getFolderMenuItems(isArchiveEnabled, isArchiveViewType).map((item) => ({
        key: FOLDER_MENU_ITEM_MAP[item].key,
        label: <FormattedMessage id={FOLDER_MENU_ITEM_MAP[item].text} />,
    }));

    return (
        <>
            {menuItems.map((item) => {
                if (!item) {
                    return null;
                }
                const showCounts = item.count !== undefined && item.count > 0;
                return (
                    <div
                        className={classNames(styles.menu_item, item.id === activeItem && styles.menu_item_active)}
                        key={item.id}
                        onClick={() => onSelect && onSelect(item.id)}
                    >
                        {typeof item.title === 'string' ? <div>{item.title}</div> : item.title}
                        {showCounts && <div>{item.count}</div>}
                        {isUserFolder && (
                            <Dropdown
                                menu={{
                                    onClick: ({ key, domEvent }) =>
                                        handleButtonClick(
                                            key as FolderMenuAction,
                                            domEvent,
                                            item.deletable,
                                            item.restorable
                                        ),
                                    items: antdMenuItems,
                                }}
                                trigger={['click']}
                                className={styles.dropdown}
                            >
                                <Button
                                    className={styles.ellipses_button}
                                    type="link"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setMenuItem(item);
                                    }}
                                >
                                    <IconEllipses />
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                );
            })}
            <CreateRenameModal
                action={CreateRenameModalAction.renameFolder}
                visible={showRenameModal}
                onCancel={() => setShowRenameModal(false)}
                currentName={menuItem?.name}
                id={menuItem?.id}
            />
            <DeleteConfirmationModal
                visible={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)}
                deletedType={isArchiveViewType ? DeletedType.archivedFolder : DeletedType.folder}
                ids={menuItem ? [menuItem.id] : []}
                onDelete={folderRedirect}
                name={menuItem?.name}
            />
            <ArchiveConfirmationModal
                visible={showArchiveModal}
                onCancel={() => setShowArchiveModal(false)}
                id={menuItem?.id}
                name={menuItem?.name}
                onArchive={folderRedirect}
            />
        </>
    );
};

export default MenuList;
