import { noop } from 'lodash';
import React, { createContext } from 'react';
import { ViewType } from '../../types/measurement';
import { ProductCode } from '../../types/proceq';

export interface DataViewContextState {
    product: ProductCode | undefined;
    setProduct: (productCode: ProductCode, folderID?: string) => void;

    viewType: ViewType;
    setViewType: (viewType: ViewType) => void;

    activeFolder: string;
    setActiveFolder: React.Dispatch<React.SetStateAction<string>>;

    fileType: string;
    setFileType: React.Dispatch<React.SetStateAction<string>>;

    // this affects folders and measurement listing
    withUnsynced: boolean;
    setWithUnsynced: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

export const DataViewContext = createContext<DataViewContextState>({
    product: undefined,
    setProduct: noop,

    viewType: ViewType.Active,
    setViewType: noop,

    activeFolder: '',
    setActiveFolder: noop,

    fileType: '',
    setFileType: noop,

    withUnsynced: false,
    setWithUnsynced: noop,
});
