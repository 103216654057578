import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { get, post, thunkPost } from './apiService';
import { getApiURL } from './ApiURL';
import { handleError } from './error';
import * as profileActions from '../actions/profile';
import { parseJwt } from '../utils/authUtils';
import authInfo from './AuthInfo';
import { API_SCOPE } from '../constants';

export interface GetOAuthTokenResponse {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token?: string;
    scope: string;
    token_type: string;
}

export interface GetOAuthTokenErrorResponse {
    error: string;
    error_description: string;
}

export interface GetOAuthTokenParams {
    client_id: string;
    code: string;
    redirect_uri: string;
    grant_type: 'authorization_code';
    code_verifier?: string;
}

export interface OAuthToken {
    sub: string;
    pqRegion: string;
    pqRegionalDomain: string;
    pqManagementDomain: string;
}

export interface GetMasqueradeTokenParams {
    client_id: string;
    password: string;
    user_id: number;
}

export async function getOAuthToken(params: GetOAuthTokenParams) {
    const url = getApiURL('GET_OAUTH_TOKEN');
    const { response } = await thunkPost<GetOAuthTokenResponse | GetOAuthTokenErrorResponse>(url, {
        params: { ...params },
        errorHandler: handleError,
    });
    if (!isErrorResponse<GetOAuthTokenResponse, GetOAuthTokenErrorResponse>(response)) {
        authInfo.updateInfo(`${response.token_type} ${response.access_token}`);
        const jwt: OAuthToken = parseJwt(response.id_token);
        return { response, token: jwt };
    }
    throw new Error(response.error);
}

export async function getMasqueradeToken(params: GetMasqueradeTokenParams) {
    const url = getApiURL('MASQUERADE');
    const { response } = await thunkPost<GetOAuthTokenResponse | GetOAuthTokenErrorResponse>(url, {
        params: { ...params, scope: API_SCOPE },
        errorHandler: handleError,
    });
    if (!isErrorResponse<GetOAuthTokenResponse, GetOAuthTokenErrorResponse>(response)) {
        authInfo.updateInfo(`${response.token_type} ${response.access_token}`);
        const jwt: OAuthToken = parseJwt(response.id_token);
        return { response, token: jwt };
    }
    throw new Error(response.error);
}

export async function validateToken(
    data: { response: GetOAuthTokenResponse; token: OAuthToken },
    dispatch: Dispatch<AnyAction>
) {
    const { token, response } = data;
    const { sub: userID, pqRegion, pqRegionalDomain, pqManagementDomain } = token;

    const verifyEagleIDURL = getApiURL('SET_EAGLE_ID_AUTH');
    const verifyEagleCloudURL = getApiURL('SET_EAGLE_CLOUD_AUTH');
    const headers = {
        Authorization: `${response.token_type} ${response.access_token}`,
    };
    await Promise.all([
        post(verifyEagleIDURL, { config: { headers } }),
        post(verifyEagleCloudURL, { config: { headers } }),
    ]);

    dispatch(profileActions.receivedUserProfile({ id: Number(userID) }));
    dispatch(profileActions.receivedRegionInfo({ pqRegion, pqRegionalDomain, pqManagementDomain }));
}

export function oAuthLogout() {
    const url = getApiURL('OAUTH_LOGOUT');

    const errorHandler = (error: any) => {
        if (error.response?.status === 401) {
            return;
        }
        handleError(error);
    };

    return get(url, { errorHandler });
}

function isErrorResponse<SuccessType, ErrorType extends { error: string }>(
    res: SuccessType | ErrorType
): res is ErrorType {
    return (res as ErrorType).error !== undefined;
}
