import logdna from '@logdna/browser';
import { browserParser } from 'tds-common-fe/src/lib/utils/browserUtils';
import { LineContext } from '@logdna/browser/dist/types/logdna';
import * as Sentry from '@sentry/browser';
import config from '../config';

class Logger {
    private additionalContext?: LineContext;

    initialize = () => {
        if (config.LOGDNA_INGESTION_KEY) {
            try {
                logdna.init(config.LOGDNA_INGESTION_KEY, {
                    app: `workspace_${config.APP_ENV}`,
                    console: location.hostname !== 'localhost',
                    sampleRate: 100,
                    hostname: browserParser.getBrowserName().replaceAll(' ', '-'),
                });
            } catch (e) {
                Sentry.captureException(`error initializing logger ${e}`);
            }
        }
    };

    setAdditionalLogContext = (context: LineContext) => {
        this.additionalContext = context;
    };

    addContext = (context: any) => {
        logdna.addContext?.(context);
    };

    log = (message: any) => {
        logdna.log?.(message, this.additionalContext);
    };

    error = (error: any) => {
        logdna.error?.(error, this.additionalContext);
    };
}

const logger = new Logger();

export default logger;
