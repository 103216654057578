import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import useProbes from '../../hooks/useProbes';
import FormattedMessage from '../../localization/FormatMessage';
import { FormatIDs } from '../../types';
import { classNames } from '../../utils/styleUtils';
import AnalyticsButton from '../AnalyticsComponents/Button';
import StyledModal from '../shared/StyledModal';
import ProbeCard from './ProbeCard';
import ProbeDetails from './ProbeDetails';
import styles from './ProbeList.styl';
import analytics from '../../analytics/firebaseAnalytics';
import { ProbeDetailsPage } from '../../analytics/analyticsConstants';
import { useProbesList } from '../../queries/probeQueries';

interface ProbeListProps {
    isShortList?: boolean;
}

const getProbeDescription = (length: number): FormatIDs => {
    switch (length) {
        case 0:
            return 'Probe.Count.Description.Empty';
        case 1:
            return 'Probe.Count.Description.Singular';
        default:
            return 'Probe.Count.Description';
    }
};

const ProbeList: React.FunctionComponent<ProbeListProps> = (props) => {
    const { isShortList } = props;

    useProbesList();
    const { probeIDs, probes } = useProbes();
    const { width: containerWidth, ref: containerElem } = useResizeDetector<HTMLDivElement>();

    const [maxAmount, setMaxAmount] = useState<number | undefined>(isShortList ? 0 : undefined);

    useEffect(() => {
        if (isShortList && containerWidth) {
            setMaxAmount(Math.floor(containerWidth / 187));
        }
    }, [containerWidth, isShortList]);

    const listProbeIDs = useMemo(() => {
        if (maxAmount !== undefined) {
            return probeIDs.slice(0, maxAmount);
        }
        return probeIDs;
    }, [maxAmount, probeIDs]);

    const [selectedProbeID, setSelectedProbeID] = useState<string | undefined>();

    const handleClick = (probId: React.SetStateAction<string | undefined>) => {
        setSelectedProbeID(probId);
        analytics.logViewProbe(isShortList ? ProbeDetailsPage.homepage : ProbeDetailsPage.probeList);
    };

    const closeModal = useCallback(() => {
        setSelectedProbeID(undefined);
    }, []);

    return (
        <div ref={containerElem} style={isShortList ? { marginTop: 8 } : {}}>
            <p>
                <FormattedMessage id={getProbeDescription(probeIDs.length)} values={{ count: probeIDs.length }} />
            </p>

            <div
                className={classNames(styles.probes_wrapper, !isShortList && styles.probes_wrapper_wrap)}
                style={{ marginBottom: '-0.5rem' }}
            >
                {listProbeIDs.map((id) => {
                    const probe = probes[id];
                    return <ProbeCard key={id} serialNumber={probe.serialNumber} onClick={() => handleClick(id)} />;
                })}
            </div>
            <StyledModal
                title={<FormattedMessage id="Probe.Details" />}
                open={!!selectedProbeID}
                onCancel={closeModal}
                footer={
                    <AnalyticsButton type="primary" onClick={closeModal}>
                        <FormattedMessage id="App.Close" />
                    </AnalyticsButton>
                }
            >
                {selectedProbeID && <ProbeDetails probeID={selectedProbeID} />}
            </StyledModal>
        </div>
    );
};

export default ProbeList;
